import React, { useState } from 'react';
import InfoTooltip from 'components/tooltip/custom/InfoTooltip';
import PledgeStatus from 'contracts/pledgeVault/constants';
import RoundedButton from 'components/button/rounded-button';
import withNewPledge from '../../hoc/withNewPledge';
import ReleasePledgesPopoup from '../ReleasePledgesPopoup';

function UpdatePledgesButton({ deal, pool, pledges, onRefetch }) {
  const [openUpdatePledges, setOpenUpdatePledges] = useState(false);

  return (
    <div className="holder-buttons">
      <RoundedButton
        disabled={pool?.status !== PledgeStatus.Freezed}
        type="secondary"
        onClick={() => setOpenUpdatePledges(true)}
      >
        Update pledges
      </RoundedButton>
      <InfoTooltip title="Update pledges" />
      {openUpdatePledges ? (
        <ReleasePledgesPopoup
          deal={deal}
          pledges={pledges}
          isOpen={openUpdatePledges}
          onRefetch={onRefetch}
          onClose={() => setOpenUpdatePledges(false)}
        />
      ) : null}
    </div>
  );
}

export default withNewPledge(UpdatePledgesButton);
