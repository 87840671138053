import { ethers } from 'ethers';
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import RoundedButton from 'components/button/rounded-button';
import CustomSlider from 'components/progress-bar/custom-slider';
import NumberInput from 'components/input/number-input';
import { setSharedNotification, setActiveHashes, addNotification } from 'store/actions';
import notificationTypes from 'constants/notificationTypes';
import { addChain } from 'contracts/browserWallet';
import { USDT_ADDRESS, ARBITRUM_CHAIN_ID, ARBITRUM_CHAIN_NAME } from 'constants/config';
import { subStringAmounts } from 'utils/helpers';

import '../index.scss';
import useContributePool from 'contracts/poolManager/hooks/useContributePool';
import usePoolQuery from 'contracts/pledgeVault/hooks/usePoolQuery';
import useERC20Allowance from 'contracts/erc20/useERC20Allowance';
import useErc20Approve from 'contracts/erc20/useERC20Approve';
import { useQueryClient } from 'react-query';
import { buildAllowlistData } from 'features/deals/UserDealsTable/helpers';

const ContributePhase = ({ deal, phase, allocation, onClose }) => {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const globalReducer = useSelector((state) => state.global);
  const authReducer = useSelector((state) => state.auth);
  const [errorMessage, setErrorMessage] = useState('');

  const { chainId, activeHashes } = globalReducer;
  const { accountInfo } = authReducer;

  const { allowance } = useERC20Allowance(deal.address);

  const { approve, isLoading: isApproving } = useErc20Approve(USDT_ADDRESS, {
    onSuccess: () => {
      queryClient.invalidateQueries(['pool-info', deal.address]);
      queryClient.invalidateQueries(['allowance', deal.address]);
    },
  });

  const { contribute, isLoading: isContributing } = useContributePool(deal.address, {
    onSuccess: () => {
      queryClient.invalidateQueries(['pool-info', deal.address]);
      queryClient.invalidateQueries(['allowance', deal.address]);
      queryClient.invalidateQueries(['remaining-allocations', deal.address, accountInfo.address]);
    },
    onClose,
  });

  const { pool } = usePoolQuery(deal);

  const maxAllocation = +phase.cap || +pool?.dealSize;

  const [contributionValue, setContributionValue] = useState(pool?.minContribution || 0);

  const getErrorMessage = () => {
    if (!pool) return '';

    if (errorMessage === 'min') return `Min. = ${+pool.minContribution} USDT`;
    if (errorMessage === 'max') {
      const levelCap =
        phase.levelCaps.find((cap) => +cap > 0) || phase.levelCaps[accountInfo.userAccessLevel];

      return `Max. = ${Number(levelCap)
        .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} USDT`;
    }

    if (errorMessage === 'remaining-allocation') return `Remaining allocation is ${allocation}.`;
    if (errorMessage === 'cap') return `Exceeds cap of ${+phase.cap}.`;
    if (errorMessage === 'personalCap')
      return `Unfilled deal balance must be at least ${pool.minContribution} or equal to 0.`;

    if (errorMessage === 'unfilled')
      return `Contribution must be max ${maxAllocation - +pool.totalContribution}.`;
    if (errorMessage === 'whitelist')
      return `Contribution must be max ${ethers.utils.formatUnits(phase.allowlistAmount, 'mwei')}.`;
    if (errorMessage === 'max-contribution')
      return `Contribution must be max ${pool.maxContribution}.`;
    if (errorMessage === 'deal-filled')
      return `The deal is filled. Contributions are no longer allowed.`;

    if (errorMessage === 'nftPrice') return 'Contribution must be a multiple of the NFT price.';

    if (errorMessage === 'allowance') return 'Approve additional allowance';
    return '';
  };

  const onChangeContributionValue = (e) => {
    const { value } = e.target;
    setContributionValue(value);
  };

  const minAmountLeftCondition = useCallback(() => {
    if (!pool) return;

    const raisedAfterContribution = +pool.totalContribution + +contributionValue || 0;

    const unfilledAmount = maxAllocation - raisedAfterContribution;

    return unfilledAmount >= +pool.minContribution || unfilledAmount <= 1;
  }, [pool, contributionValue, maxAllocation]);

  const checkMinContribution = useCallback(() => {
    if (Number(contributionValue) < Number(pool?.minContribution)) {
      setErrorMessage('min');
      return true;
    }

    return false;
  }, [contributionValue, pool]);

  const checkAllowlistAmount = useCallback(() => {
    const allowlistAmount = +ethers.utils.formatUnits(phase.allowlistAmount, 'mwei');

    return (
      phase.allowlistProof?.length > 0 &&
      allowlistAmount > 0 &&
      Number(contributionValue) > allowlistAmount
    );
  }, [contributionValue, phase]);

  const checkMaxContribution = useCallback(() => {
    const exceedsRemainingAllocation = +allocation < +contributionValue || 0;

    if (exceedsRemainingAllocation) {
      setErrorMessage('remaining-allocation');
      return true;
    }

    const isDealFilled = +pool.totalContribution === +pool.dealSize;

    if (isDealFilled) {
      setErrorMessage('deal-filled');
      return true;
    }

    if (+phase.cap && +phase.cap < (+contributionValue || 0)) {
      setErrorMessage('cap');
      return true;
    }

    const exceedsMaxContribution =
      pool.maxContribution > 0 && +pool.maxContribution < (+contributionValue || 0);

    if (exceedsMaxContribution) {
      setErrorMessage('max-contribution');
      return true;
    }

    if (phase.allowlistAmount) {
      const exceedsWhitelistAmount = checkAllowlistAmount();

      if (exceedsWhitelistAmount) {
        setErrorMessage('whitelist');
        return true;
      }
    }

    const raisedAfterContribution = +pool.totalContribution + +contributionValue || 0;
    const unfilledAmount = +pool.dealSize - raisedAfterContribution;

    const exceedsUnfilledAmount = unfilledAmount < 0;

    if (exceedsUnfilledAmount) {
      setErrorMessage('unfilled');
      return true;
    }

    const exceedsLevelCap =
      (!phase.allowlistAmount || phase.allowlistAmount === '0') &&
      phase.levelCaps.find((cap) => +cap > 0) &&
      Number(contributionValue) > +phase.levelCaps[accountInfo.userAccessLevel];
    if (exceedsLevelCap) {
      setErrorMessage('max');
      return true;
    }

    return false;
  }, [
    accountInfo.userAccessLevel,
    checkAllowlistAmount,
    contributionValue,
    allocation,
    phase,
    pool,
  ]);

  const checkPersonalCap = useCallback(() => {
    if (!minAmountLeftCondition()) {
      setErrorMessage('personalCap');
      return true;
    }
    return false;
  }, [minAmountLeftCondition]);

  const checkAllowance = useCallback(() => {
    if (Number(allowance) < Number(contributionValue)) {
      setErrorMessage('allowance');
      return true;
    }
    return false;
  }, [allowance, contributionValue]);

  const checkNftPrice = useCallback(() => {
    if (!+phase.nftPrice || !+contributionValue) {
      return;
    }

    const remainder = Number(contributionValue) % Number(phase.nftPrice);

    if (remainder !== 0) {
      setErrorMessage('nftPrice');
      return true;
    }

    return false;
  }, [phase.nftPrice, contributionValue]);

  useEffect(() => {
    if (
      !checkMinContribution() &&
      !checkMaxContribution() &&
      !checkPersonalCap() &&
      !checkNftPrice() &&
      !checkAllowance()
    ) {
      setErrorMessage('');
    }
  }, [checkMinContribution, checkMaxContribution, checkPersonalCap, checkAllowance, checkNftPrice]);

  const onChangeContributionSlider = (event, val) => {
    if (val < Number(deal.contributedAmount)) {
      setContributionValue('0');
      return;
    }
    const contrValue = subStringAmounts(val.toString(), deal.contributedAmount, 6);
    setContributionValue(contrValue);
  };

  const showErrorNotification = (message) => {
    dispatch(
      setSharedNotification({
        status: 'error',
        title: 'Error',
        description: message,
      })
    );
  };

  const handleApprove = async () => {
    const networkChecked = await addChain(chainId, ARBITRUM_CHAIN_ID);
    if (networkChecked) {
      if (+contributionValue === 0) {
        return;
      }

      setErrorMessage('');

      const tx = await approve({
        spender: deal.address,
        amountInWei: ethers.utils.parseUnits(contributionValue.toString(), 6),
      });

      if (tx) {
        dispatch(
          setActiveHashes([
            ...activeHashes,
            {
              hash: tx.transactionHash,
              pending: false,
              chain: ARBITRUM_CHAIN_NAME,
            },
          ])
        );
        dispatch(
          addNotification({
            name: tx.transactionHash,
            chain: ARBITRUM_CHAIN_NAME,
            status: 'pending',
            statusText: 'Pending!',
            time: Date.now(),
            type: notificationTypes.LOCKUP,
          })
        );
      } else {
        showErrorNotification('Something went wrong. Please try again.');
      }
    } else {
      showErrorNotification('You need to change your network to "Ethereum Mainnet" to continue.');
    }
  };

  const onContribute = async () => {
    if (!minAmountLeftCondition()) {
      showErrorNotification(
        `Unfilled deal balance must be at least ${pool.minContribution} or equal to 0.`
      );
      return;
    }

    const phaseToContribute = phase;

    const allowListData = buildAllowlistData(phaseToContribute);

    contribute({
      phaseId: phaseToContribute.index,
      amount: ethers.utils.parseUnits(contributionValue, 6),
      allowListData,
    });
  };

  return (
    <div className="contribute-modal-slider-container" key={phase.id}>
      <label>{phase.name}</label>
      <CustomSlider
        min={0}
        max={allocation}
        value={contributionValue}
        onChange={onChangeContributionSlider}
      />
      <div className="amount-input">
        <NumberInput
          name="amount"
          placeholder="0.0"
          value={contributionValue}
          onChange={onChangeContributionValue}
          error={getErrorMessage()}
          decimalNumber="2"
        />
        <RoundedButton
          disabled={isApproving || isContributing}
          className="max-button"
          onClick={() => setContributionValue(allocation)}
        >
          MAX
        </RoundedButton>
        <span className="usdt-label">USDT</span>
      </div>
      {+contributionValue <= allowance ? (
        <RoundedButton
          type="secondary"
          disabled={
            Number(contributionValue) === 0 ||
            Number(contributionValue) < Number(pool?.minContribution) ||
            Number(contributionValue) > Number(deal.personalCap) ||
            Number(accountInfo.usdtBalance) < Number(contributionValue) ||
            !minAmountLeftCondition() ||
            errorMessage ||
            isApproving ||
            isContributing
          }
          onClick={onContribute}
          className="phase-contribute-button"
        >
          <div className="d-flex">Contribute</div>
        </RoundedButton>
      ) : (
        <RoundedButton
          type="secondary"
          disabled={Number(contributionValue) === 0 || isApproving || errorMessage !== 'allowance'}
          onClick={handleApprove}
          className="phase-approve-button"
        >
          Approve
        </RoundedButton>
      )}
    </div>
  );
};

export default ContributePhase;
