import React, { createContext, useState, useEffect, useContext, useMemo } from 'react';
import usePledgePoolQuery from 'contracts/pledgeVault/hooks/usePledgePoolQuery';
import usePoolQuery from 'contracts/pledgeVault/hooks/usePoolQuery';
import { InitialPhaseState } from '../constants';
import { isValidPledgeStatus, mapPhases } from '../helpers';
import {
  PoolConfigParams,
  validateAccessLevels,
  validateCaps,
  validateDates,
  validatePhases,
  validatePhasesAccessLevel,
  validatePhasesCaps,
  validatePhasesDates,
  validatePoolConfigField,
} from '../components/validators';
import useFormattedState from './hooks/useFormattedPoolState';
import { checkIsPhaseValid } from '../actions/helpers';

const DeployContractContext = createContext();

export const useDeployContractContext = () => useContext(DeployContractContext);

export const DeployContractProvider = ({ deal, children }) => {
  const [whitelistPickerData, setWhitelistPickerData] = useState(null);
  const [phases, setPhases] = useState([InitialPhaseState]);
  const [phaseIdsToUpdate, setPhaseIdsToUpdate] = useState([]);
  const [poolConfig, setPoolConfig] = useFormattedState({
    totalContribution: '',
    minContribution: '',
    maxContribution: '',
    dealSize: '',
  });
  const [isDirty, setIsDirty] = useState(false);

  const [phaseDisplayState, setPhaseDisplayState] = useState({
    id: 0,
    accordion: 'phase-settings',
  });

  const phaseValidState = checkIsPhaseValid(phases[phaseDisplayState?.id]);

  const [errors, setErrors] = useState({});

  const { pool, isLoading: isLoadingPool } = usePoolQuery(deal);

  const { pool: pledgePool, isLoading: isLoadingPledgePool } = usePledgePoolQuery(deal.address);

  useEffect(() => {
    if (!pool?.dealSize || isLoadingPool || isLoadingPledgePool) {
      return;
    }

    setPoolConfig({
      dealSize: pool.dealSize,
      minContribution: pool.minContribution,
      totalContribution: pool.totalContribution,
      maxContribution: +pool.maxContribution || '',
    });
  }, [pool, isLoadingPool, isLoadingPledgePool, setPoolConfig]);

  useEffect(() => {
    if (!deal.phases || !deal.phases.length) return;

    setPhaseIdsToUpdate([]);
    setPhases(() => mapPhases(deal.phases));
  }, [deal.phases]);

  const toggleExpand = (index) => {
    setPhases((prev) =>
      prev.map((phase, i) => (index === i ? { ...phase, expanded: !phase.expanded } : phase))
    );
  };

  const handlePhaseChange = (name, value, index) => {
    if (['cap', 'levels', 'whitelist', 'nftPrice'].includes(name)) {
      validateCaps(name, value, { index, poolConfig, errors, setErrors });
    }

    if (name === 'accessLevels') {
      validateAccessLevels(value, { index, setErrors });
    }

    if (name === 'startDate') {
      validateDates(value, phases[index].endDate, { index, setErrors });
    }

    if (name === 'endDate') {
      validateDates(phases[index].startDate, value, { index, setErrors });
    }

    setPhaseIdsToUpdate((prev) => {
      if (deal.phases.find((p) => p.index === phases[index].index)) {
        return [...prev, phases[index].index];
      }

      return prev;
    });

    setPhases((prev) =>
      prev.map((phase, i) => (index === i ? { ...phase, [name]: value } : phase))
    );
  };

  const addNewPhase = () => {
    setPhases((prev) => [...prev, { ...InitialPhaseState, index: prev.length + 1 }]);
    setPhaseDisplayState({
      id: phases.length,
      accordion: 'phase-settings',
    });
    setIsDirty(false);
  };

  const arePhasesValid = useMemo(() => {
    const arePhasesValid = validatePhases(phases);

    if (arePhasesValid) {
      setIsDirty(false);
    }

    return arePhasesValid;
  }, [phases]);

  const validatePoolConfig = () => {
    const errors = PoolConfigParams.map((key) =>
      validatePoolConfigField(key, poolConfig[key], {
        setErrors,
        poolConfig,
      })
    );

    return { errors, isFormValid: !errors.some((error) => !!error) };
  };

  const triggerDirty = () => {
    if (isDirty) {
      return;
    }

    validatePhasesDates(phases, { setErrors });
    validatePhasesAccessLevel(phases, { setErrors });
    validatePhasesCaps(phases, { poolConfig, setErrors });
    validatePoolConfig();

    setIsDirty(true);
  };

  return (
    <DeployContractContext.Provider
      value={{
        deal,
        pool,
        phases,
        setPhases,
        errors,
        setErrors,
        pledgePool,
        poolConfig,
        addNewPhase,
        setPoolConfig,
        toggleExpand,
        arePhasesValid,
        phaseIdsToUpdate,
        handlePhaseChange,
        whitelistPickerData,
        setWhitelistPickerData,
        isLoadingPool: isLoadingPool || isLoadingPledgePool,
        isValidPledgeStatus: isValidPledgeStatus(deal, pledgePool),
        hasErrors: Object.values(errors).some((error) => !!error),
        phaseDisplayState,
        setPhaseDisplayState,
        phaseValidState,
        isDirty,
        triggerDirty,
        validatePoolConfig,
      }}
    >
      {children}
    </DeployContractContext.Provider>
  );
};
