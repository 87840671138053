import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import NumberFormat from 'react-number-format';

function NumberInput({
  label,
  subLabel,
  value,
  name,
  placeholder,
  disabled,
  onChange,
  onBlur,
  error,
  decimalNumber,
  max,
  isAllowed,
}) {
  return (
    <div className={`custom-number-input${error?.length > 0 ? ' error' : ''}`}>
      {label.length > 0 && (
        <label htmlFor="custom-number-input">
          {label}
          {subLabel ? <span className="sub-label"> {subLabel}</span> : null}
        </label>
      )}
      <NumberFormat
        name={name}
        value={value}
        className="real-input"
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        max={max}
        isAllowed={isAllowed}
        isNumericString
        allowNegative={false}
        allowLeadingZeros={false}
        decimalScale={decimalNumber}
      />
      {error && error.length > 0 && <span className="error-message">{error}</span>}
    </div>
  );
}

NumberInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

NumberInput.defaultProps = {
  label: '',
  value: '',
  placeholder: '',
  disabled: false,
  onChange: () => {},
  onBlur: () => {},
};

export default NumberInput;
