import React, { useState } from 'react';

import './index.scss';
import { DealStatus } from 'models/constants';
import usePledgeVersionUpdate from './hooks/usePledgeVersionUpdate';
import PledgeSwitchInput from '../PledgeSwitchInput';

function PledgeVersionSwitch({ deal }) {
  const [isOnChain, setIsOnChain] = useState(!deal.useOldPledge);

  const { mutate: updatePledgeVersion, isLoading } = usePledgeVersionUpdate(deal.id, {
    onMutate: () => {
      setIsOnChain(!isOnChain);
    },
    onError: () => {
      setIsOnChain(isOnChain);
    },
  });

  const isDisabled = deal.statusId !== DealStatus.None || isLoading;

  const handleChange = async (value) => {
    if (isDisabled) return;

    updatePledgeVersion({ useOldPledge: !value });
  };

  return (
    <div className={`${isDisabled ? 'pledge-switch-input-disabled' : ''}`}>
      <div>On-chain</div>
      <PledgeSwitchInput
        id="pledge-version-switch"
        checked={isOnChain}
        onChange={handleChange}
        disabled={isDisabled}
      />
    </div>
  );
}

export default PledgeVersionSwitch;
