import { useNotifications } from 'hooks';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { setClaimerTokens } from 'services/apiService';
import { updateDeal } from 'store/actions';

function useClaimerTokenMutation({ deal, selectedNetwork, onSuccess }) {
  const dispatch = useDispatch();

  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const { mutate: updateClaimerTokens, isLoading } = useMutation(
    ({ claimerId, chainId, tokens }) => setClaimerTokens(claimerId, chainId, tokens),
    {
      onSuccess: (claimerTokens) => {
        dispatch(updateDeal({ ...deal, [`${selectedNetwork}ClaimerTokens`]: claimerTokens }));

        showSuccessNotification('Claimer token addresses set successfully');
        onSuccess();
      },
      onError: () => {
        showErrorNotification('Failed to set claimer token addresses');
      },
    }
  );

  return { updateClaimerTokens, isLoading };
}

export default useClaimerTokenMutation;
