import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import RoundedButton from 'components/button/rounded-button';
import RoundedAvatar from 'components/avatar/rounded-avatar';
import CustomProgressBar from 'components/progress-bar/custom-progress-bar';
import CustomInput from 'components/input/custom-input';
import './index.scss';
import { getAddressLinkByChainId } from 'contracts/explorers';
import {
  updateLoading,
  setActiveHashes,
  addNotification,
  setSharedNotification,
  updateDeal,
} from 'store/actions';
import notificationTypes from 'constants/notificationTypes';
import { addNetwork } from 'contracts/browserWallet';
import { createClaimer } from 'contracts/claimerContract';
import { getWantedNetwork } from 'utils/helpers';
import IconButton from 'components/button/icon-button';
import { Box } from '@material-ui/core';
import { getERC20Metadata } from 'contracts/erc20';
import { isAddress } from 'ethers/lib/utils';
import useClaimerTokenMutation from './hooks/useClaimerTokenMutation';

const CreateClaimerModal = ({ open, onClose, deal, showErrorNotification }) => {
  const dispatch = useDispatch();
  const globalReducer = useSelector((state) => state.global);
  const [selectedNetwork, setSelectedNetwork] = useState('ethereum');
  const [isDeployed, setIsDeployed] = useState(!!deal.ethClaimerAddress);
  const [currClaimerId, setCurrClaimerId] = useState(deal.ethClaimerId);
  const [currClaimerAddress, setCurrClaimerAddress] = useState(deal.ethClaimerAddress);
  const [currTokenAddresses, setCurrTokenAddresses] = useState(
    deal?.ethClaimerTokens?.map(({ address }) => address) || ['']
  );

  const { chainId, activeHashes } = globalReducer;

  const [errors, setErrors] = useState(null);
  const [isDirty, setIsDirty] = useState(currTokenAddresses.map(() => false));

  const { updateClaimerTokens, isLoading: isUpdatingToken } = useClaimerTokenMutation({
    deal,
    selectedNetwork,
    onSuccess: (claimerAddresses) => {
      if (
        !['ethereum', 'bsc', 'polygon', 'avalanche', 'base', 'merlin', 'arbitrum'].includes(
          selectedNetwork
        )
      ) {
        throw new Error(`Unfamiliar network ${selectedNetwork}`);
      }

      dispatch(updateDeal({ ...deal, [`${selectedNetwork}ClaimerTokens`]: claimerAddresses }));
      onClose();
    },
  });

  const onDeploy = async () => {
    const { address, merkleRoot, total } = deal;
    dispatch(updateLoading(true));
    const network = getWantedNetwork(selectedNetwork);

    const networkChecked = await addNetwork(chainId, network);

    if (networkChecked) {
      const tx = await createClaimer(address, selectedNetwork, merkleRoot, total);
      if (tx) {
        dispatch(
          setActiveHashes([
            ...activeHashes,
            {
              hash: tx.hash,
              pending: false,
              chain: selectedNetwork,
              callback: () => {
                setIsDeployed(true);
              },
            },
          ])
        );
        dispatch(
          addNotification({
            name: tx.hash,
            chain: selectedNetwork,
            dealAddress: deal.address,
            dealImage: deal.imageUrl,
            status: 'pending',
            statusText: 'Pending!',
            time: Date.now(),
            type: notificationTypes.GENERAL,
          })
        );
      } else {
        showErrorNotification('Something went wrong.');
      }
    } else {
      dispatch(
        setSharedNotification({
          status: 'error',
          title: 'Error',
          description: `You need to change your network to "${network}" to continue.`,
        })
      );
    }

    dispatch(updateLoading(false));
  };

  const setClaimerToken = async () => {
    dispatch(updateLoading(true));
    try {
      const { chainId, tokensWithMetadata } = await getERC20Metadata(
        selectedNetwork,
        currTokenAddresses
      );

      await updateClaimerTokens({
        claimerId: currClaimerId,
        tokens: tokensWithMetadata,
        chainId,
      });
    } catch (error) {
      console.error(error);
      showErrorNotification(error.response?.message);
    } finally {
      dispatch(updateLoading(false));
    }
  };

  const areTokenAddressesValid = currTokenAddresses.every((address) => isAddress(address));

  useEffect(() => {
    if (!areTokenAddressesValid) {
      setErrors('Token addresses must be valid Ethereum addresses.');
    } else {
      setErrors(null);
    }
  }, [areTokenAddressesValid]);

  const onChangeNetwork = (network) => {
    setSelectedNetwork(network);
    setIsDirty([]);
  };

  const handleInputChange = (value, index) => {
    setCurrTokenAddresses((prev) => {
      const newAddresses = [...prev];
      newAddresses[index] = value;
      return newAddresses;
    });

    setIsDirty((prev) => {
      const newDirty = [...prev];
      newDirty[index] = true;
      return newDirty;
    });
  };

  return (
    <Dialog
      open={open}
      className="create-claimer-modal"
      id="create-claimer-modal"
      onClose={onClose}
    >
      <div className="create-claimer-modal-header d-flex">
        <div className="create-claimer-modal-header__left d-flex vertical-center">
          <div className="deal-avatar vertical-center">
            <a
              href={deal ? getAddressLinkByChainId(deal.chainId, deal.address) : ''}
              target="_blank"
              rel="noopener noreferrer"
            >
              <RoundedAvatar src={deal.imageUrl} />
            </a>
          </div>
          <div className="deal-name vertical-center">
            <div className="full-width">
              <span>{deal.name}</span>
              <CustomProgressBar total={Number(deal.dealSize)} value={Number(deal.raisedAmount)} />
            </div>
          </div>
        </div>
        <div className="create-claimer-modal-header__right vertical-center">
          <RoundedButton onClick={onClose}>Cancel</RoundedButton>
        </div>
      </div>
      <div className="network-selection">
        <button
          type="button"
          className={`network-selection-button${selectedNetwork === 'ethereum' ? ' active' : ''}`}
          onClick={() => {
            onChangeNetwork('ethereum');
            setIsDeployed(!!deal.ethClaimerAddress);
            setCurrClaimerAddress(deal.ethClaimerAddress);
            setCurrClaimerId(deal.ethClaimerId);
            setCurrTokenAddresses(deal?.ethClaimerTokens?.map(({ address }) => address) || ['']);
          }}
        >
          Ethereum
        </button>
        <button
          type="button"
          className={`network-selection-button${selectedNetwork === 'bsc' ? ' active' : ''}`}
          onClick={() => {
            onChangeNetwork('bsc');
            setIsDeployed(!!deal.bscClaimerAddress);
            setCurrClaimerAddress(deal.bscClaimerAddress);
            setCurrClaimerId(deal.bscClaimerId);
            setCurrTokenAddresses(deal?.bscClaimerTokens?.map(({ address }) => address) || ['']);
          }}
        >
          BSC
        </button>
        <button
          type="button"
          className={`network-selection-button${selectedNetwork === 'polygon' ? ' active' : ''}`}
          onClick={() => {
            onChangeNetwork('polygon');
            setIsDeployed(!!deal.polygonClaimerAddress);
            setCurrClaimerAddress(deal.polygonClaimerAddress);
            setCurrClaimerId(deal.polygonClaimerId);
            setCurrTokenAddresses(
              deal?.polygonClaimerTokens?.map(({ address }) => address) || ['']
            );
          }}
        >
          POLYGON
        </button>
        <button
          type="button"
          className={`network-selection-button${selectedNetwork === 'avalanche' ? ' active' : ''}`}
          onClick={() => {
            onChangeNetwork('avalanche');
            setIsDeployed(!!deal.avalancheClaimerAddress);
            setCurrClaimerAddress(deal.avalancheClaimerAddress);
            setCurrClaimerId(deal.avalancheClaimerId);
            setCurrTokenAddresses(
              deal?.avalancheClaimerTokens?.map(({ address }) => address) || ['']
            );
          }}
        >
          AVALANCHE
        </button>
        <br />
        <button
          type="button"
          className={`network-selection-button${selectedNetwork === 'base' ? ' active' : ''}`}
          onClick={() => {
            onChangeNetwork('base');
            setIsDeployed(!!deal.baseClaimerAddress);
            setCurrClaimerAddress(deal.baseClaimerAddress);
            setCurrClaimerId(deal.baseClaimerId);
            setCurrTokenAddresses(deal?.baseClaimerTokens?.map(({ address }) => address) || ['']);
          }}
        >
          BASE
        </button>
        <button
          type="button"
          className={`network-selection-button${selectedNetwork === 'merlin' ? ' active' : ''}`}
          onClick={() => {
            onChangeNetwork('merlin');
            setIsDeployed(!!deal.merlinClaimerAddress);
            setCurrClaimerAddress(deal.merlinClaimerAddress);
            setCurrClaimerId(deal.merlinClaimerId);
            setCurrTokenAddresses(deal?.merlinClaimerTokens?.map(({ address }) => address) || ['']);
          }}
        >
          MERLIN
        </button>
        <button
          type="button"
          className={`network-selection-button${selectedNetwork === 'arbitrum' ? ' active' : ''}`}
          onClick={() => {
            onChangeNetwork('arbitrum');
            setIsDeployed(!!deal.arbitrumClaimerAddress);
            setCurrClaimerAddress(deal.arbitrumClaimerAddress);
            setCurrClaimerId(deal.arbitrumClaimerId);
            setCurrTokenAddresses(
              deal?.arbitrumClaimerTokens?.map(({ address }) => address) || ['']
            );
          }}
        >
          ARBITRUM
        </button>
      </div>
      <div className="create-claimer-modal-body">
        <div className="step step-one">
          <p>
            DEPLOY CLAIMER: <br />
            {currClaimerAddress}
          </p>
          <RoundedButton type="secondary" disabled={isDeployed} onClick={onDeploy}>
            Deploy contract
          </RoundedButton>
        </div>
        <div className="step step-one">
          {currTokenAddresses.map((address, i) => (
            <CustomInput
              label={`Token address ${i + 1}`}
              key={i}
              value={address}
              onChange={(e) => handleInputChange(e.target.value, i)}
              disabled={!isDeployed}
            />
          ))}
          <Box display="flex" alignItems="center" justifyContent="space-between" mt={1}>
            <RoundedButton
              type="secondary"
              disabled={!isDeployed || isUpdatingToken || !areTokenAddressesValid}
              onClick={setClaimerToken}
            >
              Set Token Addresses
            </RoundedButton>
            {errors && isDirty.some((dirty) => dirty) && (
              <span className="add-token-address-error-info">{errors}</span>
            )}
            <IconButton
              disabled={!isDeployed || isUpdatingToken || !areTokenAddressesValid}
              icon="plus"
              onClick={() => setCurrTokenAddresses((prev) => [...prev, ''])}
            />
          </Box>
        </div>
      </div>
    </Dialog>
  );
};

export default CreateClaimerModal;
