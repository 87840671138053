import React, { useEffect, useRef } from 'react';

import './index.scss';
import { useDeployContractContext } from 'features/deals/DealInfo/DeployContractTab/context';
import ActivePhase from './components/ActivePhase';
import moment from 'moment';
import Icon from 'components/svgIcon';
import { checkIsPhaseValid } from '../../actions/helpers';

export default function DealPhaseEditor() {
  const { deal, phases, phaseDisplayState, setPhaseDisplayState, addNewPhase, isDirty } =
    useDeployContractContext();

  const containerRef = useRef(null);

  const createdPhaseIds = deal.phases.map(({ index }) => index);

  const currentPhase = phases[phaseDisplayState?.id];

  const checkIsActive = (phase) => {
    const now = new Date();

    const isActive = now >= phase.startDate && now <= phase.endDate;

    const isNew = !createdPhaseIds.includes(phase?.index);

    return isNew ? 'draft' : isActive ? 'active' : 'upcoming';
  };

  useEffect(() => {
    if (deal.phases.length === phases.length) return;

    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [phases, deal.phases]);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div className="phases-vertical-list">
        <div className="phases-container" ref={containerRef}>
          <div className="space-y-2">
            {phases.map((phase, index) => (
              <div
                key={`${phase.id}-${index}`}
                onClick={() => setPhaseDisplayState({ id: index, accordion: 'phase-settings' })}
                className={`phase-item ${
                  phaseDisplayState?.id === index ? 'phases-container__selected' : ''
                }`}
              >
                <div className="phase-header">
                  <div className="phase-name">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                        maxWidth: 170,
                      }}
                    >
                      <span
                        style={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {index + 1}. {phase.phaseName}
                      </span>
                    </div>
                    {!checkIsPhaseValid(phase).isValid && isDirty ? (
                      <Icon name="error" width={14} height={14} />
                    ) : null}
                  </div>
                  <span className={`badge badge__${checkIsActive(phase)}`}>
                    {checkIsActive(phase)}
                  </span>
                </div>
                <div className="phase-date">
                  {moment(phase.startDate).format('MM/DD/YY HH:mm')} -{' '}
                  {moment(phase.endDate).format('MM/DD/YY HH:mm')}
                </div>
              </div>
            ))}
          </div>

          <button type="button" onClick={addNewPhase} className="add-phase-btn">
            Add New Phase
          </button>
        </div>
      </div>

      {phases?.length > 0 && phaseDisplayState ? (
        <ActivePhase
          phase={currentPhase}
          index={phaseDisplayState.id}
          isNew={!createdPhaseIds.includes(currentPhase?.index)}
        />
      ) : null}
    </div>
  );
}
