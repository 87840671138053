const buildInitialState = (totalPledgedAmount) => ({
  totalRelease: {
    amountFormatted: parseFloat(totalPledgedAmount).toFixed(1),
    amount: totalPledgedAmount,
    refund: 0,
    distribute: 0,
    isDirty: true,
  },
  error: '',
  isManualInput: false,
  isDistributeEvenly: false,
});

const calculateNewReleaseAmounts = ({ pledges, totalRelease, value, index, skipDistribute }) => {
  if (Number.isNaN(+value)) {
    value = 0;
  }

  const oldAmount = pledges[index].acceptedAmount || 0;

  const diff = +value - +oldAmount;

  let newTotalReleaseAmount;

  if (skipDistribute) {
    newTotalReleaseAmount = (+totalRelease.amount || 0) + diff;
  } else {
    newTotalReleaseAmount = (+totalRelease.distribute || 0) + diff;
  }

  return { newTotalReleaseAmount: +newTotalReleaseAmount.toFixed(2), acceptedAmount: value };
};

const sumPledges = (pledges) => {
  return pledges.reduce((acc, pledge) => acc + +pledge.newAmount || 0, 0);
};

export { buildInitialState, calculateNewReleaseAmounts, sumPledges };
