import React from 'react';
import { useSelector } from 'react-redux';
import RoundedAvatar from 'components/avatar/rounded-avatar';
import RoundedButton from 'components/button/rounded-button';

import './index.scss';
import useRemainingAllocationsQuery from 'contracts/poolManager/hooks/useRemainingAllocationsQuery';
import { Dialog, Divider } from '@material-ui/core';
import CustomProgressBar from 'components/progress-bar/custom-progress-bar';
import { useIsMutating } from 'react-query';
import { utils } from 'ethers';
import { buildAllowlistData, getAllowedPhases } from '../../helpers';
import ContributePhase from './components/ContributePhase';

const ContributeModal = ({ deal, isOpen, onClose }) => {
  const authReducer = useSelector((state) => state.auth);

  const { accountInfo } = authReducer;

  const eligiblePhases = getAllowedPhases(deal.activePhases, accountInfo);

  const { remainingAllocations } = useRemainingAllocationsQuery(deal, {
    phaseIds: eligiblePhases.map((phase) => phase.index),
    allowListDatas: eligiblePhases.map((phase) => buildAllowlistData(phase)),
  });

  const isMutating = useIsMutating();

  return (
    <Dialog
      className="pledge-action-modal-overlay"
      open={isOpen}
      onClose={onClose}
      onClick={(e) => e.stopPropagation()}
      maxWidth="xl"
    >
      <div className="modal-container">
        <div className="contribute-modal-header">
          <div>Contribute</div>
        </div>
        <div className="contribute-modal-divider-container">
          <Divider />
        </div>
        <div className="user-info">
          <div className="user-avatar">
            <RoundedAvatar src={deal.imageUrl} />
            <div>
              <span>{deal.name}</span>
              {deal.dealSize && deal.raisedAmount && (
                <CustomProgressBar
                  total={Number(deal.dealSize)}
                  value={Number(deal.raisedAmount)}
                />
              )}
            </div>
          </div>
          <div className="user-name">{deal.name}</div>
        </div>

        <div>
          {eligiblePhases.map((phase, i) => (
            <ContributePhase
              allocation={remainingAllocations?.[i]}
              key={phase.id}
              deal={deal}
              phase={phase}
              onClose={onClose}
            />
          ))}
        </div>

        <div className="contribute-modal-divider-container">
          <Divider />
        </div>
        <div className="button-container">
          <RoundedButton disabled={isMutating} onClick={onClose}>
            Cancel
          </RoundedButton>
          <RoundedButton type="secondary" disabled={isMutating} onClick={onClose}>
            Done
          </RoundedButton>
        </div>
      </div>
    </Dialog>
  );
};

export default ContributeModal;
