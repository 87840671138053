import React, { useEffect, useState } from 'react';
import RoundedButton from 'components/button/rounded-button';
import NumberInput from 'components/input/number-input';
import './index.scss';
import { roundNumberToDecimals } from 'utils/helpers';
import { Dialog, Divider } from '@material-ui/core';
import useReleaseMutation from 'contracts/pledgeVault/hooks/useReleaseMutation';

import usePledgePoolQuery from 'contracts/pledgeVault/hooks/usePledgePoolQuery';
import RoundedAvatar from 'components/avatar/rounded-avatar';
import CustomSlider from 'components/progress-bar/custom-slider';
import useMyContributionQuery from 'contracts/poolManager/hooks/useMyContributionQuery';

const ReleaseModal = ({ deal, onLoad, onCancel, defaultValue, isOpen }) => {
  const [data, setData] = useState({
    amount: defaultValue ? roundNumberToDecimals(Number(defaultValue), 0) : '',
  });
  const [errorMessage, setErrorMessage] = useState('');

  const { pool } = usePledgePoolQuery(deal.address);

  const { myContribution: max } = useMyContributionQuery(deal);

  const { release, isLoading } = useReleaseMutation(deal.address, {
    onSuccess: () => {
      onLoad();
      onCancel();
    },
    onError: onCancel,
  });

  const getErrorMessage = () => {
    if (errorMessage === 'max') {
      return `Max. = ${max}`;
    }

    return '';
  };

  useEffect(() => {
    if (!pool) return;

    if (+max < data.amount) {
      setErrorMessage('max');
    } else {
      setErrorMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onChangeData = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const onConfirm = async () => {
    const amountInStr = data.amount.toString();

    await release(amountInStr);
  };

  const handleSliderChange = (_, value) => {
    onChangeData('amount', value);
  };

  const handleMaxClick = () => {
    onChangeData('amount', max);
  };

  return (
    <Dialog
      className="pledge-action-modal-overlay"
      open={isOpen}
      onClose={onCancel}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="modal-container">
        <div className="release-modal-header">
          <div>Release Amount</div>
        </div>
        <div className="release-modal-divider-container">
          <Divider />
        </div>

        <div className="user-info">
          <div className="user-avatar">
            <RoundedAvatar src={deal.imageUrl} />
          </div>
          <div className="user-name">{deal.name}</div>
        </div>

        <div className="release-modal-slider-container">
          <CustomSlider
            min={0}
            max={+max}
            value={data.amount}
            onChange={handleSliderChange}
            disabled={isLoading}
          />
          <div className="release-modal-amount-input">
            <NumberInput
              name="amount"
              value={data.amount.toString()}
              onChange={(e) => onChangeData(e.target.name, e.target.value)}
              error={getErrorMessage()}
              decimalNumber="0"
              disabled={isLoading}
            />
            <RoundedButton className="release-modal-max-button" onClick={handleMaxClick}>
              MAX
            </RoundedButton>
            <span>USDT</span>
          </div>
        </div>
        <div className="release-modal-divider-container">
          <Divider />
        </div>
        <div className="button-container">
          <RoundedButton disabled={isLoading} onClick={onCancel}>
            Cancel
          </RoundedButton>
          <RoundedButton
            type="secondary"
            disabled={isLoading || data.amount <= 0 || !!errorMessage}
            onClick={onConfirm}
          >
            Confirm
          </RoundedButton>
        </div>
      </div>
    </Dialog>
  );
};

export default ReleaseModal;
