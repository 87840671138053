import React, { useState } from 'react';
import { getAddressLinkByChainId } from 'contracts/explorers';
import { ARBITRUM_CHAIN_ID } from 'constants/config';

import { roundNumberToDecimals, shortenAddress } from 'utils/helpers';
import moment from 'moment';
import IconButton from 'components/button/icon-button';
import PledgeTxListModal from './PledgeTxListModal';

const PledgeTxList = ({ deal, pledges }) => {
  const [showAllTx, setShowAllTx] = useState(false);
  const [selectedPledge, setSelectedPledge] = useState(null);

  return (
    <div className="pledge-transactions">
      <div className="pledge-transaction-row header-row">
        <div className="pledge-transactions-col date-col">Last transaction date</div>
        <div className="pledge-transactions-col address-col">Wallet ID</div>
        <div className="pledge-transactions-col">Pledge amount</div>
        <div className="pledge-transactions-col">Accepted amount</div>
      </div>
      {pledges?.length ? (
        pledges.map((p) => (
          <div className="pledge-transaction-row" key={p.id.toString()}>
            <div className="pledge-transactions-col date-col">
              <p>{moment(p.updatedAt).format('D-MMMM-YYYY, HH:mm:ss')}</p>
            </div>
            <div className="pledge-transactions-col address-col">
              <a
                href={getAddressLinkByChainId(ARBITRUM_CHAIN_ID, p.wallet)}
                className="underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                {p.wallet}
              </a>
            </div>
            <div className="pledge-transactions-col amount-col">
              <p>${roundNumberToDecimals(Number(p.amount), 0)}</p>
            </div>
            <div className="pledge-transactions-col amount-col">
              <p>
                {p.acceptedAmount
                  ? `$${roundNumberToDecimals(Number(p.acceptedAmount), 0)}`
                  : 'N/A'}
              </p>
            </div>
            {!deal.useOldPledge ? (
              <div className="pledge-transactions-col show-all-col">
                <IconButton
                  icon="iconTokensClaimed"
                  onClick={() => {
                    setSelectedPledge(p);
                    setShowAllTx(true);
                  }}
                />
              </div>
            ) : null}
          </div>
        ))
      ) : (
        <div style={{ marginTop: '100px', textAlign: 'center' }}>
          There are no pledges currently
        </div>
      )}
      <PledgeTxListModal
        open={showAllTx}
        deal={deal}
        wallet={selectedPledge?.wallet}
        onClose={() => setShowAllTx(false)}
      />
    </div>
  );
};

export default PledgeTxList;
