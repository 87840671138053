import React, { useState } from 'react';
import RoundedButton from 'components/button/rounded-button';
import '../../index.scss';
import Tooltip from 'components/tooltip';
import { useSelector } from 'react-redux';
import useOldPledgeMutation from './hooks/useOldPledgeMutation';
import PledgeModal from '../../Modal/PledgeModal';

export default function OldUserPledgeControl({ deal, onLoad }) {
  const authReducer = useSelector((state) => state.auth);
  const { accountInfo } = authReducer;

  const [noPledgeMessage, setNoPledgeMessage] = useState('');

  const [pledgeModalOpen, setPledgeModalOpen] = useState(false);

  const pledgeMutation = useOldPledgeMutation(deal.id, { onSuccess: onLoad });

  const handleNoPledge = (accountInfo) => {
    if (!accountInfo) return;
    if (accountInfo.userAccessLevel === -1 && accountInfo.relockMessage?.length) {
      setNoPledgeMessage(accountInfo.relockMessage);
      return;
    }

    setNoPledgeMessage('');
  };

  if (![1, 7].includes(deal.statusId)) return null;

  return (
    <>
      <Tooltip title={noPledgeMessage}>
        <div
          className="deal__field deal__field-status-stepper vertical-center vertical-gap pledge-action-button"
          onMouseEnter={() => handleNoPledge(accountInfo)}
        >
          <RoundedButton
            type="secondary"
            disabled={accountInfo.userAccessLevel === -1}
            onClick={() => setPledgeModalOpen(true)}
          >
            Pledge
          </RoundedButton>
        </div>
      </Tooltip>
      <PledgeModal
        deal={deal}
        pledgeMutation={pledgeMutation}
        isOpen={pledgeModalOpen}
        onCancel={() => setPledgeModalOpen(false)}
        defaultValue={deal.pledge}
      />
    </>
  );
}
