import React from 'react';
import RoundedButton from 'components/button/rounded-button';

import { useNotifications } from 'hooks';
import { useQueryClient } from 'react-query';
import useCreatePoolPhases from 'contracts/poolManager/hooks/useCreatePoolPhases';
import useWithErrorNotifier from 'hooks/useWithErrorNotifier';
import Tooltip from 'components/tooltip';
import { useDeployContractContext } from '../context';
import usePhaseMerkleRoot from '../../hooks/usePhaseMerkleRoot';
import { preparePhasesForDeploy } from '../helpers';

function CreatePhasesAction({ phase }) {
  const [showSuccessNotification] = useNotifications();

  const { deal, poolConfig, hasErrors, phaseValidState, triggerDirty } = useDeployContractContext();

  const queryClient = useQueryClient();

  const { createPoolPhases, isLoading: isCreatingPhase } = useCreatePoolPhases(deal.address, {
    onSuccess: () => {
      queryClient.invalidateQueries(['deal', deal.id]);
      queryClient.invalidateQueries(['admin-deal', deal.id]);
      queryClient.invalidateQueries(['deal', deal.id, 'pledge']);

      showSuccessNotification('Phase created successfully');
    },
  });

  const onSuccess = useWithErrorNotifier((result) => {
    const phases = preparePhasesForDeploy(result.phases);

    if (!phases) {
      return;
    }

    createPoolPhases({
      ...phases,
    });
  });

  const { insertMerkleRoots } = usePhaseMerkleRoot(deal.id, {
    onSuccess,
  });

  const onCreate = (e) => {
    e.stopPropagation();
    insertMerkleRoots({ phases: [phase], poolConfig });
  };

  const { isValid, errorMessage } = phaseValidState;

  const isDisabled = isCreatingPhase || !isValid || hasErrors;

  return (
    <Tooltip title={errorMessage} onMouseEnter={triggerDirty}>
      <div>
        <RoundedButton onClick={onCreate} disabled={isDisabled} type="secondary">
          Create Phase
        </RoundedButton>
      </div>
    </Tooltip>
  );
}

export default CreatePhasesAction;
