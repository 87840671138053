import { useMutation } from 'react-query';
import { batchPledgesForRelease } from 'services/apiService';

export default function useBatchPledgesForRelease(dealId, options = {}) {
  const { mutateAsync: batchPledges, isLoading } = useMutation(
    (data) => batchPledgesForRelease(dealId, data),
    { ...options }
  );

  return {
    batchPledges,
    isLoading,
  };
}
