import Icon from 'components/svgIcon';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import './index.scss';
import { LevelOptions } from 'features/deals/DealInfo/DeployContractTab/constants';

export default function MultiSelectChip({
  name,
  initialState,
  label,
  options,
  placeholder,
  onChange,
  error,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    let newSelectedOptions;

    if (selectedOptions.some((item) => item.value === option.value)) {
      newSelectedOptions = selectedOptions.filter((item) => item.value !== option.value);
    } else {
      newSelectedOptions = [...selectedOptions, option];
    }

    const sortedOptions = newSelectedOptions.sort((a, b) => a.value - b.value);

    setSelectedOptions(sortedOptions);

    onChange(
      name,
      sortedOptions.map((opt) => opt.value)
    );
  };

  const removeOption = (option, e) => {
    e.stopPropagation();

    const newSelectedOptions = selectedOptions.filter((item) => item.value !== option.value);
    setSelectedOptions(newSelectedOptions);

    onChange(
      name,
      newSelectedOptions.map((opt) => opt.value)
    );
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    const selectedOptions = initialState.map((value) =>
      LevelOptions.find((option) => option.value === value)
    );

    setSelectedOptions(selectedOptions);
  }, [initialState]);

  const maxWidth = useMemo(() => {
    const optionCount = selectedOptions.length;

    if (optionCount <= 1) return 200;

    if (optionCount > 1 && optionCount < 5) return optionCount * 112;

    return 460;
  }, [selectedOptions]);

  return (
    <div
      className={`multiselect-container ${error ? 'error' : ''}`}
      style={{ maxWidth }}
      ref={dropdownRef}
    >
      <label className="multiselect-label">{label}</label>
      <div className="multiselect-field" onClick={toggleDropdown}>
        <div className="multiselect-selected">
          {selectedOptions.length === 0 ? (
            <span className="multiselect-placeholder">{placeholder}</span>
          ) : (
            <div className="multiselect-chips">
              {selectedOptions.map((option) => (
                <div key={option.value} className="multiselect-chip">
                  {option.name}
                  <button
                    type="button"
                    className="multiselect-chip-remove"
                    onClick={(e) => removeOption(option, e)}
                    aria-label={`Remove ${option.name}`}
                  >
                    <Icon name="closeGray" width={14} height={14} />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="multiselect-arrow">
          <Icon name="arrowDown" width={18} height={18} />
        </div>
      </div>
      {isOpen && (
        <div className="multiselect-dropdown">
          {options.map((option) => (
            <div
              key={option.value}
              className={`multiselect-option ${
                selectedOptions.some((item) => item.value === option.value) ? 'selected' : ''
              }`}
              onClick={() => handleOptionClick(option)}
            >
              {option.name}
            </div>
          ))}
        </div>
      )}
      {error && <span className="error-message">{error}</span>}
    </div>
  );
}
