import { ethers } from 'ethers';
import { arrayify } from 'ethers/lib/utils';

const isPhaseAllowed = (phase, accountInfo) => {
  return phase.isWhitelisted || phase.accessLevels.includes(+accountInfo.userAccessLevel);
};

const getAllowedPhases = (activePhases, accountInfo) => {
  return (
    activePhases
      ?.filter((phase) => isPhaseAllowed(phase, accountInfo))
      .sort((a, b) => a.index - b.index) || []
  );
};

const hasAllowedPhases = (activePhases, accountInfo) => {
  return activePhases?.some((phase) => isPhaseAllowed(phase, accountInfo));
};

const buildAllowlistData = (phase) => {
  return {
    useAllowlist: !!phase.allowlistAmount?.toString(),
    amountOnAllowlist: ethers.BigNumber.from(phase.allowlistAmount?.toString() || '0'),
    proof: phase.allowlistProof?.map((p) => arrayify(p)) || [],
  };
};

export { getAllowedPhases, hasAllowedPhases, buildAllowlistData };
