import { DealStatus } from 'models/constants';
import PoolStatus from 'contracts/poolManager/constants';
import { DealValidState } from './constants';

const isDealValid = (deal, type) => {
  switch (type) {
    case DealValidState.PledgeFreezed: {
      // deal is promoted to live
      if (deal.datePublished) {
        return true;
      }

      if (!deal.isValidPledgeStatus) {
        return false;
      }

      return true;
    }
    case DealValidState.OldPledgeClosed: {
      if (!deal.useOldPledge) {
        return true;
      }

      return deal.statusId === DealStatus.Live || deal.statusId === DealStatus.PledgeClosed;
    }
    case DealValidState.FeeExists:
      return !!deal.fee;
    case DealValidState.CorrectState:
      return [
        PoolStatus.Active,
        PoolStatus.Paused,
        PoolStatus.NotActive,
        PoolStatus.Freezed,
        DealStatus.PledgeClosed, // offchain support
      ].includes(deal.statusId);
    default:
      return false;
  }
};

export { isDealValid };
