import React from 'react';
import SvgIcon from 'components/svgIcon';
import moment from 'moment';
import useMyContributionQuery from 'contracts/poolManager/hooks/useMyContributionQuery';

const PledgeTab = ({ deal }) => {
  const { myContribution, isLoading } = useMyContributionQuery(deal, {
    enabled: !deal.useOldPledge,
  });

  const getPledgeAmount = () => {
    if (deal.useOldPledge) {
      return deal.pledgeAmount || '0.0';
    }

    return myContribution;
  };

  return (
    <div className="content pledge-content">
      <div className="content-row">
        <div className="col col-4 pledge-box">
          <div className="pledge-box-image">
            <SvgIcon name="iconPledgeBoxName" />
          </div>
          <div className="pledge-box-info">
            <p className="label">Project name</p>
            <h3 className="value">{deal.name}</h3>
          </div>
        </div>
        <div className="col col-4 pledge-box">
          <div className="pledge-box-image">
            <SvgIcon name="iconPledgeBoxDate" />
          </div>
          <div className="pledge-box-info">
            <p className="label">Pledge date</p>
            <h3 className="value">
              {deal.pledgeCreatedAt
                ? moment(deal.pledgeCreatedAt).format('D-MMMM-YYYY, HH:mm:ss')
                : '-'}
            </h3>
          </div>
        </div>
        <div className="col col-4 pledge-box">
          <div className="pledge-box-image">
            <SvgIcon name="iconPledgeBoxAmount" />
          </div>
          <div className="pledge-box-info">
            <p className="label">Pledge amount</p>
            <h3 className="value">{isLoading ? 'Loading...' : getPledgeAmount()}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PledgeTab;
