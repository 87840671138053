import React from 'react';

import { Dialog } from '@material-ui/core';
import { ReleasePledgeProvider } from './context';
import { PledgeDistributeProvider } from './pages/PledgeDistribute/context';
import ReleasePledgesPopupPages from './pages';

const ReleasePledgesPopup = ({ isOpen, onClose, deal, pledges, onRefetch }) => {
  return (
    <ReleasePledgeProvider deal={deal} onClose={onClose}>
      <PledgeDistributeProvider initialPledges={pledges} onRefetch={onRefetch}>
        <Dialog open={isOpen} onClose={onClose} maxWidth="md">
          <ReleasePledgesPopupPages />
        </Dialog>
      </PledgeDistributeProvider>
    </ReleasePledgeProvider>
  );
};

export default ReleasePledgesPopup;
