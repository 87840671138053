import {
  ETHEREUM_CHAIN_ID,
  XDAI_CHAIN_ID,
  BSC_CHAIN_ID,
  POLYGON_CHAIN_ID,
  AVALANCHE_CHAIN_ID,
  BASE_CHAIN_ID,
  MERLIN_CHAIN_ID,
  ARBITRUM_CHAIN_ID,
  XDAI_CHAIN_NAME,
  ETHEREUM_CHAIN_NAME,
  BSC_CHAIN_NAME,
  POLYGON_CHAIN_NAME,
  AVALANCHE_CHAIN_NAME,
  BASE_CHAIN_NAME,
  MERLIN_CHAIN_NAME,
  ARBITRUM_CHAIN_NAME,
} from 'constants/config';

const ChainIdByName = {
  [XDAI_CHAIN_NAME]: XDAI_CHAIN_ID,
  [ETHEREUM_CHAIN_NAME]: ETHEREUM_CHAIN_ID,
  [BSC_CHAIN_NAME]: BSC_CHAIN_ID,
  [POLYGON_CHAIN_NAME]: POLYGON_CHAIN_ID,
  [AVALANCHE_CHAIN_NAME]: AVALANCHE_CHAIN_ID,
  [BASE_CHAIN_NAME]: BASE_CHAIN_ID,
  [MERLIN_CHAIN_NAME]: MERLIN_CHAIN_ID,
  [ARBITRUM_CHAIN_NAME]: ARBITRUM_CHAIN_ID,
};

const ChainNameById = Object.fromEntries(
  Object.entries(ChainIdByName).map(([name, id]) => [+id, name])
);

export const getChainIdByName = (chainName) => {
  const chainId = ChainIdByName[chainName];
  if (chainId === undefined) {
    throw new Error(`getChainIdByName - unknown chain name ${chainName}`);
  }
  return chainId;
};

export const getChainNameById = (chainId) => {
  const chainName = ChainNameById[+chainId];
  if (chainName === undefined) {
    throw new Error(`getChainNameById - unknown chain id ${chainId}`);
  }
  return chainName;
};
