import React, { useEffect, useState } from 'react';

import './index.scss';
import usePledgePoolQuery from 'contracts/pledgeVault/hooks/usePledgePoolQuery';
import { PledgeStatus } from 'contracts/pledgeVault/constants';
import { useQueryClient } from 'react-query';
import useUpdatePledgeStatus from 'contracts/pledgeVault/hooks/useUpdatePledgeStatus';
import { useNotifications } from 'hooks';
import PledgeSwitchInput from '../PledgeSwitchInput';

function PledgeLockSwitch({ deal }) {
  const [isLocked, setIsLocked] = useState();
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const { pool } = usePledgePoolQuery(deal.address);

  const queryClient = useQueryClient();

  const { updatePledgeStatus, isLoading: isUpdating } = useUpdatePledgeStatus(deal.address, {
    onMutate: () => {
      setIsLocked(!isLocked);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['admin-deal', deal.id]);
      queryClient.invalidateQueries(['deal', deal.id, 'pledge']);

      showSuccessNotification(`Pool status update successfully`);
    },
    onError: () => {
      showErrorNotification(`Error while updating pool status`);
      setIsLocked(isLocked);
    },
  });

  useEffect(() => {
    if (!pool) return;

    const isPoolLocked = pool.status === PledgeStatus.OpenLocked;

    setIsLocked(isPoolLocked);
  }, [pool]);

  const isDisabled =
    ![PledgeStatus.OpenLocked, PledgeStatus.OpenUnlocked].includes(pool?.status) || isUpdating;

  const handleChange = (locked) => {
    if (isDisabled) return;

    let statusToUpdate;

    if (locked) {
      statusToUpdate = PledgeStatus.OpenLocked;
    } else {
      statusToUpdate = PledgeStatus.OpenUnlocked;
    }

    updatePledgeStatus(statusToUpdate);
  };

  if (!pool || isLocked === undefined) return null;

  return (
    <div className={`${isDisabled ? 'pledge-switch-input-disabled' : ''}`}>
      <div>Locked</div>
      <PledgeSwitchInput
        id="pledge-lock-switch"
        checked={isLocked}
        onChange={handleChange}
        disabled={isDisabled}
      />
    </div>
  );
}

export default PledgeLockSwitch;
