import React, { useEffect, useState } from 'react';
import Tabs from 'components/tabs';
import { PropagateLoader } from 'react-spinners';
import usePledgeTxTabs from './hooks/usePledgeTxTabs';
import './index.scss';
import { useReleasePledgeContext } from '../../context';
import useBatchPledgesForRelease from './hooks/useBatchPledgesForRelease';

const PledgePreview = () => {
  const { deal, pledges, transactions, setTransactions } = useReleasePledgeContext();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const tabs = usePledgeTxTabs(transactions);

  const { batchPledges, isLoading } = useBatchPledgesForRelease(deal.id, {
    onSuccess: setTransactions,
    onError: (error) => {
      console.error(error);
    },
  });

  useEffect(() => {
    if (!pledges.length || transactions.length > 0) return;

    batchPledges({ pledges });
  }, [pledges, batchPledges, transactions]);

  if (isLoading || tabs.length === 0)
    return (
      <div className="pledge-batching-spinner">
        <PropagateLoader color="#029E80" />
        <div>Batching pledges into transactions...</div>
      </div>
    );

  return (
    <div>
      <div className="claim-deal-check-modal__table pledge-transactions-container">
        <div className="pledge-count-container">
          <div className="pledge-count">
            Pledges: <span>{pledges.length}</span>
          </div>
        </div>
        <Tabs
          tabs={tabs}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
        />
      </div>
    </div>
  );
};

export default PledgePreview;
