import React from 'react';
import RoundedButton from 'components/button/rounded-button';
import { useIsMutating } from 'react-query';
import { usePledgeDistributeContext } from '../../../pages/PledgeDistribute/context';
import { ReleasePledgePage, useReleasePledgeContext } from '../../../context';

export default function ReleasePledgesNextButton() {
  const { activePage, setActivePage, onClose } = useReleasePledgeContext();

  const { state, isLoading: isLoadingDistribute } = usePledgeDistributeContext();

  const isMutating = useIsMutating();

  const { error } = state;

  const pageConfig = {
    [ReleasePledgePage.RELEASE]: {
      label: 'Done',
      loading: isMutating > 0,
      onClick: onClose,
    },
    [ReleasePledgePage.DISTRIBUTE]: {
      label: 'Next',
      loading: error,
      onClick: () => setActivePage(ReleasePledgePage.PREVIEW),
    },
    [ReleasePledgePage.PREVIEW]: {
      label: 'Next',
      loading: isLoadingDistribute,
      onClick: () => setActivePage(ReleasePledgePage.RELEASE),
    },
  };

  return (
    <RoundedButton
      type="secondary"
      disabled={pageConfig[activePage]?.loading}
      onClick={() => pageConfig[activePage]?.onClick()}
    >
      {pageConfig[activePage]?.label}
    </RoundedButton>
  );
}
