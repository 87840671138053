import React from 'react';
import './index.scss';

const PledgeSwitchInput = ({ id, checked, onChange, disabled }) => {
  const handleChange = (e) => {
    onChange(e.target.checked);
  };

  return (
    <div className={`toggle-switch ${disabled ? 'toggle-switch--disabled' : ''}`}>
      <input
        id={id}
        className="toggle-switch__checkbox"
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
      />
      <label className="toggle-switch__label" htmlFor={id}>
        <span className="toggle-switch__label-button" />
      </label>
    </div>
  );
};

export default PledgeSwitchInput;
