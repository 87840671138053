/* eslint-disable no-restricted-syntax */
/* eslint-disable no-use-before-define */
/* eslint-disable import/prefer-default-export */
import { queryCalls } from 'contracts/multicallContract';
import {
  groupByChain,
  groupDealsByStatus,
  groupDealsByVersion,
  isChainMulticallSupported,
  markDealForManualFetch,
} from 'models/common/helpers';
import { CALL_TYPE } from 'contracts/calls/constants';
import buildCall from 'contracts/calls';
import { getProviderByChainId } from 'contracts/providers';
import { enrichWithMetadata } from './metadata';
import { filterAccessibleDeals, parseEssentialData } from './helpers';
import { handleOldDealClaimers } from './claimers-old';
import { enrichWithRemainingAllocation } from './remainingAllocation';

export async function getDealModels(authState, apiDeals, queryClient) {
  const { accountInfo } = authState;

  const { newDeals, oldDeals } = groupDealsByVersion(apiDeals);

  let { liveDeals, otherDeals } = groupDealsByStatus(newDeals);

  liveDeals = await processLiveDeals(liveDeals, accountInfo);

  otherDeals = otherDeals.map((otherDeal) => ({
    ...otherDeal,
    status: otherDeal.status.toLowerCase(),
    id: otherDeal.id.toString(),
  }));

  const accessibleLiveDeals = filterAccessibleDeals(liveDeals, accountInfo);

  const enrichedLiveDeals = await enrichWithRemainingAllocation(
    accessibleLiveDeals,
    accountInfo,
    queryClient
  );

  const oldDealsWithClaimers = await handleOldDealClaimers(oldDeals, accountInfo);

  return [...enrichedLiveDeals, ...otherDeals, ...oldDealsWithClaimers];
}

async function processLiveDeals(liveDeals, accountInfo) {
  const dealsByChain = groupByChain(liveDeals);

  const rawDeals = await prefetchDealsPerChain(dealsByChain, accountInfo);

  return enrichWithMetadata(rawDeals, accountInfo);
}

async function prefetchDealsPerChain(dealsByChain, accountInfo) {
  const rawDeals = [];

  const { address: walletAddress } = accountInfo;

  for await (const [chainId, deals] of Object.entries(dealsByChain)) {
    if (!isChainMulticallSupported(chainId)) {
      const markedDeals = deals.map(markDealForManualFetch);

      rawDeals.push(...markedDeals);
    }

    const provider = getProviderByChainId(chainId);

    const { multicallCalls, callIndexes } = prepareCalls(deals, walletAddress);

    const multicallResults = await queryCalls(provider, multicallCalls);

    const results = processPrefetchResults({
      deals,
      multicallResults,
      callIndexes,
    });

    rawDeals.push(...results);
  }

  return rawDeals;
}

function processPrefetchResults({ deals, multicallResults, callIndexes }) {
  return deals.map((deal, index) => {
    const callIndex = callIndexes[index];

    const { dealChainData, contributedAmount, claimAmount } = parseEssentialData(
      multicallResults,
      callIndex
    );

    return {
      deal,
      claimAmount,
      dealChainData,
      contributedAmount,
    };
  });
}

function prepareCalls(deals, walletAddress) {
  const multicallCalls = [];
  const callIndexes = [];

  deals.forEach((deal) => {
    const currentCallIndexes = {
      dealInfoIndex: null,
      contributionIndex: null,
      whitelistIndex: null,
      maxContributionIndex: null,
      phaseInfoIndex: null,
    };

    createEssentialCalls({
      deal,
      walletAddress,
      currentCallIndexes,
      multicallCalls,
    });

    callIndexes.push(currentCallIndexes);
  });

  return { multicallCalls, callIndexes };
}

function createEssentialCalls({ deal, walletAddress, currentCallIndexes, multicallCalls }) {
  currentCallIndexes.dealInfoIndex = multicallCalls.length;
  multicallCalls.push(buildCall(CALL_TYPE.DEAL_INFO)(deal.address));

  currentCallIndexes.contributionIndex = multicallCalls.length;
  multicallCalls.push(
    buildCall(CALL_TYPE.ADDRESS_CONTRIBUTION_AMOUNT)(deal.address, [walletAddress])
  );

  currentCallIndexes.claimerAmountIndex = multicallCalls.length;
  multicallCalls.push(
    buildCall(CALL_TYPE.CLAIM_AMOUNT)(deal.address, deal.chainId, [walletAddress])
  );
}
