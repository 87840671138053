import { isAddress } from 'ethers/lib/utils';

function hasValidPersonalCaps(phase) {
  if (!phase.whitelist || phase.whitelist.length === 0) {
    return false;
  }

  return phase.whitelist.every(
    (item) => +item.personalCap > 0 && !!item.address && isAddress(item.address)
  );
}
function checkIsPhaseValid(phase) {
  if (!phase) {
    return { isValid: true, errorMessage: '' };
  }

  const validations = [
    {
      sectionError: 'phase-settings',
      condition: phase.startDate < phase.endDate,
      errorMessage: 'Please select valid date range',
    },
    {
      sectionError: 'phase-settings',
      condition: !!phase.phaseName,
      errorMessage: 'Please add a valid phase name',
    },
    {
      sectionError: 'access-management',
      condition: !phase.accessLevels.includes(4) || phase.whitelist?.length > 0,
      errorMessage: 'Please add a valid whitelisted users',
    },
    {
      sectionError: 'access-management',
      condition: phase.model !== 'WalletCap' || hasValidPersonalCaps(phase),
      errorMessage: 'Please add a valid caps per wallet',
    },
    {
      sectionError: 'access-management',
      condition: phase.accessLevels.length > 0,
      errorMessage: 'Please select at least one access level',
    },
  ];

  const invalidValidation = validations.find((validation) => !validation.condition);

  if (invalidValidation) {
    return {
      isValid: false,
      ...invalidValidation,
    };
  }

  return { isValid: true, errorMessage: '' };
}

export { checkIsPhaseValid };
