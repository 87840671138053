import useCreateRole from 'contracts/poolManager/hooks/roles/useCreateRole';
import useUpdateRole from 'contracts/poolManager/hooks/roles/useUpdateRole';

import { useNotifications } from 'hooks';

import { useQueryClient } from 'react-query';
import useApiCreateRole from './api/useApiCreateRole';
import useApiUpdateRole from './api/useApiUpdateRole';

const useRoleUpsert = (role, { onSuccess, onError }) => {
  const [showSuccessNotification] = useNotifications();

  const queryClient = useQueryClient();

  const { createRole: apiCreateRole, isLoading: isApiRoleCreating } = useApiCreateRole(role, {
    onSuccess: () => {
      queryClient.invalidateQueries(['roles']);

      showSuccessNotification('Role created successfully');
      onSuccess();
    },
    onError,
  });

  const { updateRole: apiUpdateRole, isLoading: isApiRoleUpdating } = useApiUpdateRole(role, {
    onSuccess: () => {
      queryClient.invalidateQueries(['roles']);

      showSuccessNotification('Role updated successfully');
      onSuccess();
    },
    onError,
  });

  const { executeMethod: contractCreateRole, isLoading: isContractRoleCreating } = useCreateRole(
    role,
    {
      onSuccess: apiCreateRole,
      onError,
    }
  );

  const { executeMethod: contractUpdateRole, isLoading: isContractRoleUpdating } = useUpdateRole(
    role,
    {
      onSuccess: apiUpdateRole,
      onError,
    }
  );

  const updateRole = () => {
    const isMaxCloseAmountChanged = role.initMaxCloseAmount !== role.maxCloseAmount;

    if (isMaxCloseAmountChanged) {
      return contractUpdateRole();
    }

    return apiUpdateRole();
  };

  const upsertRole = async () => {
    if (role.id) {
      return updateRole();
    }

    return contractCreateRole();
  };

  const isContractRoleLoading = isContractRoleCreating || isContractRoleUpdating;
  const isApiRoleLoading = isApiRoleCreating || isApiRoleUpdating;

  return {
    upsertRole,
    isLoading: isContractRoleLoading || isApiRoleLoading,
  };
};

export default useRoleUpsert;
