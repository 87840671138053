import { useMutation } from 'react-query';
import { distributePledges } from 'services/apiService';

export default function usePledgeDistribute(options = {}) {
  const { mutateAsync: distribute, isLoading } = useMutation(distributePledges, {
    ...options,
  });

  return {
    distribute,
    isLoading,
  };
}
