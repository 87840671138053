import React, { useEffect, useState } from 'react';
import remote from 'features/deals/AdminDealsTable/remote';

import '../../../../index.scss';
import { useDeployContractContext } from 'features/deals/DealInfo/DeployContractTab/context';
import WhitelistPickerModal from 'features/whitelists/WhitelistPickerModal';
import PhaseActions from 'features/deals/DealInfo/DeployContractTab/phases/PhaseActions';
import AccessManagementAccordion from './accordions/AccessManagement';
import PhaseSettingsAccordion from './accordions/PhaseSettings';

export default function ActivePhase({ phase, index, isNew }) {
  const { deal, whitelistPickerData, setWhitelistPickerData, handlePhaseChange } =
    useDeployContractContext();

  const [whitelistPledges, setWhitelistPledges] = useState();

  const { data: pledges } = remote.useGetPledge(deal.id, true);

  useEffect(() => {
    if (!pledges || !pledges.length || whitelistPledges) return;

    const pledgeAddresses = pledges.map((pledge) => ({
      address: pledge.wallet,
      personalCap: !pledge.acceptedAmount ? +pledge.amount : +pledge.acceptedAmount,
    }));

    if (pledgeAddresses.length === 0) return;

    setWhitelistPledges({
      name: 'Pledged Wallets',
      id: 'pledges',
      addresses: pledgeAddresses,
      checked: false,
    });
  }, [pledges, whitelistPledges]);

  const checkIsActive = () => {
    const now = new Date();

    return now >= phase.startDate && now <= phase.endDate;
  };

  const phaseStatus = isNew ? 'draft' : checkIsActive() ? 'active' : 'upcoming';

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <div className="space-y-6">
        <div className="phase-detail">
          <div className="phase-detail-header">
            <span>
              {phase.phaseName}
              <div className={`badge badge__${phaseStatus}`}>{phaseStatus}</div>
            </span>
            <PhaseActions index={index} phase={phase} isNew={isNew} />
          </div>
          <PhaseSettingsAccordion phase={phase} index={index} />
          <AccessManagementAccordion phase={phase} index={index} />
          <WhitelistPickerModal
            open={!!whitelistPickerData}
            whitelistPledges={whitelistPledges}
            onClose={() => setWhitelistPickerData(null)}
            onSave={(newAddresses) =>
              handlePhaseChange(
                'whitelist',
                [
                  ...(whitelistPickerData.whitelist || []),
                  ...newAddresses.map(({ address, personalCap }) => ({
                    address,
                    personalCap: personalCap || 0,
                  })),
                ],
                whitelistPickerData.i
              )
            }
          />
        </div>
      </div>
    </div>
  );
}
