import React from 'react';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { formatFiatPrice } from 'utils/helpers';
import RoundedButton from 'components/button/rounded-button';
import IconButton from 'components/button/icon-button';
import FileSaver from 'file-saver';
import { getHANFAReport } from 'services/apiService';
import { useNotifications } from 'hooks';
import { useAllHanfaReportQuery } from './hooks/useAllHanfaReportQuery';
import { useHanfaManualGeneration } from './hooks/useHanfaManualGeneration';

const ReportTable = () => {
  const [, showErrorNotification] = useNotifications();

  const { reports, isLoading } = useAllHanfaReportQuery();

  const { generateHanfaReport } = useHanfaManualGeneration();

  const handleFileDownload = async ({ month, year }, open) => {
    try {
      const arrayBuffer = await getHANFAReport(month, year);
      const excelFile = new Blob([arrayBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      FileSaver.saveAs(excelFile, `BlackDragon report ${year}-${month}.xlsx`);

      const excelFileURL = URL.createObjectURL(excelFile);
      if (open) window.open(excelFileURL);
    } catch {
      showErrorNotification('Something went wrong');
    }
  };

  if (!reports?.length || isLoading) return null;

  return (
    <div className="invoices__body">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">MM/YYYY</TableCell>
            <TableCell>CONTRIBUTION TOTAL (EUR)</TableCell>
            <TableCell>CONTRIBUTIONS TXs</TableCell>
            <TableCell>CONTRIBUTORS</TableCell>
            <TableCell>CROATIAN WALLETS</TableCell>
            <TableCell>EUROPEAN WALLETS</TableCell>
            <TableCell>OTHER COUNTRIES WALLETS</TableCell>
            <TableCell>PEP WALLETS</TableCell>
            <TableCell>STATUS</TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {reports.map((report) => (
            <TableRow key={`${report.id}`}>
              <TableCell align="center">
                {report.month}/{report.year}.
              </TableCell>
              <TableCell>
                {report.contributionTotalEur
                  ? `${formatFiatPrice(report.contributionTotal, false)} EUR`
                  : '0.00'}
              </TableCell>
              <TableCell>{report.contributionCount}</TableCell>
              <TableCell>{report.uniqueWalletCount}</TableCell>
              <TableCell>{report.localWalletCount}</TableCell>
              <TableCell>{report.europeWalletCount}</TableCell>
              <TableCell>{report.otherCountriesWalletCount}</TableCell>
              <TableCell>{report.pepWalletCount}</TableCell>
              <TableCell>
                <Box display="flex" alignItems="center">
                  {report.isCreated ? (
                    <>
                      <div className="green-dot" />
                      <span>Created</span>
                    </>
                  ) : (
                    <>
                      <div className="red-dot" />
                      <span>Not created</span>
                    </>
                  )}
                </Box>
              </TableCell>
              <TableCell align="right">
                <RoundedButton
                  disabled={!report.isCreated}
                  type="primary"
                  onClick={() =>
                    handleFileDownload({ month: report.month, year: report.year }, true)
                  }
                >
                  View
                </RoundedButton>
                <div className="icon-download">
                  <IconButton
                    disabled={!report.isCreated}
                    icon="iconDownload"
                    onClick={() => handleFileDownload({ month: report.month, year: report.year })}
                  />
                </div>
                {!report.isCreated && (
                  <div className="icon-download">
                    <IconButton
                      icon="iconBookkeepingReport"
                      onClick={() =>
                        generateHanfaReport({ month: report.month, year: report.year })
                      }
                    />
                  </div>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default ReportTable;
