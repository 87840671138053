import React from 'react';
import './index.scss';
import DatePicker from 'react-datepicker';

function CustomDateInput({ label, value, onChange, error }) {
  return (
    <div
      className={`custom-input-container ${error?.length > 0 ? ' custom-date-input-error' : ''}`}
    >
      {label.length > 0 && <label>{label}</label>}
      <DatePicker
        minDate={new Date()}
        id="custom-date-input"
        showTimeSelect
        selected={value}
        onChange={onChange}
        dateFormat="MM/dd/yy HH:mm"
      />
      {error && error.length > 0 && <span className="error-message">{error}</span>}
    </div>
  );
}

export default CustomDateInput;
