import React from 'react';
import RoundedButton from 'components/button/rounded-button';
import { useIsMutating } from 'react-query';
import { usePledgeDistributeContext } from '../../pages/PledgeDistribute/context';
import { useReleasePledgeContext } from '../../context';
import ReleasePledgesNextButton from './components/NextButton';

function ReleasePledgesFooter() {
  const { onClose } = useReleasePledgeContext();

  const isMutating = useIsMutating();

  const { isLoading, initState } = usePledgeDistributeContext();

  return (
    <div className="claim-deal-check-modal__footer">
      <RoundedButton
        type="primary"
        disabled={isLoading || isMutating}
        onClick={() => {
          initState();
          onClose();
        }}
      >
        Cancel
      </RoundedButton>
      <ReleasePledgesNextButton />
    </div>
  );
}

export default ReleasePledgesFooter;
