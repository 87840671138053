import Icon from 'components/svgIcon';
import React from 'react';
import { formatDate, shortenAddress } from 'utils';

export default function usePledgeTxTabs(transactions) {
  if (!transactions.length) {
    return [];
  }

  const buildLabel = (tx, index) => {
    if (index === 0) {
      return 'All Transactions';
    }

    const showIcon = transactions.some((t) => t.txHash);

    const isSuccess = !!tx.txHash;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <span>Transaction {index}</span>
        {showIcon && (isSuccess ? <Icon name="success" /> : <Icon name="error" />)}
      </div>
    );
  };

  return transactions.map((transaction, i) => ({
    id: i,
    label: buildLabel(transaction, i),
    content: (
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 10 }}>
        <div className="claim-deal-check-modal__table-head">
          <span>Date</span>
          <span>Wallet</span>
          <span>Pledge Amount</span>
          <span>Refund Amount</span>
          <span>Transaction</span>
        </div>
        <div className="claim-deal-check-modal__table-body">
          {transaction.pledges.map((pledge) => (
            <div key={pledge.wallet}>
              <span>{formatDate(pledge.updatedAt)}</span>
              <span>{shortenAddress(pledge.user.wallet)}</span>
              <span>{pledge.amount}</span>
              <span>{parseFloat(pledge.acceptedAmount).toFixed(1)}</span>
              <span>T{pledge.batchId || i}</span>
            </div>
          ))}
        </div>
      </div>
    ),
  }));
}
