const DealValidState = {
  OldPledgeClosed: 'OldPledgeClosed',
  PledgeFreezed: 'PledgeFreezed',
  CorrectState: 'CorrectState',
  FeeExists: 'FeeExists',
};

const InvalidStateMessages = {
  [DealValidState.PledgeFreezed]: 'Deal can be deployed only when pool is freezed',
  [DealValidState.FeeExists]: 'Please set fee before deploying',
  [DealValidState.CorrectState]:
    'Phases can only be deployed when pool is active, paused or not active',
  [DealValidState.OldPledgeClosed]: 'Deal can be deployed only when pledge is closed',
};

export { DealValidState, InvalidStateMessages };
