import { useQuery } from 'react-query';
import { ethers } from 'ethers';
import { useSelector } from 'react-redux';
import useERC20Contract from './useERC20Contract';

const useERC20Balance = (tokenAddress) => {
  const erc20Contract = useERC20Contract(tokenAddress);

  const { accountInfo } = useSelector((state) => state.auth);

  const { data: balance, isLoading } = useQuery(
    ['balance', accountInfo.address, tokenAddress],
    async () => {
      const address = await erc20Contract.signer.getAddress();

      const result = await erc20Contract.balanceOf(address);

      return ethers.utils.formatUnits(result, 'mwei');
    },
    {
      enabled: !!tokenAddress,
    }
  );

  return { balance, isLoading };
};

export default useERC20Balance;
