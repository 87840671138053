import { useQuery } from 'react-query';
import { getPledgeTxMetadata } from 'services/apiService';

export default function useTxMetadataQuery(dealId, wallet, { enabled } = {}) {
  const { data: transactions, isFetching } = useQuery(
    ['tx-metadata', dealId, wallet],
    () => getPledgeTxMetadata(dealId, wallet),
    {
      enabled,
    }
  );

  return {
    transactions,
    isFetching,
  };
}
