import React from 'react';
import { Divider } from '@material-ui/core';
import { DealVersion } from 'models/constants';
import DeployContractHeader from './components/DeployContractHeader';
import './index.scss';
import DeployBlurContainer from './components/DeployBlurContainer';
import DealPhaseEditor from './components/PhaseEditor';

const DeployContractTab = ({ deal }) => {
  if (deal.version !== DealVersion.V3)
    return (
      <p style={{ width: '100%', textAlign: 'center', margin: '20px 0' }}>
        Old deal phases are no longer supported
      </p>
    );

  return (
    <DeployBlurContainer>
      <div className="deploy-contract">
        <DeployContractHeader />
        <Divider />
        <DealPhaseEditor />
      </div>
    </DeployBlurContainer>
  );
};

export default DeployContractTab;
