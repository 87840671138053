import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { ethers } from 'ethers';
import usePledgePoolQuery from 'contracts/pledgeVault/hooks/usePledgePoolQuery';
import { StateField } from '../constants';
import { buildInitialState, calculateNewReleaseAmounts, sumPledges } from '../helpers';
import { useReleasePledgeContext } from '../../../context';
import usePledgeDistribute from '../hooks/usePledgeDistribute';

const PledgeDistributeContext = createContext();

export const usePledgeDistributeContext = () => useContext(PledgeDistributeContext);

export const PledgeDistributeProvider = ({ initialPledges, onRefetch, children }) => {
  const { deal, pledges, setPledges } = useReleasePledgeContext();

  const [state, setState] = useState({});

  const { totalRelease, isManualInput, isDistributeEvenly } = state;

  const { pool } = usePledgePoolQuery(deal.address);

  const originalPledgedAmount = ethers.utils.formatUnits(pool?.totalPledgedAmount || 0, 'mwei');

  const updateTotalReleaseState = ({ amount = 0, isDirty, isDistributeEvenly, skipDistribute }) => {
    const safeAmount = amount || 0;

    const refund = +originalPledgedAmount - +safeAmount;

    setState((prevState) => ({
      ...prevState,
      totalRelease: {
        isDirty,
        amount: safeAmount,
        amountFormatted: parseFloat(safeAmount).toFixed(1),
        refund: parseFloat(refund).toFixed(1),
        distribute: skipDistribute ? 0 : amount,
      },
      isDistributeEvenly,
    }));
  };

  const { distribute } = usePledgeDistribute({
    onSuccess: ({ updatedPledges }) => {
      setPledges(updatedPledges);

      updateTotalReleaseState({ amount: totalRelease.distribute });
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const updateState = (field, value) => {
    setState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const invariantNewTotalRelease = (newTotalRelease, onValid) => {
    if (+newTotalRelease > +originalPledgedAmount) {
      return updateState(
        StateField.Error,
        'Release amount cannot be greater than total pledged amount'
      );
    }

    updateState(StateField.Error, '');
    onValid();
  };

  const onApplyAll = () => {
    updateState(StateField.IsManualInput, false);
    updateTotalReleaseState({
      amount: sumPledges(pledges).toFixed(1),
      isDirty: false,
    });
  };

  const onApplySinglePledge = (index, e) => {
    const { value } = e.target;

    const skipDistribute = totalRelease.distribute === 0;

    const { newTotalReleaseAmount, acceptedAmount } = calculateNewReleaseAmounts({
      pledges,
      totalRelease,
      value,
      index,
      skipDistribute,
    });

    invariantNewTotalRelease(newTotalReleaseAmount, () => {
      pledges[index].acceptedAmount = acceptedAmount;

      setPledges(pledges);

      if (skipDistribute) {
        updateTotalReleaseState({
          amount: newTotalReleaseAmount,
          skipDistribute,
        });

        return;
      }

      updateTotalReleaseState({
        amount: newTotalReleaseAmount,
        isDistributeEvenly: false,
      });
    });
  };

  const onUpdateTotalRelease = (e) => {
    const { value } = e.target;
    const newTotalRelease = value.replace(/,/g, '.');

    invariantNewTotalRelease(newTotalRelease, () => {
      updateTotalReleaseState({
        amount: newTotalRelease,
        isDirty: true,
        isDistributeEvenly: false,
      });
    });
  };

  const onToggleManualInput = () => {
    const nextIsManualInput = !isManualInput;

    if (nextIsManualInput) {
      const acceptedAmount = sumPledges(pledges);

      updateTotalReleaseState({ amount: acceptedAmount.toFixed(1), isDirty: true });
    }

    updateState(StateField.IsManualInput, nextIsManualInput);
  };

  const onToggleDistributeEvenly = async () => {
    const nextIsDistributeEvenly = !isDistributeEvenly;

    if (!isDistributeEvenly) {
      await distribute({
        amountToDistribute: totalRelease.distribute || originalPledgedAmount,
        pledges,
      });
    }

    updateState(StateField.IsDistributeEvenly, nextIsDistributeEvenly);
  };

  const initState = useCallback(() => {
    const initialState = buildInitialState(originalPledgedAmount);
    const deepCopy = initialPledges.map((pledge) => ({
      ...pledge,
      acceptedAmount: Number(pledge.amount).toFixed(0),
    }));

    setPledges(deepCopy);

    setState(initialState);
  }, [initialPledges, originalPledgedAmount, setPledges]);

  useEffect(() => {
    initState();
  }, [initState]);

  return (
    <PledgeDistributeContext.Provider
      value={{
        state,
        onApplyAll,
        onApplySinglePledge,
        onUpdateTotalRelease,
        onToggleManualInput,
        onToggleDistributeEvenly,
        originalPledgedAmount,
        initState,
        onRefetch,
        updateState,
      }}
    >
      {children}
    </PledgeDistributeContext.Provider>
  );
};
