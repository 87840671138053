import axios from 'axios';
import { QueryClient } from 'react-query';

export const baseUrl = process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create();

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('token');
    }
    throw error;
  }
);

export const apiGet = (url, config = {}) => {
  return axiosInstance.get(url, {
    crossDomain: true,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    ...config,
  });
};

const apiGetPdf = (url, config = {}) => {
  return axiosInstance.get(url, {
    crossDomain: true,
    responseType: 'arraybuffer',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
    ...config,
  });
};

export const apiPost = (url, data, headers = {}, config = {}) => {
  return axiosInstance.post(url, data, {
    crossDomain: true,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...headers,
    },
    ...config,
  });
};

export const apiDelete = (url, headers = {}, config = {}) => {
  return axiosInstance.delete(url, {
    crossDomain: true,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...headers,
    },
    ...config,
  });
};

export const apiPut = (url, data, headers = {}, config = {}) => {
  return axiosInstance.put(url, data, {
    crossDomain: true,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...headers,
    },
    ...config,
  });
};

const authPut = async (url, data) => {
  return axiosInstance.put(url, data, {
    crossDomain: true,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
};

export const getAllDeals = async () => {
  const response = await apiGet(`${baseUrl}/api/deal`);

  return response.data;
};

export const getDealInfo = async (id) => {
  const response = await apiGet(`${baseUrl}/api/deal/details/${id}`);

  return response.data;
};

export const getAllAdminDeals = async () => {
  const response = await apiGet(`${baseUrl}/api/deal/admin/admin-deal`);

  return response.data;
};

export const getAdminDealInfo = async (id) => {
  const response = await apiGet(`${baseUrl}/api/deal/admin/admin-deal/details/${id}`);

  return response.data;
};

export const getAdminDealData = async (id) => {
  const response = await apiGet(`${baseUrl}/api/deal/admin/admin-deal-data/${id}`);

  return response.data;
};

export const createDeal = (data) => {
  return apiPost(`${baseUrl}/api/deal/admin`, data);
};

export const updateDeal = async (dealId, data) => {
  return authPut(`${baseUrl}/api/deal/admin/${dealId}`, data);
};

export const updateDealPledgeVersion = async (dealId, data) => {
  return authPut(`${baseUrl}/api/deal/admin/${dealId}/use-old-pledge`, data);
};

export const deleteDeal = async (dealId) => {
  return apiDelete(`${baseUrl}/api/deal/admin/${dealId}`);
};

export const updateSendDealCancelationInvoices = async (dealId, sendCancellationInvoices) => {
  const response = await apiPut(`${baseUrl}/api/deal/${dealId}/cancelation-invoices`, {
    sendCancellationInvoices,
  });
  return response.data;
};

export const getPledge = async (dealId) => {
  const response = await apiGet(`${baseUrl}/api/deal/${dealId}/pledge`);
  return response.data;
};

export const postPledge = async (dealId, amount) => {
  return apiPost(`${baseUrl}/api/deal/${dealId}/pledge`, { amount });
};

export const openPledge = async (dealId) => {
  return apiPut(`${baseUrl}/api/deal/admin/${dealId}/pledge`);
};

export const postClaimer = async (chain, address, dealId, creationBlock, merkleTreeId) => {
  const response = await apiPost(`${baseUrl}/api/deal/${dealId}/claimer`, {
    chain,
    address,
    creationBlock,
    merkleTreeId,
  });
  return response.data;
};

export const setClaimerTokens = async (claimerId, chainId, tokens) => {
  const response = await apiPost(`${baseUrl}/api/deal/admin/claimer/${claimerId}/token`, {
    chainId,
    tokens,
  });

  return response.data;
};

export const getAllOldDeals = async () => {
  try {
    const response = await apiGet(`${baseUrl}/api/old-deal`);

    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const getUser = async () => {
  const response = await apiGet(`${baseUrl}/api/user`);
  return response.data;
};

export const updateUser = async (data) => {
  return apiPost(`${baseUrl}/api/user`, data);
};

export const getKycUid = async () => {
  const response = await apiGet(`${baseUrl}/api/user/kyc/uid`);
  return response.data;
};

export const getUserAnalytics = async () => {
  const response = await apiGet(`${baseUrl}/api/admin/user-analytics`);
  return response.data;
};

export const getOldMerkleProof = async (root) => {
  const response = await apiGet(`${baseUrl}/api/old-deal/merkle-proof/${root}`);
  return response.data;
};

export const getMerkleProof = async (claimerId) => {
  const response = await apiGet(`${baseUrl}/api/deal/merkle-proof/${claimerId}`);
  return response.data;
};

export const uploadImage = async (data) => {
  const response = await apiPost(`${baseUrl}/api/upload/image`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
};

export const getServerImage = async (path) => {
  const response = await apiGet(`${baseUrl}/${path}`, { responseType: 'arraybuffer' });

  return `data:${response.headers['content-type']};base64,${Buffer.from(
    response.data,
    'binary'
  ).toString('base64')}`;
};

export const getWhitelists = async () => {
  const response = await apiGet(`${baseUrl}/api/whitelist`);
  return response.data;
};

export const updateWhitelist = async (data) => {
  const response = await apiPut(`${baseUrl}/api/whitelist`, data);
  return response.data;
};

export const addWhitelist = async (data) => {
  const response = await apiPost(`${baseUrl}/api/whitelist`, data);
  return response.data;
};

export const updateAdminUserData = async (data) => {
  const response = await apiPut(`${baseUrl}/api/whitelist/${data.address}`, data);
  return response.data;
};

export const deleteWhitelist = async (id) => {
  const response = await apiDelete(`${baseUrl}/api/whitelist/${id}`);
  return response.data;
};

export const updateRequiredChain = async (data) => {
  const response = await apiPost(`${baseUrl}/api/deal/admin/chain`, data);
  return response.data;
};

export const getFullDealAnalytics = async (dealId, password) => {
  const response = await apiPost(`${baseUrl}/api/deal/admin/${dealId}/full-analytics`, {
    password,
  });
  return response.data;
};

export const getBasicDealAnalytics = async (dealId) => {
  const response = await apiGet(`${baseUrl}/api/deal/admin/${dealId}/basic-analytics`);
  return response.data;
};

export const login = async (signedMessage) => {
  const response = await apiPost(`${baseUrl}/api/auth/token`, {
    message: signedMessage,
  });
  return response.data;
};

export const getUserToken = async (wallet) => {
  const response = await apiPost(`${baseUrl}/api/auth/admin/user-token`, {
    wallet,
  });
  return response.data;
};

export const getSynapsSessionId = async () => {
  const response = await apiPost(`${baseUrl}/api/kyc/init-session`);

  return response.data;
};

export const getKYCStatus = async () => {
  const response = await apiGet(`${baseUrl}/api/kyc/status`);

  return response.data;
};

export const syncKyc = async () => {
  const response = await apiPost(`${baseUrl}/api/admin/kyc/sync-data`);

  return response.data;
};

export const archiveDeal = async (dealId) => {
  return apiPost(`${baseUrl}/api/deal/${dealId}/archive`);
};

export const unarchiveDeal = async (dealId) => {
  return apiPost(`${baseUrl}/api/deal/${dealId}/unarchive`);
};

export const archiveOldDeal = async (dealId) => {
  return apiPost(`${baseUrl}/api/old-deal/${dealId}/archive`);
};

export const unarchiveOldDeal = async (dealId) => {
  return apiPost(`${baseUrl}/api/old-deal/${dealId}/unarchive`);
};

export const sendVerificationEmail = async (email, verificationPageUrl) => {
  return apiPost(`${baseUrl}/api/user/send-email-verification`, {
    email,
    baseUrl: verificationPageUrl,
  });
};

export const verifyEmail = async (token) => {
  return apiPost(`${baseUrl}/api/user/verify-email`, {
    token,
  });
};

export const insertPhaseMerkleRoots = async (data, dealId) => {
  const res = await apiPost(`${baseUrl}/api/deal/admin/${dealId}/phase`, data);
  return res.data;
};

export const getActivePhases = async (dealId) => {
  const response = await apiGet(`${baseUrl}/api/deal/${dealId}/active-phases`);

  return response.data;
};

export const updateNotificationSettings = (settings) => {
  return apiPut(`${baseUrl}/api/user/update-notification-settings`, settings);
};

export const updateCloudMessagingToken = (token) => {
  return apiPut(`${baseUrl}/api/user/update-cloud-messaging-token`, { token });
};

export const updateOtherWallet = (newWallet, signedMessage) => {
  return apiPost(`${baseUrl}/api/user/add-other-wallet`, { wallet: newWallet, signedMessage });
};

export const getChangeLogs = async (dealId) => {
  const response = await apiGet(`${baseUrl}/api/deal/change-logs/${dealId}`);
  return response.data;
};

export const deleteChangeLog = async (id) => {
  return apiDelete(`${baseUrl}/api/deal/admin/change-logs/${id}`);
};

export const updateChangeLog = async (changeLog) => {
  const response = await apiPut(`${baseUrl}/api/deal/admin/change-logs/${changeLog.id}`, changeLog);
  return response.data;
};

export const addRole = async (role) => {
  const response = await apiPost(`${baseUrl}/api/role`, role);
  return response.data;
};

export const updateRole = async (role) => {
  const response = await apiPut(`${baseUrl}/api/role/${role.id}`, role);
  return response.data;
};

export const deleteRoleFromDb = async (roleId) => {
  const response = await apiDelete(`${baseUrl}/api/role/${roleId}`);
  return response.data;
};

export const getAllRoles = async () => {
  const response = await apiGet(`${baseUrl}/api/role`);
  return response.data;
};

export const getAllFeatures = async () => {
  const response = await apiGet(`${baseUrl}/api/role/all-features`);
  return response.data;
};

export const addChangeLog = async (changeLog, dealId) => {
  const response = await apiPost(`${baseUrl}/api/deal/admin/change-logs`, {
    changeLog,
    dealId,
  });
  return response.data;
};

export const getAllAdminPolls = async () => {
  const response = await apiGet(`${baseUrl}/api/poll/admin`);
  return response.data;
};

export const getAllUserPolls = async () => {
  const response = await apiGet(`${baseUrl}/api/poll`);
  return response.data;
};

export const createPoll = async (poll) => {
  const response = await apiPost(`${baseUrl}/api/poll/admin`, poll);
  return response.data;
};

export const startPoll = async (id) => {
  const response = await apiPut(`${baseUrl}/api/poll/admin/${id}/start`);
  return response.data;
};

export const endPoll = async (id) => {
  const response = await apiPut(`${baseUrl}/api/poll/admin/${id}/end`);
  return response.data;
};

export const deletePoll = async (id) => {
  const response = await apiDelete(`${baseUrl}/api/poll/admin/${id}`);
  return response.data;
};

export const votePoll = async (optionId) => {
  const response = await apiPost(`${baseUrl}/api/poll/vote`, { optionId });
  return response.data;
};

export const updatePoll = async (poll) => {
  const response = await apiPut(`${baseUrl}/api/poll/admin/${poll.id}`, poll);
  return response.data;
};

export const getPollResults = async (pollId) => {
  const response = await apiGet(`${baseUrl}/api/poll/${pollId}/results`);
  return response.data;
};

export const getUsersWithRole = async () => {
  const response = await apiGet(`${baseUrl}/api/role/users`);
  return response.data;
};

export const removeRoleFromUser = async (userWallet) => {
  await apiDelete(`${baseUrl}/api/role/users/${userWallet}`);
};

export const addRoleToUser = async (assignData) => {
  const response = await apiPost(`${baseUrl}/api/role/users/${assignData.address}`, {
    roleId: assignData.role.id,
    username: assignData.username,
    email: assignData.email,
  });
  return response.data;
};

export const editUserWithRole = async (username, email, wallet, roleId) => {
  const response = await apiPut(`${baseUrl}/api/role/users/${wallet}`, { roleId, username, email });
  return response.data;
};

export const getPledgeAnalytics = async (dealId) => {
  const response = await apiGet(`${baseUrl}/api/deal/admin/${dealId}/pledge-analytics`);
  return response.data;
};

export const getPledgeTxMetadata = async (dealId, wallet) => {
  const response = await apiGet(
    `${baseUrl}/api/deal/admin/${dealId}/pledge-tx-metadata?wallet=${wallet}`
  );
  return response.data;
};

export const getAdminDealReleasePledges = async (dealId) => {
  const response = await apiGet(`${baseUrl}/api/deal/admin/${dealId}/pledge-batches`);
  return response.data;
};

export const distributePledges = async (data) => {
  const response = await apiPost(`${baseUrl}/api/deal/admin/distribute-pledges`, data);

  return response.data;
};

export const batchPledgesForRelease = async (dealId, data) => {
  const response = await apiPost(`${baseUrl}/api/deal/admin/${dealId}/pledge-batches`, data);
  return response.data;
};

export const getAllInvoices = async () => {
  const response = await apiGet(`${baseUrl}/api/invoice`);
  return response.data;
};

export const getInvoicesByDealId = async (dealId) => {
  const response = await apiGet(`${baseUrl}/api/invoice/admin/${dealId}`);
  return response.data;
};

export const getInvoiceData = async (invoiceId) => {
  const response = await apiGet(`${baseUrl}/api/invoice/data/${invoiceId}`);
  return response.data;
};

export const getInvoicePdf = async (invoiceId, isCanceled) => {
  const response = await apiGetPdf(`${baseUrl}/api/invoice/${invoiceId}`, {
    params: { isCanceled },
  });
  return response.data;
};

export const getInvoicePdfForAdmin = async (invoiceId, isCanceled) => {
  const response = await apiGetPdf(`${baseUrl}/api/invoice/admin/user-invoice/${invoiceId}`, {
    params: { isCanceled },
  });
  return response.data;
};

export const getInvoiceCsvData = async (startDate, endDate) => {
  const response = await apiGet(`${baseUrl}/api/invoice/csv-export`, {
    params: { startDate, endDate },
  });
  return response.data;
};

export const getAdminInvoiceCsvData = async (
  startDate,
  endDate,
  dealAddresses = [],
  userAddresses = []
) => {
  const response = await apiGet(`${baseUrl}/api/invoice/admin/admin-csv-export`, {
    params: {
      startDate,
      endDate,
      dealAddresses: JSON.stringify(dealAddresses),
      userAddresses: JSON.stringify(userAddresses),
    },
  });
  return response.data;
};

export const getBookkeepingReports = async () => {
  const response = await apiGet(`${baseUrl}/api/bookkeeping-report`);
  return response.data;
};

export const getBookkeepingReport = async (month, year) => {
  const response = await apiGetPdf(`${baseUrl}/api/bookkeeping-report/${month}/${year}`);
  return response.data;
};

export const getHANFAReports = async () => {
  const response = await apiGet(`${baseUrl}/api/admin/report/hanfa-report`);
  return response.data;
};

export const getHANFAReport = async (month, year) => {
  const response = await apiGetPdf(`${baseUrl}/api/admin/report/hanfa-report/${month}/${year}`);
  return response.data;
};

export const manuallyGenerateHanfaReport = async ({ month, year }) => {
  const response = await apiPost(
    `${baseUrl}/api/admin/report/hanfa-report/manual-generation/${month}/${year}`
  );
  return response.data;
};

export const manuallyGenerateReport = async (month, year) => {
  const response = await apiPost(
    `${baseUrl}/api/bookkeeping-report/manual-generation/${month}/${year}`
  );
  return response.data;
};

export const manuallyGenerateUngeneratedInvoices = async (dealId) => {
  const response = await apiPost(`${baseUrl}/api/invoice/admin/manual-generation/${dealId}`);
  return response.data;
};

export const getUserDealAnalytic = async (id) => {
  const response = await apiGet(`${baseUrl}/api/analytics/deal/${id}`);
  return response.data;
};

export const getUserOldDealAnalytic = async (id) => {
  const response = await apiGet(`${baseUrl}/api/analytics/olddeal/${id}`);
  return response.data;
};

export const getUserDealDashboardAnalytic = async (id) => {
  const response = await apiGet(`${baseUrl}/api/dashboard/deal/${id}`);
  return response.data;
};

export const getUserOldDealDashboardAnalytic = async (id) => {
  const response = await apiGet(`${baseUrl}/api/dashboard/olddeal/${id}`);
  return response.data;
};

export const getDealSellTransactions = async ({ queryKey }) => {
  const response = await apiGet(`${baseUrl}/api/analytics/sell-transaction`, {
    params: { dealId: queryKey[2], isOldDeal: !!queryKey[3] },
  });
  return response.data;
};

export const addDealSellTransaction = async (transaction) => {
  const response = await apiPost(`${baseUrl}/api/analytics/sell-transaction`, transaction);
  return response.data;
};

export const updateDealSellTransaction = async (transaction) => {
  const response = await apiPut(
    `${baseUrl}/api/analytics/sell-transaction/${transaction.id}`,
    transaction
  );
  return response.data;
};

export const deleteDealSellTransaction = async (id) => {
  const response = await apiDelete(`${baseUrl}/api/analytics/sell-transaction/${id}`);
  return response.data;
};

export const upsertVesting = async (dealId, items) => {
  const response = await apiPost(`${baseUrl}/api/deal/vesting-calendar/${dealId}`, items);
  return response.data;
};

export const updateIsDelivered = async (vestingItemId, isDelivered) => {
  const response = await apiPut(`${baseUrl}/api/distribution/schedule/deal/${vestingItemId}`, {
    isDelivered,
  });
  return response.data;
};
