import React from 'react';
import { Divider } from '@material-ui/core';
import PledgeDistribute from './PledgeDistribute';
import PledgePreview from './PledgePreview';
import PledgeRelease from './PledgeRelease';
import PopupFooter from '../components/PopupFooter';
import { ReleasePledgePage, useReleasePledgeContext } from '../context';
import PopupHeader from '../components/PopupHeader';

const ReleasePledgesPopupPages = () => {
  const { activePage } = useReleasePledgeContext();

  return (
    <div className="claim-deal-check-modal">
      <PopupHeader />
      <Divider style={{ margin: '24px 0' }} />
      {activePage === ReleasePledgePage.DISTRIBUTE && <PledgeDistribute />}
      {activePage === ReleasePledgePage.PREVIEW && <PledgePreview />}
      {activePage === ReleasePledgePage.RELEASE && <PledgeRelease />}
      <PopupFooter />
    </div>
  );
};

export default ReleasePledgesPopupPages;
