import React, { useCallback } from 'react';
import IconButton from 'components/button/icon-button';
import PledgeStatus from 'contracts/pledgeVault/constants';
import { useDeployContractContext } from '../context';
import UpdatePhaseAction from '../actions/UpdatePhase';
import CreatePhasesAction from '../actions/CreatePhases';

const PhaseActions = ({ index, phase, isNew }) => {
  const { pledgePool, setPhases, setErrors, setPhaseDisplayState } = useDeployContractContext();

  const renderActions = useCallback(() => {
    if (isNew) {
      return <CreatePhasesAction phase={phase} />;
    }

    return <UpdatePhaseAction phase={phase} />;
  }, [isNew, phase]);

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
      {pledgePool?.status === PledgeStatus.Finalized ? renderActions() : null}
      <IconButton
        disabled={!isNew}
        icon="iconErase"
        onClick={() => {
          setPhaseDisplayState(null);
          setPhases((prev) => prev.filter((_, i) => i !== index));
          setErrors((prev) => {
            return Object.fromEntries(
              Object.entries(prev).filter(([key]) => !key.startsWith(`${index}`))
            );
          });
        }}
      />
    </div>
  );
};

export default PhaseActions;
