import SvgIcon from 'components/svgIcon';
import RoundedButton from 'components/button/rounded-button';
import useBatchReleasePledge from 'contracts/pledgeVault/hooks/useBatchReleasePledge';
import React, { useEffect } from 'react';
import '../index.scss';
import { useQueryClient } from 'react-query';

const LabelByStatus = {
  pending: 'Processing...',
  success: 'Successful!',
  failed: 'Failed',
};

const IconByStatus = {
  pending: <SvgIcon name="iconBuffering" />,
  success: <SvgIcon name="success" />,
  failed: <SvgIcon name="error" />,
};

function TransactionListItem({ deal, transaction, index }) {
  const queryClient = useQueryClient();

  const { batchReleasePledge, isLoading, isError } = useBatchReleasePledge(deal.address, {
    onSuccess: () => {
      queryClient.invalidateQueries(['deal', deal.id, 'pledge']);
      queryClient.invalidateQueries(['admin-deal', deal.id]);
    },
    onError: () => {
      console.error('Error releasing pledges');
    },
  });

  const getStatus = () => {
    if (isLoading) return 'pending';

    if (isError) return 'failed';

    return 'success';
  };

  const status = getStatus();

  useEffect(() => {
    if (transaction.txHash) {
      return;
    }

    batchReleasePledge(transaction.pledges);
  }, [batchReleasePledge, transaction]);

  return (
    <div className="transaction-list-item">
      <div className="transaction-list-item__index">Transaction {index + 1}</div>
      <div className={`transaction-list-item__status transaction-list-item__status__${status}`}>
        {LabelByStatus[status]}
        {IconByStatus[status]}
      </div>
      {isError ? (
        <RoundedButton type="secondary" onClick={() => batchReleasePledge(transaction.pledges)}>
          Retry
        </RoundedButton>
      ) : null}
    </div>
  );
}

export default TransactionListItem;
