import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import NumberFormat from 'react-number-format';
import RoundedAvatar from 'components/avatar/rounded-avatar';
import IconButton from 'components/button/icon-button';
import SvgIcon from 'components/svgIcon';
import RoundedButton from 'components/button/rounded-button';
import CustomProgressBar from 'components/progress-bar/custom-progress-bar';
import CloseDealModal from 'features/deals/CloseDealModal';
import moment from 'moment';
import {
  updateGlobal,
  updateLoading,
  removeAnimateDeal,
  setUserDeal,
  updateDeal as updateDealAction,
} from 'store/actions';
import CreateClaimerModal from 'features/deals/CreateClaimerModal';
import { capitalize, getDealStatusClass } from 'utils/helpers';
import NameEditModal from 'components/NameEditModal';
import { useNotifications, useApiService } from 'hooks';
import { updateDeal } from 'services/apiService';
import Tooltip from 'components/tooltip';
import allDealsSingleRemote from 'screens/AllDealsSingle/remote';
import './index.scss';
import ConfirmDealCancellationModal from 'features/deals/ConfirmDealCancellationModal';
import { getAddressLinkByChainId } from 'contracts/explorers';
import useUpdatePoolStatus from 'contracts/poolManager/hooks/useUpdatePoolStatus';
import PoolStatus from 'contracts/poolManager/constants';
import usePoolQuery from 'contracts/pledgeVault/hooks/usePoolQuery';
import { useQueryClient } from 'react-query';
import useAdminDealQuery from 'hooks/useAdminDealQuery';
import { getDealStatusName } from 'models/dealStatusModel';
import DealInfoDesktop from '../DealInfo';
import ImageEditModal from './ImageEditModal';
import DealManageDropdown from './DealManageDropdown';
import remote from './remote';
import { isActionEnabled } from './helpers';
import ActivePhases from '../UserDealsTable/ActivePhases';

const DealRow = ({ deal: initDeal, onCloseDeal }) => {
  const globalReducer = useSelector((state) => state.global);
  const { animateDeals } = globalReducer;
  const dispatch = useDispatch();
  const [closeModalOpened, setCloseModalOpened] = useState(false);
  const [nameEditModalOpen, setNameEditModalOpen] = useState(false);
  const [claimerModalOpen, setClaimerModalOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeInfoTab, setActiveInfoTab] = useState('info');
  const [overviewModalOpen, setOverviewModalOpen] = useState(false);
  const [highlightsModalOpen, setHighlightsModalOpen] = useState(false);
  const [basicMetricsModalOpen, setBasicMetricsModalOpen] = useState(false);
  const [blackDragonDealModalOpen, setBlackDragonDealModalOpen] = useState(false);
  const [raiseStatsModalOpen, setRaiseStatsModalOpen] = useState(false);
  const [partnersModalOpen, setPartnersModalOpen] = useState(false);
  const [vestingClaimInfoModalOpen, setVestingClaimInfoModalOpen] = useState(false);
  const [teamModalOpen, setTeamModalOpen] = useState(false);
  const [projectsModalOpen, setProjectsModalOpen] = useState(false);
  const [imageEditModalOpen, setImageEditModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [adminOverviewModalOpen, setAdminOverviewModalOpen] = useState(false);
  const [vestingBatchInfoModalOpen, setVestingBatchInfoModalOpen] = useState(false);
  const [onUpdateDeal] = useApiService();
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const { deal } = useAdminDealQuery(initDeal);

  const { pool, isLoading: isPoolLoading } = usePoolQuery(deal);

  // backwards compatibility
  const status = pool?.status || getDealStatusName(deal.statusId);

  const queryClient = useQueryClient();

  const { updatePoolStatus } = useUpdatePoolStatus(deal.address, {
    onSuccess: () => {
      queryClient.invalidateQueries(['deal', deal.id]);
      queryClient.invalidateQueries(['admin-deal', deal.id]);
    },
    onError: () => {
      showErrorNotification('Error updating deal status');
    },
  });

  const { data: pledges } = remote.useGetPledge(deal.id, activeInfoTab === 'pledge');

  const toggleImageEditModal = (e) => {
    if (e) {
      e.preventDefault();
    }

    setImageEditModalOpen(!imageEditModalOpen);
  };

  const toggleDrop = () => {
    setShowDropdown(!showDropdown);
  };

  const onNameChangeSubmit = async (name) => {
    try {
      const response = await updateDeal(deal.id, { name });
      const changedData = JSON.parse(response.config.data);
      const newDeal = JSON.parse(JSON.stringify(deal));
      newDeal.name = changedData.name;
      dispatch(setUserDeal(newDeal));
      setNameEditModalOpen(false);
      showSuccessNotification('Updated successfully');
    } catch (err) {
      console.error(err);
      showErrorNotification('Something went wrong');
    }
  };

  const onManage = (e) => {
    if (!activeInfoTab) {
      setActiveInfoTab('info');
    }

    if (showDropdown) {
      toggleDrop();
    } else {
      const headerOffset = 135;
      const elementPosition = e.target.closest('.deal-row-top').getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      setTimeout(() => {
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }, 400);
      dispatch(updateGlobal({ activeDeal: deal, dealEditModalAction: 'update' }));
      toggleDrop();
    }
  };

  const updateOldDealStatus = allDealsSingleRemote.useUpdateVestingStatus(+deal.id);
  const onDistributeDeal = async () => {
    try {
      dispatch(updateLoading(true));
      await updateOldDealStatus.mutateAsync({
        vestingStatusId: 2,
        isOldDeal: false,
      });

      dispatch(
        updateDealAction({
          id: deal.id,
          vestingStatusId: 2,
          vestingStatus: 'Distributed',
          status: 'distributed',
        })
      );

      showSuccessNotification('Deal status updated to distributed!');
    } catch (err) {
      showErrorNotification('Deal status not updated!');
    } finally {
      dispatch(updateLoading(false));
    }
  };

  const onClaimContract = async () => {
    setClaimerModalOpen(true);
  };

  const handleToggleCloseModal = () => {
    setCloseModalOpened(!closeModalOpened);
  };

  const toggleClaimerModal = () => {
    setClaimerModalOpen(!claimerModalOpen);
  };

  const getAnimateClass = (val) => {
    if (
      animateDeals &&
      animateDeals.fields &&
      deal.address === animateDeals.address &&
      (animateDeals?.fields.includes(val) || animateDeals?.fields.includes('allFields'))
    ) {
      return 'animate';
    }
    return val;
  };

  useEffect(() => {
    if (animateDeals && animateDeals.fields && deal.address === animateDeals.address) {
      setTimeout(() => {
        dispatch(removeAnimateDeal());
      }, 3000);
    }
  }, [animateDeals, deal, dispatch]);

  const toggleOverviewModal = () => {
    setOverviewModalOpen(!overviewModalOpen);
  };

  const toggleHighlightsModal = () => {
    setHighlightsModalOpen(!highlightsModalOpen);
  };

  const toggleBasicMetricsModal = () => {
    setBasicMetricsModalOpen(!basicMetricsModalOpen);
  };

  const toggleBlackDragonDealModal = () => {
    setBlackDragonDealModalOpen(!blackDragonDealModalOpen);
  };

  const toggleRaiseStatsModal = () => {
    setRaiseStatsModalOpen(!raiseStatsModalOpen);
  };

  const toggleVestingBatchInfoModal = () => {
    setVestingBatchInfoModalOpen(!vestingBatchInfoModalOpen);
  };

  const togglePartnersModal = () => {
    setPartnersModalOpen(!partnersModalOpen);
  };

  const toggleVestingClaimInfoModal = () => {
    setVestingClaimInfoModalOpen(!vestingClaimInfoModalOpen);
  };

  const toggleTeamModal = () => {
    setTeamModalOpen(!teamModalOpen);
  };

  const toggleAdminOverviewModal = () => {
    setAdminOverviewModalOpen(!adminOverviewModalOpen);
  };

  const toggleProjectsModal = () => {
    setProjectsModalOpen(!projectsModalOpen);
  };

  if (isPoolLoading) {
    return null;
  }

  return (
    <>
      {closeModalOpened && (
        <CloseDealModal
          open={closeModalOpened}
          deal={deal}
          onOk={onCloseDeal}
          onClose={handleToggleCloseModal}
          handleToggleCloseModal={handleToggleCloseModal}
        />
      )}
      {imageEditModalOpen && (
        <ImageEditModal
          deal={deal}
          onOk={onUpdateDeal}
          onCancel={toggleImageEditModal}
          defaultValue={deal.imageUrl}
        />
      )}
      {cancelModalOpen && (
        <ConfirmDealCancellationModal
          open={cancelModalOpen}
          onOk={async () => {
            await updatePoolStatus(PoolStatus.Canceled);

            setCancelModalOpen(false);
          }}
          onClose={() => setCancelModalOpen(false)}
          dealName={deal.name}
          dealId={deal.id}
        />
      )}
      <NameEditModal
        name={deal?.name}
        open={nameEditModalOpen}
        onOk={onNameChangeSubmit}
        onCancel={() => setNameEditModalOpen(false)}
      />
      {claimerModalOpen && (
        <CreateClaimerModal
          open={claimerModalOpen}
          onClose={toggleClaimerModal}
          deal={deal}
          showErrorNotification={showErrorNotification}
        />
      )}
      <div className="deal-holder d-flex full-width">
        <div className="deal-row-top" onClick={onManage}>
          <div
            className={`deal__field deal__field-avatar vertical-center ${getAnimateClass(
              'imageUrl'
            )}`}
          >
            <a
              href={getAddressLinkByChainId(deal.chainId, deal.address)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <RoundedAvatar src={deal.imageUrl} />
              <RoundedButton
                type="default"
                onClick={toggleImageEditModal}
                className="image-edit-btn"
              >
                Edit
              </RoundedButton>
            </a>
          </div>
          <div
            className={`deal__field deal__field-name vertical-center ${getAnimateClass('name')}`}
          >
            <div>
              <div className="deal-name">
                {/* <CustomInput name="name" value={deal.name} onChange={onInputChange} /> */}
                <span>{deal.name}</span>
                <RoundedButton
                  type="default"
                  onClick={() => setNameEditModalOpen(true)}
                  className="name-edit-btn"
                >
                  Edit
                </RoundedButton>
              </div>
              <CustomProgressBar total={Number(deal.dealSize)} value={Number(deal.raisedAmount)} />
            </div>
          </div>
          <div className="deal__field deal__field-date vertical-center">
            <div>
              <span>{moment(deal.datePublished).format('D-MMM-YYYY')}</span>
            </div>
          </div>
          <div className="deal__field deal__field-date vertical-center">
            <div>
              <span>{deal.dateClosed ? moment(deal.dateClosed).format('D-MMM-YYYY') : ''}</span>
            </div>
          </div>
          <div
            className={`deal__field deal__field-status deal__field-status--${getDealStatusClass(
              status
            )} vertical-center ${getAnimateClass('status')}`}
          >
            <span className="deal__field-status__icon">
              <SvgIcon name="dot" />
            </span>
            <span className="deal__field-status__name">
              {status === 'opened'
                ? deal.raisedAmount === deal.dealSize
                  ? 'filled'
                  : 'live'
                : status === 'distributed'
                ? 'completed'
                : capitalize(status)}
            </span>
          </div>
          <div
            className={`deal__field deal__field-raised-amount vertical-center ${getAnimateClass(
              'raisedAmount'
            )}`}
          >
            <NumberFormat
              value={Number(deal.raisedAmount).toFixed(0)}
              thousandSeparator
              displayType="text"
              prefix="$"
            />
          </div>
          <div
            className={`deal__field deal__field-size vertical-center ${getAnimateClass(
              'raisedAmount'
            )}`}
          >
            <NumberFormat
              value={Number(deal.dealSize).toFixed(0)}
              thousandSeparator
              displayType="text"
              prefix="$"
            />
          </div>
          <div className="deal__field deal__field-status-stepper vertical-center">
            <Tooltip title="Deal open for contributions.">
              <span
                className={`deal__field-status-step ${
                  status === 'opened' ? 'deal__field-status-step--opened--active' : ''
                }`}
              >
                <IconButton
                  icon="statusOpened"
                  disabled={!isActionEnabled(pool?.statusId, PoolStatus.Active)}
                  onClick={() => updatePoolStatus(PoolStatus.Active)}
                />
              </span>
            </Tooltip>
            <Tooltip title="New contributions paused, existing ones are still present..">
              <span
                className={`deal__field-status-step ${
                  status === 'paused' ? 'deal__field-status-step--paused--active' : ''
                }`}
              >
                <IconButton
                  icon="statusPaused"
                  disabled={!isActionEnabled(pool?.statusId, PoolStatus.Paused)}
                  onClick={() => updatePoolStatus(PoolStatus.Paused)}
                />
              </span>
            </Tooltip>
            <Tooltip title="Close the deal for new contributions and send funds to Admin deal wallet.">
              <span
                className={`deal__field-status-step ${
                  status === 'closed' ? 'deal__field-status-step--closed--active' : ''
                }`}
              >
                <IconButton
                  icon="statusClosed"
                  disabled={!isActionEnabled(pool?.statusId, PoolStatus.Closed)}
                  onClick={handleToggleCloseModal}
                />
              </span>
            </Tooltip>
            <Tooltip title="Deal inactive, collected funds available for refund.">
              <span
                className={`deal__field-status-step ${
                  status === 'canceled' ? 'deal__field-status-step--canceled--active' : ''
                }`}
              >
                <IconButton
                  icon="statusCanceled"
                  disabled={!isActionEnabled(pool?.statusId, PoolStatus.Canceled)}
                  onClick={() => setCancelModalOpen(true)}
                />
              </span>
            </Tooltip>
            <Tooltip title="Deal inactive, all tokens delivered.">
              <span
                className={`deal__field-status-step ${
                  status === 'distributed' ? 'deal__field-status-step--distributed--active' : ''
                }`}
              >
                <IconButton icon="statusDistributed" onClick={onDistributeDeal} />
              </span>
            </Tooltip>
            <Tooltip title="Deploy a smart contracts for platform claiming.">
              <span
                className={`deal__field-status-step claimer ${
                  status === 'closed' ? 'deal__field-status-step--deployClaimer--active' : ''
                }`}
              >
                <IconButton
                  icon="iconClaimContract"
                  disabled={status !== 'closed'}
                  onClick={onClaimContract}
                />
              </span>
            </Tooltip>
            <ActivePhases deal={deal} />
            <RoundedButton type="primary">{showDropdown ? 'Update' : 'Manage'}</RoundedButton>
            <DealManageDropdown deal={deal} showHide />
          </div>
        </div>

        <div className={`deal-info-mobile${showDropdown ? ' show' : ''}`}>
          <div className="deal-info-mobile-box">
            <p>Status</p>
            <h3>Deals</h3>
          </div>
          <div className="deal-info-mobile-box">
            <p>Filled</p>
            <h3>
              <NumberFormat
                value={Number(deal.raisedAmount).toFixed(0)}
                thousandSeparator
                displayType="text"
                prefix="$"
              />
            </h3>
          </div>
          <div className="deal-info-mobile-box">
            <p>Deal size</p>
            <h3>
              <NumberFormat
                value={Number(deal.dealSize).toFixed(0)}
                thousandSeparator
                displayType="text"
                prefix="$"
              />
            </h3>
          </div>
          <div className="deal-info-mobile-box">
            <p>Personal Max</p>
            <h3>
              <NumberFormat
                value={Number(deal.userCap).toFixed(0)}
                thousandSeparator
                displayType="text"
                prefix="$"
              />
            </h3>
          </div>
          <div className="deal-info-mobile-box">
            <p>My contribution</p>
            <h3>
              <NumberFormat
                value={Number(deal.userCap).toFixed(0)}
                thousandSeparator
                displayType="text"
                prefix="$"
              />
            </h3>
          </div>
          <div className="deal-info-mobile-actions">
            <RoundedButton type="primary" onClick={onManage}>
              Manage
            </RoundedButton>
          </div>
        </div>

        {showDropdown ? (
          <DealInfoDesktop
            showDropdown={showDropdown}
            activeInfoTab={activeInfoTab}
            setActiveInfoTab={setActiveInfoTab}
            overviewModalOpen={overviewModalOpen}
            toggleOverviewModal={toggleOverviewModal}
            highlightsModalOpen={highlightsModalOpen}
            toggleHighlightsModal={toggleHighlightsModal}
            basicMetricsModalOpen={basicMetricsModalOpen}
            toggleBasicMetricsModal={toggleBasicMetricsModal}
            blackDragonDealModalOpen={blackDragonDealModalOpen}
            toggleBlackDragonDealModal={toggleBlackDragonDealModal}
            raiseStatsModalOpen={raiseStatsModalOpen}
            toggleRaiseStatsModal={toggleRaiseStatsModal}
            partnersModalOpen={partnersModalOpen}
            togglePartnersModal={togglePartnersModal}
            vestingClaimInfoModalOpen={vestingClaimInfoModalOpen}
            toggleVestingClaimInfoModal={toggleVestingClaimInfoModal}
            teamModalOpen={teamModalOpen}
            toggleTeamModal={toggleTeamModal}
            projectsModalOpen={projectsModalOpen}
            adminOverviewModalOpen={adminOverviewModalOpen}
            toggleAdminOverviewModal={toggleAdminOverviewModal}
            toggleProjectsModal={toggleProjectsModal}
            vestingBatchInfoModalOpen={vestingBatchInfoModalOpen}
            toggleVestingBatchInfoModal={toggleVestingBatchInfoModal}
            deal={deal}
            pledges={pledges}
          />
        ) : null}
      </div>
    </>
  );
};

DealRow.propTypes = {
  deal: PropTypes.shape(),
};

DealRow.defaultProps = {
  deal: {},
};

export default React.memo(DealRow);
