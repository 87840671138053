import { useState, useCallback } from 'react';

export default function useFormattedState(initialState) {
  const [state, setState] = useState(initialState);

  const formatValues = (values) => {
    return Object.entries(values).reduce((acc, [key, value]) => {
      if (value === '') {
        acc[key] = '';
      } else if (!Number.isNaN(value)) {
        acc[key] = Number(value).toFixed(0);
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});
  };

  const setFormattedState = useCallback((updater) => {
    if (typeof updater === 'function') {
      setState((prevState) => {
        const nextState = updater(prevState);

        return formatValues(nextState);
      });
    } else {
      setState(formatValues(updater));
    }
  }, []);

  return [state, setFormattedState];
}
