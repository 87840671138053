import React from 'react';
import RoundedButton from 'components/button/rounded-button';
import useUpdatePoolConfig from 'contracts/poolManager/hooks/useUpdatePoolConfig';
import { ethers } from 'ethers';
import { useDeployContractContext } from '../context';
import { isValidPoolConfig } from '../helpers';

function UpdatePoolAction({ disabled, onValidate }) {
  const { pool, deal, poolConfig, hasErrors } = useDeployContractContext();

  const { updatePoolConfig, isLoading } = useUpdatePoolConfig(deal.address);

  const isPoolConfigDirty = () => {
    const { minContribution, maxContribution, dealSize } = pool;

    return (
      +poolConfig.minContribution !== +minContribution ||
      +poolConfig.maxContribution !== +maxContribution ||
      +poolConfig.dealSize !== +dealSize
    );
  };

  const onUpdatePoolConfig = () => {
    const { isFormValid } = onValidate();

    if (!isFormValid) {
      return;
    }

    updatePoolConfig({
      minContribution: ethers.utils.parseUnits(poolConfig.minContribution, 'mwei'),
      maxContribution: ethers.utils.parseUnits(poolConfig.maxContribution || '0', 'mwei'),
      dealSize: ethers.utils.parseUnits(poolConfig.dealSize, 'mwei'),
    });
  };

  const isDisabled =
    isLoading || !isValidPoolConfig(poolConfig) || !isPoolConfigDirty() || disabled || hasErrors;

  return (
    <RoundedButton onClick={onUpdatePoolConfig} disabled={isDisabled} type="secondary">
      Update Pool
    </RoundedButton>
  );
}

export default UpdatePoolAction;
