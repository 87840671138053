export const PledgeStatus = {
  // Default
  NotActive: 0,
  // Pledge allowed, release allowed for users. Transition allowed.
  OpenUnlocked: 1,
  // Pledge allowed, release NOT allowed. Transition allowed.
  OpenLocked: 2,
  // Pledge NOT allowed, release NOT allowed. Transition allowed.
  Freezed: 3,
  // Pledge NOT allowed, release allowed for admin. Transition NOT allowed.
  Canceled: 4,
  // Pledge NOT allowed, release NOT allowed. Transition NOT allowed.
  Finalized: 5,
};

export const AdditionalPledgeStatus = {
  ...PledgeStatus,
  Open: 100,
  Closed: 101,
};

export default AdditionalPledgeStatus;
