import React, { useEffect, useMemo, useState } from 'react';

import '../../../index.scss';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { useDeployContractContext } from 'features/deals/DealInfo/DeployContractTab/context';
import BdtLevelCapInput from 'features/deals/AdminDealsTable/BdtLevelCapInput';
import Whitelist from 'features/deals/AdminDealsTable/Whitelist';
import SvgIcon from 'components/svgIcon';
import ImportWhitelistCSV from 'features/deals/AdminDealsTable/ImportWhitelistCSV';
import { LevelOptions } from 'features/deals/DealInfo/DeployContractTab/constants';
import MultipleSelect from 'components/select/multi-select';

export default function AccessManagementAccordion({ phase, index }) {
  const {
    phaseDisplayState,
    setPhaseDisplayState,
    setWhitelistPickerData,
    handlePhaseChange,
    errors,
    phaseValidState,
    isDirty,
  } = useDeployContractContext();

  const { sectionError } = phaseValidState;

  const [isWhitelistEnabled, setIsWhitelistEnabled] = useState(false);

  const shouldDisplayWhitelist = () => {
    return (
      phase.accessLevels.includes(4) ||
      (Array.isArray(phase.whitelist) && phase.whitelist.length > 0) ||
      phase.minViewLevel.toString() === '4' ||
      (phase.model === 'WalletCap' && isWhitelistEnabled)
    );
  };

  useEffect(() => {
    if (!phase.whitelist) {
      return;
    }

    setIsWhitelistEnabled(true);
  }, [phase.whitelist]);

  const invalidWhitelistAddresses = useMemo(() => {
    if (!errors?.[`${index}-whitelist`]) {
      return [];
    }

    return errors?.[`${index}-whitelist`]?.map(({ address }) => address);
  }, [errors, index]);

  const isError = isDirty && sectionError === 'access-management';

  return (
    <Accordion
      onChange={() =>
        setPhaseDisplayState({ ...phaseDisplayState, accordion: 'access-management' })
      }
    >
      <AccordionSummary expandIcon={<SvgIcon name="arrowDown" width={16} height={16} />}>
        <SvgIcon name="iconUserGray" width={12} height={12} />
        <Typography>Access Management</Typography>
        {isError && <SvgIcon name="error" width={14} height={14} />}
      </AccordionSummary>
      <AccordionDetails>
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          className="whitelist-content whitelist-wrapper "
        >
          <MultipleSelect
            name="accessLevels"
            placeholder="Access Level"
            label="Access Level"
            options={LevelOptions}
            initialState={phase.accessLevels}
            onChange={(name, value) => handlePhaseChange(name, value, index)}
            error={errors[`${index}-accessLevels`]}
          />
          {phase.model === 'WalletCap' ? (
            <BdtLevelCapInput
              phaseLevels={phase.levels}
              accessLevels={phase.accessLevels}
              setPhaseLevels={(updatedLevels) => handlePhaseChange('levels', updatedLevels, index)}
              errors={errors[`${index}-levels`]}
            />
          ) : null}
          {shouldDisplayWhitelist() ? (
            <div className="deal-edit-modal__content whitelist-content">
              <div className="whitelist-header">
                <label>
                  Whitelist
                  <button
                    type="button"
                    onClick={() => setWhitelistPickerData({ whitelist: phase.whitelist, i: index })}
                  >
                    <SvgIcon name="iconDownloadPledge" width={16} height={16} />
                  </button>
                </label>
                <ImportWhitelistCSV
                  inputId={`csv-input-phase-${index}`}
                  onImport={(newAddresses) => {
                    handlePhaseChange('whitelist', [...phase.whitelist, ...newAddresses], index);
                  }}
                />
              </div>
              <Whitelist
                showAmount={phase.model === 'WalletCap'}
                whitelistId={`${phase.dealId}-${index}`}
                whitelist={phase.whitelist}
                onChange={(updatedWhitelist) =>
                  handlePhaseChange('whitelist', updatedWhitelist, index)
                }
                onValidityChange={() => null}
                invalidAddresses={invalidWhitelistAddresses}
                error="Exceeds deal size"
              />
            </div>
          ) : null}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
