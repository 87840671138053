import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { updateLoading, addNotification, setActiveHashes } from 'store/actions';
import { ethers } from 'ethers';
import { DEFAULT_CHAIN_NAME } from 'constants/config';
import notificationTypes from 'constants/notificationTypes';
import useWithCorrectNetwork from 'hooks/useWithCorrectNetwork';
import { MutationArgs } from 'utils/types';
import usePledgeVault from './usePledgeVault';

const useReleaseMutation = (address, { onSuccess, onError } = MutationArgs) => {
  const dispatch = useDispatch();

  const pledgeContract = usePledgeVault();

  const queryClient = useQueryClient();

  const {
    mutateAsync: release,
    isLoading,
    isError,
    error,
  } = useMutation(
    async (amountInEther) => {
      const amountInWei = ethers.utils.parseUnits(amountInEther, 'mwei');

      const tx = await pledgeContract.releasePledge(address, amountInWei);

      const receipt = await tx.wait();

      return {
        receipt,
      };
    },
    {
      onMutate: () => {
        dispatch(updateLoading(true));
      },
      onSuccess: ({ receipt }) => {
        dispatch(updateLoading(false));
        dispatch(
          setActiveHashes([
            {
              hash: receipt.transactionHash,
              chain: DEFAULT_CHAIN_NAME,
              pending: false,
              callback: () => {
                queryClient.invalidateQueries(['pool', address]);
                queryClient.invalidateQueries(['pool-info', address]);
                queryClient.invalidateQueries(['pledge-balance']);
                queryClient.invalidateQueries(['my-contribution', address]);
              },
            },
          ])
        );
        dispatch(
          addNotification({
            name: receipt.transactionHash,
            chain: DEFAULT_CHAIN_NAME,
            status: 'pending',
            statusText: 'Pending!',
            time: Date.now(),
            type: notificationTypes.GENERAL,
          })
        );

        onSuccess();
      },
      onError: () => {
        dispatch(updateLoading(false));
        dispatch(
          addNotification({
            name: 'Release pledge Error',
            status: 'error',
            statusText: 'Error!',
            time: Date.now(),
            chain: DEFAULT_CHAIN_NAME,
            type: notificationTypes.GENERAL,
          })
        );
        onError();
      },
    }
  );

  const checkedRelease = useWithCorrectNetwork(release);

  return {
    release: checkedRelease,
    isLoading,
    isError,
    error,
  };
};

export default useReleaseMutation;
