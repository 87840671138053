import IconChart from 'assets/icons/icon-chart.svg';
import IconLayers from 'assets/icons/icon-layers.svg';
import IconTimePast from 'assets/icons/icon-time-past.svg';
import IconSettingsSliders from 'assets/icons/icon-settings-sliders.svg';
import IconKey from 'assets/icons/icon-key.svg';
import IconWallet from 'assets/icons/icon-wallet.svg';
import IconMyChart from 'assets/icons/icon-my-chart.svg';
import IconSearch from 'assets/icons/icon-search.svg';
import IconNotification from 'assets/icons/icon-notification.svg';
import IconFilter from 'assets/icons/icon-filter.svg';
import IconHelp from 'assets/icons/icon-help.svg';
import IconDot from 'assets/icons/icon-dot.svg';
import IconArrowDown from 'assets/icons/icon-arrowDown.svg';
import IconArrowLeft from 'assets/icons/icon-arrowLeft.svg';
import IconArrowRight from 'assets/icons/icon-arrowRight.svg';

import IconStatusOpened from 'assets/icons/icon-status-opened.svg';
import IconStatusPaused from 'assets/icons/icon-status-paused.svg';
import IconStatusClosed from 'assets/icons/icon-status-closed.svg';
import IconStatusCanceled from 'assets/icons/icon-status-canceled.svg';
import IconStatusDistributed from 'assets/icons/icon-status-distributed.svg';
import IconPlus from 'assets/icons/icon-plus.svg';
import IconClose from 'assets/icons/icon-close.svg';
import IconCloseRed from 'assets/icons/icon-close-red.svg';
import IconCloseGray from 'assets/icons/icon-close-gray.svg';
import IconReport from 'assets/icons/icon-report.svg';

import IconLockPledge from 'assets/icons/pledge/icon-lock-pledge.svg';
import IconUnlockPledge from 'assets/icons/pledge/icon-unlock-pledge.svg';

import IconFacebook from 'assets/icons/icon-facebook.svg';
import IconGooglePlus from 'assets/icons/icon-google-plus.svg';
import IconTwitter from 'assets/icons/icon-twitter.svg';
import IconInstagram from 'assets/icons/icon-instagram.svg';

import IconDiscord from 'assets/icons/icon-discord.svg';
import IconTelegram from 'assets/icons/icon-telegram.svg';
import IconMail from 'assets/icons/icon-mail.svg';
import IconMenu from 'assets/icons/icon-menu.svg';
import IconMenuExpanded from 'assets/icons/icon-menu-expanded.svg';
import IconMenuCollapsed from 'assets/icons/icon-menu-collapsed.svg';

import IconError from 'assets/icons/icon-error.svg';
import IconTick from 'assets/icons/icon-tick.svg';

import IconTokensClaimed from 'assets/icons/icon-tokensClaimed.svg';
import IconTokensClaimedDisabled from 'assets/icons/icon-tokensClaimedDisabled.svg';

import IconLockupGreen from 'assets/icons/icon-lockup-green-backup.svg';
import IconLockupWhite from 'assets/icons/icon-lockupWhite.svg';
import IconSwapGreen from 'assets/icons/icon-swap-green.svg';
import IconUserProfileGreen from 'assets/icons/icon-user-profile-green.svg';
import IconWhitelistGreen from 'assets/icons/icon-whitelist-green.svg';

import IconLogoOrange from 'assets/icons/icon-lockup-tokens-locked.svg';
import IconLogoGreen from 'assets/icons/icon-lockup-tokens-avalable.svg';
import IconTotalTokensLocked from 'assets/icons/icon-lockup-tokens-safe.svg';
import IconMyProRataAllocation from 'assets/icons/icon-lockup-pro-rata-share.svg';

import IconTokenClaimedDisabled from 'assets/icons/icon-token-claimed-disabled.svg';

import IconChevronDown from 'assets/icons/icon-chevron-down.svg';
import IconChevronUp from 'assets/icons/icon-chevron-up.svg';

import IconClaimContract from 'assets/icons/icon-claim-contract.svg';

import IconTime from 'assets/icons/icon-time.svg';

import IconSpinner from 'assets/icons/icon-spinner.svg';

import IconNotificationSuccess from 'assets/icons/icon-notification-success.svg';
import IconNotificationPending from 'assets/icons/icon-notification-pending.svg';
import IconNotificationFailed from 'assets/icons/icon-notification-failed.svg';
import IconNotificationCircle from 'assets/icons/icon-notification-circle.svg';

import IconExternalLink from 'assets/icons/icon-external-link.svg';
import IconExternalLinkSmall from 'assets/icons/icon-external-link-small.svg';

import IconDealInfoAmount from 'assets/icons/icon-deal-info-amount.svg';
import IconDealInfoLockup from 'assets/icons/icon-deal-info-lockup.svg';
import IconDealInfoFee from 'assets/icons/icon-deal-info-fee.svg';

import IconProjectLinksWebsite from 'assets/icons/project-links/icon-project-links-website.svg';
import IconProjectLinksDiscord from 'assets/icons/project-links/icon-project-links-discord.svg';
import IconProjectLinksMail from 'assets/icons/project-links/icon-project-links-mail.svg';
import IconProjectLinksStickyNote from 'assets/icons/project-links/icon-project-links-sticky-note.svg';
import IconProjectLinksTelegram from 'assets/icons/project-links/icon-project-links-telegram.svg';
import IconProjectLinksTwitter from 'assets/icons/project-links/icon-project-links-twitter.svg';
import IconProjectLinksLink from 'assets/icons/project-links/icon-project-links-link.svg';
import IconProjectLinksBlog from 'assets/icons/project-links/icon-project-links-blog.svg';

import IconPledgeBoxName from 'assets/icons/pledge/icon-pledge-name.svg';
import IconPledgeBoxDate from 'assets/icons/pledge/icon-pledge-date.svg';
import IconPledgeBoxAmount from 'assets/icons/pledge/icon-pledge-amount.svg';
import IconSumma from 'assets/icons/pledge/icon-summa.svg';
import IconDownloadPledge from 'assets/icons/pledge/icon-download-green.svg';
import IconPledges from 'assets/icons/icon-pledges.svg';
import IconBuffering from 'assets/icons/icon-buffering.svg';

import IconDealsEdit from 'assets/icons/icon-deals-edit.svg';
import IconDealsErase from 'assets/icons/icon-deals-erase.svg';
import IconDealsModalEdit from 'assets/icons/icon-deals-modal-edit.svg';

import IconDeleteX from 'assets/icons/icon-delete-x.svg';

import IconDealDiscord from 'assets/icons/projects-modal/icon-discord.svg';
import IconDealMail from 'assets/icons/projects-modal/icon-mail.svg';
import IconDealPitchDeck from 'assets/icons/projects-modal/icon-pitch-deck.svg';
import IconDealTelegram from 'assets/icons/projects-modal/icon-telegram.svg';
import IconDealTwitter from 'assets/icons/projects-modal/icon-twitter.svg';
import IconDealWebsite from 'assets/icons/projects-modal/icon-website.svg';
import IconDealWhitepaper from 'assets/icons/projects-modal/icon-whitepaper.svg';

import MetamaskFox from 'assets/icons/metamask-fox.svg';
import IconProfileNotVerified from 'assets/icons/profile/icon-profile-not-verified.svg';
import IconProfileVerified from 'assets/icons/profile/icon-profile-verified.svg';
import IconProfileWalletModalHeader from 'assets/icons/profile/icon-profile-wallet-modal-header.svg';
import IconProfileWalletEdit from 'assets/icons/profile/icon-profile-wallet-edit.svg';

import IconCropperRotate from 'assets/icons/icon-crop-rotate.svg';
import IconVisibility from 'assets/icons/icon-visibility.svg';
import IconVisibilityOff from 'assets/icons/icon-visibility-off.svg';
import IconVisibilityOffGray from 'assets/icons/icon-visibility-off-gray.svg';
import IconDropdownVisibilityOffGray from 'assets/icons/icon-deal-dropdown-visibility-off.svg';
import IconDraftGray from 'assets/icons/icon-draft-gray.svg';
import IconPollGray from 'assets/icons/icon-poll-gray.svg';
import IconDealsUserCancel from 'assets/icons/icon-deals-user-cancel-clicked.svg';
import IconDealsCheckmarkClicked from 'assets/icons/icon-deals-user-checkmark-clicked.svg';
import IconSettingsGray from 'assets/icons/icon-settings-gray.svg';
import IconUserProfileEdit from 'assets/icons/icon-user-profile-edit.svg';
import IconBlackDragon from 'assets/icons/icon-black-dragon.svg';
import IconErase from 'assets/icons/icon-deals-admin-erase-active.svg';
import IconArchive from 'assets/icons/icon-archive.svg';
import IconUnrchive from 'assets/icons/icon-unarchive.svg';
import IconMore from 'assets/icons/icon-more-gray.svg';
import IconCopy from 'assets/icons/icon-copy-gray.svg';
import IconInvoiceDownloadGrey from 'assets/icons/icon-invoice-download-grey.svg';
import IconInvoice from 'assets/icons/icon-invoice-grey.svg';
import IconDownload from 'assets/icons/icon-invoice-download-grey.svg';
import IconInvoiceExport from 'assets/icons/icon-invoice-export.svg';
import IconBookkeepingReport from 'assets/icons/icon-bookkeeping-report.svg';
import IconAnalytics from 'assets/icons/icon-my-analytics-green.svg';
import IconDashboard from 'assets/icons/icon-dashboard-gray.svg';
import IconUserGray from 'assets/icons/icon-user-profile-gray.svg';

import IconDraftPoll from 'assets/icons/icon-draft-poll.svg';
import IconFinishedPoll from 'assets/icons/icon-finished-poll.svg';
import IconInProgressPoll from 'assets/icons/icon-in-progress-poll.svg';
import IconPreview from 'assets/icons/icon-preview.svg';
import IconPollEdit from 'assets/icons/icon-poll-edit.svg';

import IconInvest from 'assets/icons/icon-deals-user-invest.svg';
import IconProfit from 'assets/icons/icon-deals-user-profit.svg';
import IconReturn from 'assets/icons/icon-deals-user-return.svg';
import IconRoi from 'assets/icons/icon-roi.svg';
import IconAthRoi from 'assets/icons/icon-ath-roi.svg';
import IconAnalyticDeals from 'assets/icons/icon-analytic-deals.svg';

import IconPuase from 'assets/icons/pause.svg';
import IconPlay from 'assets/icons/play.svg';
import IconInfo from 'assets/icons/icon-info.svg';

import AddDeal from 'assets/icons/icon-add-deal.svg';

const Icons = {
  chart: IconChart,
  myChart: IconMyChart,
  search: IconSearch,
  notification: IconNotification,
  filter: IconFilter,
  help: IconHelp,
  dot: IconDot,
  arrowDown: IconArrowDown,
  arrowLeft: IconArrowLeft,
  arrowRight: IconArrowRight,
  statusOpened: IconStatusOpened,
  statusPaused: IconStatusPaused,
  statusClosed: IconStatusClosed,
  statusCanceled: IconStatusCanceled,
  statusDistributed: IconStatusDistributed,
  plus: IconPlus,
  close: IconClose,
  closeRed: IconCloseRed,
  closeGray: IconCloseGray,
  report: IconReport,

  facebook: IconFacebook,
  googlePlus: IconGooglePlus,
  twitter: IconTwitter,
  instagram: IconInstagram,

  menu: IconMenu,
  menuExpanded: IconMenuExpanded,
  menuCollapsed: IconMenuCollapsed,
  discord: IconDiscord,
  telegram: IconTelegram,
  mail: IconMail,

  error: IconError,
  success: IconTick,

  iconTokensClaimed: IconTokensClaimed,
  iconTokensClaimedDisabled: IconTokensClaimedDisabled,

  iconLockupGreen: IconLockupGreen,
  iconLockupWhite: IconLockupWhite,
  iconSwapGreen: IconSwapGreen,
  iconUserProfileGreen: IconUserProfileGreen,
  iconWhitelistGreen: IconWhitelistGreen,

  iconLogoOrange: IconLogoOrange,
  iconLogoGreen: IconLogoGreen,
  iconTotalTokensLocked: IconTotalTokensLocked,
  iconMyProRataAllocation: IconMyProRataAllocation,

  iconTokenClaimedDisabled: IconTokenClaimedDisabled,

  iconLockPledge: IconLockPledge,
  iconUnlockPledge: IconUnlockPledge,

  iconChevronDown: IconChevronDown,
  iconChevronUp: IconChevronUp,

  iconClaimContract: IconClaimContract,

  iconInvoiceDownloadGrey: IconInvoiceDownloadGrey,

  iconTime: IconTime,

  iconSpinner: IconSpinner,
  iconBuffering: IconBuffering,

  iconNotificationSuccess: IconNotificationSuccess,
  iconNotificationPending: IconNotificationPending,
  iconNotificationFailed: IconNotificationFailed,
  iconNotificationCircle: IconNotificationCircle,

  iconExternalLink: IconExternalLink,
  iconExternalLinkSmall: IconExternalLinkSmall,

  iconDealInfoAmount: IconDealInfoAmount,
  iconDealInfoLockup: IconDealInfoLockup,
  iconDealInfoFee: IconDealInfoFee,

  iconProjectLinksWebsite: IconProjectLinksWebsite,
  iconProjectLinksDiscord: IconProjectLinksDiscord,
  iconProjectLinksMail: IconProjectLinksMail,
  iconProjectLinksStickyNote: IconProjectLinksStickyNote,
  iconProjectLinksTelegram: IconProjectLinksTelegram,
  iconProjectLinksTwitter: IconProjectLinksTwitter,
  iconProjectLinksLink: IconProjectLinksLink,
  iconProjectLinksBlog: IconProjectLinksBlog,

  iconPledgeBoxName: IconPledgeBoxName,
  iconPledgeBoxDate: IconPledgeBoxDate,
  iconPledgeBoxAmount: IconPledgeBoxAmount,
  iconSumma: IconSumma,
  iconDownloadPledge: IconDownloadPledge,
  iconPledges: IconPledges,

  iconDealsEdit: IconDealsEdit,
  iconDealsErase: IconDealsErase,
  iconDealsModalEdit: IconDealsModalEdit,

  iconDeleteX: IconDeleteX,

  iconDealDiscord: IconDealDiscord,
  iconDealMail: IconDealMail,
  iconDealPitchDeck: IconDealPitchDeck,
  iconDealTelegram: IconDealTelegram,
  iconDealTwitter: IconDealTwitter,
  iconDealWebsite: IconDealWebsite,
  iconDealWhitepaper: IconDealWhitepaper,

  metamaskFox: MetamaskFox,
  iconProfileNotVerified: IconProfileNotVerified,
  iconProfileVerified: IconProfileVerified,
  iconProfileWalletModalHeader: IconProfileWalletModalHeader,
  iconProfileWalletEdit: IconProfileWalletEdit,
  iconUserGray: IconUserGray,

  iconCropRotate: IconCropperRotate,
  iconVisibility: IconVisibility,
  iconVisibilityOff: IconVisibilityOff,
  iconVisibilityOffGray: IconVisibilityOffGray,
  iconDraftGray: IconDraftGray,
  iconPollGray: IconPollGray,
  iconDealsCheckmarkClicked: IconDealsCheckmarkClicked,
  iconDealsUserCancel: IconDealsUserCancel,
  iconSettingsGray: IconSettingsGray,
  iconUserProfileEdit: IconUserProfileEdit,
  iconBlackDragon: IconBlackDragon,
  iconErase: IconErase,
  iconArchive: IconArchive,
  iconUnarchive: IconUnrchive,
  iconMore: IconMore,
  iconCopy: IconCopy,
  iconDropdownVisibilityOffGray: IconDropdownVisibilityOffGray,
  iconDraftPoll: IconDraftPoll,
  iconFinishedPoll: IconFinishedPoll,
  iconInProgressPoll: IconInProgressPoll,
  iconPreview: IconPreview,
  iconPollEdit: IconPollEdit,
  iconInvoice: IconInvoice,
  iconDownload: IconDownload,
  invoiceExport: IconInvoiceExport,
  iconBookkeepingReport: IconBookkeepingReport,
  iconInvest: IconInvest,
  iconProfit: IconProfit,
  iconReturn: IconReturn,
  iconAthRoi: IconAthRoi,
  iconRoi: IconRoi,
  iconAnalyticDeals: IconAnalyticDeals,
  iconAnalytics: IconAnalytics,
  iconDashboard: IconDashboard,
  iconInfo: IconInfo,
  iconLayers: IconLayers,
  iconTimePast: IconTimePast,
  iconSettingsSliders: IconSettingsSliders,
  iconKey: IconKey,
  iconWallet: IconWallet,

  pause: IconPuase,
  play: IconPlay,

  addDeal: AddDeal,
};

export default Icons;
