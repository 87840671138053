import { ethers } from 'ethers';
import { DealStatus } from 'models/constants';
import PledgeStatus from 'contracts/pledgeVault/constants';
import { AllocationModel } from './constants';

function buildPhaseConfig(phase) {
  return {
    name: phase.phaseName,
    startTimestamp: Math.floor(new Date(phase.startDate).getTime() / 1000),
    endTimestamp: Math.floor(new Date(phase.endDate).getTime() / 1000),
    levelsActive: prepareAccessLevels(phase.accessLevels),
    allocationModel: AllocationModel[phase.model],
    phaseCap: phase.cap ? ethers.utils.parseUnits(phase.cap, 'mwei') : 0,
    walletCapsForLevels: phase.levels.map((cap) =>
      cap ? ethers.utils.parseUnits(cap, 'mwei') : 0
    ),
    allowlistMerkleRoot: phase.allowlistMerkleRoot || ethers.constants.HashZero,
    nftPrice: phase.nftPrice ? ethers.utils.parseUnits(phase.nftPrice, 'mwei') : 0,
  };
}

function prepareAccessLevels(inputArray, length = 5) {
  const resultArray = new Array(length).fill(false);

  inputArray.forEach((num) => {
    if (num < length) {
      resultArray[num] = true;
    }
  });

  return resultArray;
}

const preparePhasesForDeploy = (phases) => {
  return phases.reduce(
    (acc, phase) => {
      if (!phase.phaseName) {
        throw new Error('Phase name is required');
      }

      if (phase.startDate > phase.endDate) {
        throw new Error('Phase start date cannot be greater than end date');
      }

      const phaseConfig = buildPhaseConfig(phase);

      return {
        phaseIds: [...acc.phaseIds, phase.index],
        phaseConfigs: [...acc.phaseConfigs, phaseConfig],
      };
    },
    { phaseIds: [], phaseConfigs: [] }
  );
};

const prepareConfigForDeploy = ({ minContribution, maxContribution, dealSize }) => {
  if (!minContribution) {
    throw new Error('Min contribution is required');
  }

  if (!dealSize) {
    throw new Error('Deal size is required');
  }

  return {
    dealSize: ethers.utils.parseUnits(dealSize, 'mwei'),
    minContribution: ethers.utils.parseUnits(minContribution, 'mwei'),
    maxContribution: ethers.utils.parseUnits(maxContribution || '0', 'mwei'),
  };
};

function mapPhases(phases) {
  const mappedPhases = phases.map(
    ({
      accessLevels,
      minAccessLevel,
      minViewLevel,
      model,
      name,
      startTimestamp,
      endTimestamp,
      whitelist,
      cap,
      levelCaps,
      index,
      proRataCirculatingSupply,
      nftPrice,
    }) => ({
      accessLevels,
      minAccessLevel,
      minViewLevel,
      model,
      phaseName: name,
      startDate: new Date(startTimestamp),
      endDate: new Date(endTimestamp),
      expanded: false,
      cap,
      index,
      nftPrice,
      levels: levelCaps,
      proRataCirculatingSupply,
      whitelist: whitelist?.map(({ amount, wallet }) => ({
        address: wallet,
        personalCap: amount,
      })),
    })
  );

  return fixDecimalPlaces(mappedPhases);
}

function isValidPledgeStatus(deal, pool) {
  if (!deal.useOldPledge && pool?.status !== PledgeStatus.Freezed) {
    return false;
  }

  if (deal.useOldPledge && deal.statusId !== DealStatus.PledgeClosed) {
    return false;
  }

  return true;
}

function isValidPoolConfig(poolConfig) {
  return !!poolConfig.minContribution && +poolConfig.dealSize > 0;
}

function fixDecimalPlaces(phases, decimals = 0) {
  const fieldsToFix = ['cap', 'nftPrice', 'levels'];

  return phases.map((phase) => {
    const fixedPhase = {};

    fieldsToFix.forEach((field) => {
      if (!Number.isNaN(phase[field])) {
        fixedPhase[field] = Number(phase[field]).toFixed(decimals);
      }

      if (field === 'levels') {
        fixedPhase[field] = phase[field].map((level) => Number(level).toFixed(decimals));
      }
    });

    return { ...phase, ...fixedPhase };
  });
}

export {
  mapPhases,
  isValidPledgeStatus,
  isValidPoolConfig,
  buildPhaseConfig,
  prepareConfigForDeploy,
  preparePhasesForDeploy,
  prepareAccessLevels,
};
