import React from 'react';

import '../../../index.scss';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { useDeployContractContext } from 'features/deals/DealInfo/DeployContractTab/context';
import CustomSelect from 'components/select/custom-select';
import { AllocationModelOptions } from 'features/deals/DealInfo/DeployContractTab/constants';
import CustomInput from 'components/input/custom-input';
import NumberInput from 'components/input/number-input';
import InfoTooltip from 'components/tooltip/custom/InfoTooltip';
import Icon from 'components/svgIcon';
import CustomDateInput from 'components/input/date-input';

export default function PhaseSettingsAccordion({ phase, index }) {
  const {
    phaseDisplayState,
    setPhaseDisplayState,
    handlePhaseChange,
    errors,
    phaseValidState,
    isDirty,
  } = useDeployContractContext();

  const { sectionError } = phaseValidState;

  const onToggle = () => {
    const isExpanded = phaseDisplayState.accordion === 'phase-settings';

    setPhaseDisplayState({ ...phaseDisplayState, accordion: isExpanded ? '' : 'phase-settings' });
  };

  const isError = isDirty && sectionError === 'phase-settings';

  return (
    <Accordion defaultExpanded onChange={onToggle}>
      <AccordionSummary expandIcon={<Icon name="arrowDown" width={16} height={16} />}>
        <Icon name="iconSettingsGray" width={16} height={16} />
        <Typography>Phase Settings</Typography>
        {isError && <Icon name="error" width={14} height={14} />}
      </AccordionSummary>
      <AccordionDetails>
        <div className="accordion-content">
          <CustomInput
            label="Phase Name"
            value={phase.phaseName}
            onChange={(e) => handlePhaseChange('phaseName', e.target.value, index)}
          />
          <CustomDateInput
            label="Start Date"
            value={phase.startDate}
            onChange={(date) => handlePhaseChange('startDate', date, index)}
            error={errors[`${index}-startDate`]}
          />
          <CustomDateInput
            label="End Date"
            value={phase.endDate}
            onChange={(date) => handlePhaseChange('endDate', date, index)}
            error={errors[`${index}-endDate`]}
          />
          <CustomSelect
            label="Allocation Model"
            items={AllocationModelOptions}
            name="model"
            value={phase.model}
            onSelect={(name, value) => handlePhaseChange(name, value, index)}
          />
          <NumberInput
            label="Phase Cap"
            subLabel="(optional)"
            value={phase.cap}
            onChange={(e) => handlePhaseChange('cap', e.target.value, index)}
            decimalNumber="2"
            error={errors[`${index}-cap`]}
          />
          <NumberInput
            label="Nft Price"
            value={phase.nftPrice}
            onChange={(e) => handlePhaseChange('nftPrice', e.target.value, index)}
            decimalNumber="2"
            error={errors[`${index}-nftPrice`]}
            subLabel={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '4px',
                }}
              >
                <span>(optional)</span>
                <InfoTooltip
                  title="Add the nft price to ensure contributions are multiples of this value."
                  placement="right"
                />
              </div>
            }
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
