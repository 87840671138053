import React from 'react';
import NumberInput from 'components/input/number-input';
import RoundedButton from 'components/button/rounded-button';
import { usePledgeDistributeContext } from '../context';

const UpdatePledgeControls = () => {
  const { state, onUpdateTotalRelease, onToggleDistributeEvenly } = usePledgeDistributeContext();

  const { totalRelease, isDistributeEvenly, error } = state;

  return (
    <div className="claim-deal-check-modal__head margin-bottom-10">
      <div className="claim-deal-check-modal__header">
        <div className="claim-deal-check-modal__header-row">
          <div className="switch-wrapper">
            <NumberInput
              label="Distribute Amount:"
              value={totalRelease.distribute}
              onChange={onUpdateTotalRelease}
              decimalNumber="2"
            />
            <RoundedButton
              type="secondary"
              onClick={onToggleDistributeEvenly}
              disabled={isDistributeEvenly}
              className="distribute-btn"
            >
              Distribute
            </RoundedButton>
          </div>

          {error && <div className="claim-deal-check-modal__error">{error}</div>}
        </div>
      </div>
    </div>
  );
};

export default UpdatePledgeControls;
