import { buildAllowlistData } from 'features/deals/UserDealsTable/helpers';
import { useMemo } from 'react';
import useRemainingAllocationsQuery from './useRemainingAllocationsQuery';

const usePersonalCap = (deal, eligiblePhases) => {
  const { remainingAllocations, isLoading } = useRemainingAllocationsQuery(deal, {
    phaseIds: eligiblePhases.map((phase) => phase.index),
    allowListDatas: eligiblePhases.map((phase) => buildAllowlistData(phase)),
  });

  return useMemo(() => {
    if (isLoading || !remainingAllocations) return { deal, isLoading };

    const personalCap = remainingAllocations.reduce((acc, allocation) => {
      return acc + Number(allocation);
    }, 0);

    deal.personalCap = personalCap;

    return { deal, isLoading, remainingAllocations };
  }, [deal, isLoading, remainingAllocations]);
};

export default usePersonalCap;
