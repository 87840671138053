import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import RoundedAvatar from 'components/avatar/rounded-avatar';
import SvgIcon from 'components/svgIcon';
import RoundedButton from 'components/button/rounded-button';
import { deleteUserDeal, removeAnimateDeal, setUserDeal } from 'store/actions';
import './index.scss';
import { updateDeal, deleteDeal, archiveDeal, unarchiveDeal } from 'services/apiService';
import { useNotifications, useApiService } from 'hooks';
import { capitalize, getDealStatusClass, thousandSeparator } from 'utils/helpers';
import NameEditModal from 'components/NameEditModal';
import moment from 'moment';
import IconButton from 'components/button/icon-button';
import Tooltip from 'components/tooltip';
import { Box } from '@material-ui/core';

import useAdminDealQuery from 'hooks/useAdminDealQuery';
import DealInfoDesktop from '../DealInfo';
import ImageEditModal from './ImageEditModal';
import remote from './remote';
import usePledgeLockIcon from '../UserDealsTable/hooks/usePledgeLockIcon';
import usePledgePoolQuery from 'contracts/pledgeVault/hooks/usePledgePoolQuery';

const DraftDealRow = ({ deal: initDeal, showPledgeInfo }) => {
  const globalReducer = useSelector((state) => state.global);
  const dispatch = useDispatch();
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeInfoTab, setActiveInfoTab] = useState('info');
  const [overviewModalOpen, setOverviewModalOpen] = useState(false);
  const [highlightsModalOpen, setHighlightsModalOpen] = useState(false);
  const [basicMetricsModalOpen, setBasicMetricsModalOpen] = useState(false);
  const [blackDragonDealModalOpen, setBlackDragonDealModalOpen] = useState(false);
  const [raiseStatsModalOpen, setRaiseStatsModalOpen] = useState(false);
  const [partnersModalOpen, setPartnersModalOpen] = useState(false);
  const [vestingClaimInfoModalOpen, setVestingClaimInfoModalOpen] = useState(false);
  const [vestingBatchInfoModalOpen, setVestingBatchInfoModalOpen] = useState(false);
  const [teamModalOpen, setTeamModalOpen] = useState(false);
  const [projectsModalOpen, setProjectsModalOpen] = useState(false);
  const [adminOverviewModalOpen, setAdminOverviewModalOpen] = useState(false);
  const { animateDeals } = globalReducer;
  const [showSuccessNotification, showErrorNotification] = useNotifications();
  const [onUpdateDeal] = useApiService();
  const [imageEditModalOpen, setImageEditModalOpen] = useState(false);
  const [isTogglingHide, setIsTogglingHide] = useState(false);
  const [nameEditModalOpen, setNameEditModalOpen] = useState(false);

  const { deal, refetch: refetchDeal } = useAdminDealQuery(initDeal, { enabled: false });

  const { data: pledges, refetch: refetchPledges } = remote.useGetPledge(
    initDeal.id,
    activeInfoTab === 'pledge'
  );

  const { pool } = usePledgePoolQuery(deal.address, {
    enabled: false,
  });

  const pledgeLockIcon = usePledgeLockIcon(deal, pool);

  const onRefetch = async () => {
    await refetchDeal();
    await refetchPledges();
  };

  const toggleImageEditModal = (e) => {
    if (e) {
      e.preventDefault();
    }

    setImageEditModalOpen(!imageEditModalOpen);
  };

  const toggleDrop = () => {
    setShowDropdown(!showDropdown);
  };

  const onManage = (e) => {
    const disabledTags = ['input', 'button'];
    if (disabledTags.includes(e.target.tagName.toLowerCase())) return;
    toggleDrop();
  };

  const onHideToggle = async () => {
    setIsTogglingHide(true);
    const newUserHidden = !deal.userHidden;

    try {
      const response = await updateDeal(deal.id, { userHidden: newUserHidden });
      const updatedDeal = response.data;

      dispatch(setUserDeal({ ...deal, ...updatedDeal, id: updatedDeal.id.toString() }));
      showSuccessNotification(`${deal.name} ${newUserHidden ? 'hidden' : 'unhidden'} successfully`);
    } catch (error) {
      console.error(error);
      showErrorNotification(
        `Something went wrong while ${newUserHidden ? 'hiding' : 'unhiding'} ${deal.name}`
      );
    }
    setIsTogglingHide(false);
  };

  const getAnimateClass = (val) => {
    if (
      animateDeals &&
      animateDeals.fields &&
      deal.address === animateDeals.address &&
      (animateDeals?.fields.includes(val) || animateDeals?.fields.includes('allFields'))
    ) {
      return 'animate';
    }
    return val;
  };

  useEffect(() => {
    if (animateDeals && animateDeals.fields && deal.address === animateDeals.address) {
      setTimeout(() => {
        dispatch(removeAnimateDeal());
      }, 3000);
    }
  }, [animateDeals, deal, dispatch]);

  const toggleOverviewModal = () => {
    setOverviewModalOpen(!overviewModalOpen);
  };

  const toggleHighlightsModal = () => {
    setHighlightsModalOpen(!highlightsModalOpen);
  };

  const toggleBasicMetricsModal = () => {
    setBasicMetricsModalOpen(!basicMetricsModalOpen);
  };

  const toggleBlackDragonDealModal = () => {
    setBlackDragonDealModalOpen(!blackDragonDealModalOpen);
  };

  const toggleRaiseStatsModal = () => {
    setRaiseStatsModalOpen(!raiseStatsModalOpen);
  };

  const togglePartnersModal = () => {
    setPartnersModalOpen(!partnersModalOpen);
  };

  const toggleVestingClaimInfoModal = () => {
    setVestingClaimInfoModalOpen(!vestingClaimInfoModalOpen);
  };

  const toggleVestingBatchInfoModal = () => {
    setVestingBatchInfoModalOpen(!vestingBatchInfoModalOpen);
  };

  const toggleTeamModal = () => {
    setTeamModalOpen(!teamModalOpen);
  };

  const toggleAdminOverviewModal = () => {
    setAdminOverviewModalOpen(!adminOverviewModalOpen);
  };

  const toggleProjectsModal = () => {
    setProjectsModalOpen(!projectsModalOpen);
  };

  const onNameChangeSubmit = async (name) => {
    try {
      const response = await updateDeal(deal.id, { name });
      const changedData = JSON.parse(response.config.data);
      const newDeal = JSON.parse(JSON.stringify(deal));
      newDeal.name = changedData.name;
      dispatch(setUserDeal(newDeal));
      setNameEditModalOpen(false);
      showSuccessNotification('Updated successfully');
    } catch (err) {
      console.error(err);
      showErrorNotification('Something went wrong');
    }
  };

  const handleEraseDraft = async () => {
    try {
      await deleteDeal(deal.id);
      dispatch(deleteUserDeal(deal.id));
      showSuccessNotification('Draft deleted successfully');
    } catch {
      showErrorNotification('Something went wrong while deleting draft');
    }
  };

  const handleArchive = async () => {
    try {
      await archiveDeal(deal.id);
      const updatedDeal = JSON.parse(JSON.stringify(deal));
      updatedDeal.isArchived = true;
      dispatch(setUserDeal(updatedDeal));
      showSuccessNotification('Deal archived successfully');
    } catch {
      showErrorNotification('Something went wrong');
    }
  };

  const handleUnarchive = async () => {
    try {
      await unarchiveDeal(deal.id);
      const updatedDeal = JSON.parse(JSON.stringify(deal));
      updatedDeal.isArchived = false;
      dispatch(setUserDeal(updatedDeal));
      showSuccessNotification('Deal unarchived successfully');
    } catch {
      showErrorNotification('Something went wrong');
    }
  };

  return (
    <>
      {imageEditModalOpen && (
        <ImageEditModal
          deal={deal}
          onOk={onUpdateDeal}
          onCancel={toggleImageEditModal}
          defaultValue={deal.imageUrl}
        />
      )}
      <NameEditModal
        name={deal?.name}
        open={nameEditModalOpen}
        onOk={onNameChangeSubmit}
        onCancel={() => setNameEditModalOpen(false)}
      />
      <div className="deal-holder deal-holder-draft d-flex full-width">
        <div className="deal-row-top" onClick={onManage}>
          <div
            className={`deal__field deal__field-avatar vertical-center ${getAnimateClass(
              'imageUrl'
            )}`}
          >
            <RoundedAvatar src={deal.imageUrl} />
            <RoundedButton type="default" onClick={toggleImageEditModal} className="image-edit-btn">
              Edit
            </RoundedButton>
          </div>
          <div
            className={`deal__field deal__field-name deal-name vertical-center ${getAnimateClass(
              'name'
            )}`}
          >
            <div>
              <div className="deal-name">
                <span>{deal.name}</span>
                <RoundedButton
                  type="default"
                  onClick={() => setNameEditModalOpen(true)}
                  className="name-edit-btn"
                >
                  Edit
                </RoundedButton>
              </div>
            </div>
          </div>
          <div
            className={`deal__field deal__field-status deal__field-status--${getDealStatusClass(
              deal.status
            )} vertical-center ${getAnimateClass('status')} deal-draft-date-admin`}
          >
            <span className="deal__field-status__icon">
              <SvgIcon name="dot" />
            </span>
            <span className="deal__field-status__name">
              {deal.statusId === 6 || deal.statusId === 0 ? 'Draft' : capitalize(deal.status)}
            </span>
          </div>
          {showPledgeInfo ? (
            <div
              className={`deal__field deal__field-pledge-amount vertical-center ${getAnimateClass(
                'pledgeAmount'
              )}`}
            >
              <div>
                <span>{pledgeLockIcon}</span>
              </div>
            </div>
          ) : null}
          <div className="deal__field deal__field-status deal__field-status vertical-center deal-status-admin">
            <span className="deal__field-status__name">
              {moment(deal.dateCreated || deal.createdAt).format('D-MMM-YYYY')}
            </span>
          </div>
          {showPledgeInfo ? (
            <>
              <div
                className={`deal__field deal__field-pledge-amount vertical-center ${getAnimateClass(
                  'pledgeAmount'
                )}`}
              >
                <div>
                  <span>$ {thousandSeparator(deal.pledgeAmount || 0)}</span>
                </div>
              </div>
              <div
                className={`deal__field deal__field-pledge-amount vertical-center ${getAnimateClass(
                  'pledgeAcceptedAmount'
                )} deal-pledge-amount`}
              >
                <div>
                  <span>
                    {deal.pledgeAcceptedAmount
                      ? `$${thousandSeparator(deal.pledgeAcceptedAmount || 0)}`
                      : 'N/A'}
                  </span>
                </div>
              </div>
              <div className="deal__field deal__field-pledge-amount vertical-center pledge-type-icon-container">
                <span>
                  {deal.useOldPledge ? (
                    <span className="pledge-mode-offchain-icon">
                      <SvgIcon name="dot" />
                      <span>Offchain</span>
                    </span>
                  ) : (
                    <span className="deal__field-status--Opened">
                      <SvgIcon name="dot" />
                      <span>Onchain</span>
                    </span>
                  )}
                </span>
              </div>
            </>
          ) : null}
          {deal.statusId !== 6 && (
            <>
              <Box display="flex" alignItems="center">
                <Tooltip
                  title={deal.userHidden ? 'Unhide deal from Users.' : 'Hide deal from Users.'}
                >
                  <div>
                    <IconButton
                      icon={deal.userHidden ? 'iconVisibilityOff' : 'iconVisibility'}
                      onClick={onHideToggle}
                      disabled={isTogglingHide}
                    />
                  </div>
                </Tooltip>
              </Box>
              <Box display="flex" alignItems="center">
                <Tooltip title={deal.isArchived ? 'Unarchive pledge' : 'Archive pledge'}>
                  <div>
                    <IconButton
                      onClick={deal.isArchived ? handleUnarchive : handleArchive}
                      disabled={isTogglingHide}
                      icon={deal.isArchived ? 'iconUnarchive' : 'iconArchive'}
                    />
                  </div>
                </Tooltip>
              </Box>
            </>
          )}
          {deal.statusId === 6 && (
            <Box display="flex" alignItems="center">
              <Tooltip title="Permanently erase draft">
                <div>
                  <IconButton icon="iconErase" onClick={() => handleEraseDraft(deal.id)} />
                </div>
              </Tooltip>
            </Box>
          )}
        </div>

        <div className={`deal-info-mobile${showDropdown ? ' show' : ''}`}>
          <div className="deal-info-mobile-actions">
            <RoundedButton type="primary">Manage</RoundedButton>
          </div>
        </div>
        {showDropdown ? (
          <DealInfoDesktop
            showDropdown={showDropdown}
            activeInfoTab={activeInfoTab}
            setActiveInfoTab={setActiveInfoTab}
            overviewModalOpen={overviewModalOpen}
            toggleOverviewModal={toggleOverviewModal}
            highlightsModalOpen={highlightsModalOpen}
            toggleHighlightsModal={toggleHighlightsModal}
            basicMetricsModalOpen={basicMetricsModalOpen}
            toggleBasicMetricsModal={toggleBasicMetricsModal}
            blackDragonDealModalOpen={blackDragonDealModalOpen}
            toggleBlackDragonDealModal={toggleBlackDragonDealModal}
            raiseStatsModalOpen={raiseStatsModalOpen}
            toggleRaiseStatsModal={toggleRaiseStatsModal}
            partnersModalOpen={partnersModalOpen}
            togglePartnersModal={togglePartnersModal}
            vestingClaimInfoModalOpen={vestingClaimInfoModalOpen}
            toggleVestingClaimInfoModal={toggleVestingClaimInfoModal}
            teamModalOpen={teamModalOpen}
            toggleTeamModal={toggleTeamModal}
            projectsModalOpen={projectsModalOpen}
            adminOverviewModalOpen={adminOverviewModalOpen}
            toggleAdminOverviewModal={toggleAdminOverviewModal}
            toggleProjectsModal={toggleProjectsModal}
            vestingBatchInfoModalOpen={vestingBatchInfoModalOpen}
            toggleVestingBatchInfoModal={toggleVestingBatchInfoModal}
            deal={deal}
            pledges={pledges}
            onRefetch={onRefetch}
          />
        ) : null}
      </div>
    </>
  );
};

DraftDealRow.propTypes = {
  deal: PropTypes.shape(),
};

DraftDealRow.defaultProps = {
  deal: {},
};

export default React.memo(DraftDealRow);
