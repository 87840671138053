import React, { createContext, useContext, useEffect, useState } from 'react';
import useAdminReleasePledgesQuery from '../pages/PledgeRelease/hooks/useAdminReleasePledgesQuery';

export const ReleasePledgePage = {
  DISTRIBUTE: 'distribute',
  PREVIEW: 'preview',
  RELEASE: 'release',
};

const ReleasePledgeContext = createContext();

export const useReleasePledgeContext = () => useContext(ReleasePledgeContext);

export const ReleasePledgeProvider = ({ deal, children, onClose }) => {
  const [activePage, setActivePage] = useState(ReleasePledgePage.DISTRIBUTE);

  const [pledges, setPledges] = useState([]);
  const [transactions, setTransactions] = useState([]);

  const { pledges: pledgesToRelease } = useAdminReleasePledgesQuery(deal.id);

  useEffect(() => {
    if (!pledgesToRelease || Object.keys(pledgesToRelease).length === 0 || transactions.length) {
      return;
    }

    setTransactions(pledgesToRelease);
    setActivePage(ReleasePledgePage.PREVIEW);
  }, [pledges, pledgesToRelease, transactions.length]);

  return (
    <ReleasePledgeContext.Provider
      value={{
        setTransactions,
        transactions,
        setActivePage,
        activePage,
        setPledges,
        pledges,
        onClose,
        deal,
      }}
    >
      {children}
    </ReleasePledgeContext.Provider>
  );
};
