import { XDAI_CHAIN_ID } from 'constants/config';
import buildCall from 'contracts/calls';
import { decodeTokenInfo } from 'models/common/decoders';
import { CALL_TYPE } from 'contracts/calls/constants';
import { formatClaimAmount } from 'models/claimerModel';
import { isChainMulticallSupported } from 'models/common/helpers';
import { groupBy } from 'utils';
import { isAddress } from 'ethers/lib/utils';
import { getClaimerData } from 'contracts/poolContract';

export function prepareClaimers(deal, walletAddress) {
  const claimers = deal.claimers || [];

  return claimers.map((claimer) => {
    if (
      !isChainMulticallSupported(claimer.chainId) ||
      claimer.tokens.find((token) => !isChainMulticallSupported(token.chainId))
    ) {
      return { ...claimer, claimableAmountCalls: [], notMulticallSupported: true };
    }

    if (!claimer.tokens.length && claimer.chainId !== +XDAI_CHAIN_ID) {
      return { ...claimer, claimableAmountCalls: [], token: null };
    }

    // prepare contract calls for claim amounts and token info
    const { claimableAmountCalls, tokens } = claimer.tokens.reduce(
      (acc, token) => {
        if (isAddress(token.address)) {
          const claimableAmountCall = prepareClaimerAmountCalls({
            dealContributionAmount: deal.contributedAmount,
            claimerAddress: claimer.address,
            tokenAddress: token.address,
            walletAddress,
            chainId: token.chainId,
          });

          acc.claimableAmountCalls.push(...claimableAmountCall);
          acc.tokens.push(token);
        }

        return acc;
      },
      { claimableAmountCalls: [], tokens: [] }
    );

    return {
      ...claimer,
      claimableAmountCalls,
      tokens,
    };
  });
}

export function prepareClaimerAmountCalls({
  claimerAddress,
  tokenAddress,
  walletAddress,
  chainId,
}) {
  const claimAmountCall = buildCall(CALL_TYPE.CLAIM_AMOUNT)(claimerAddress, chainId, [
    walletAddress,
  ]);

  const tokenCalls = buildCall(CALL_TYPE.TOKEN_INFO)(tokenAddress, chainId);

  return [claimAmountCall, ...tokenCalls];
}

export async function mergeClaimers({ claimers, multicallResults, walletAddress }) {
  const { prefetchedClaimers = [], claimersToFetch = [] } = groupBy(claimers, (claimer) =>
    claimer.notMulticallSupported ? 'claimersToFetch' : 'prefetchedClaimers'
  );

  const fetchedClaimersResult = await getClaimerData(claimersToFetch, walletAddress);

  const prefetchedClaimersResult = mergePrefetchedClaimers(prefetchedClaimers, multicallResults);

  return [...fetchedClaimersResult, ...prefetchedClaimersResult];
}

export function mergePrefetchedClaimers(claimers, multicallResults) {
  let resultOffset = 0;

  return claimers.map((claimer) => {
    delete claimer.claimableAmountCalls;

    const updatedTokens = claimer.tokens.map(({ address }) => {
      const claimAmountIdx = resultOffset;

      const rawClaimAmount = multicallResults[claimAmountIdx]?.returnData?.[0] || '0';
      const tokenInfo = decodeTokenInfo(multicallResults, claimAmountIdx + 1);

      resultOffset += 4; // skip by token calls count(value, name, symbol, decimals)

      return {
        claimAmount: formatClaimAmount(rawClaimAmount, tokenInfo),
        token: { address, ...tokenInfo },
      };
    });

    return {
      ...claimer,
      claimableAmounts: updatedTokens,
    };
  });
}
