import React from 'react';
import NumberInput from 'components/input/number-input';
import { formatDate, shortenAddress } from 'utils';
import { useMediaQuery } from '@material-ui/core';
import { usePledgeDistributeContext } from '../context';
import { useReleasePledgeContext } from '../../../context';
import { StateField } from '../constants';

const UpdatePledgeTable = () => {
  const { pledges } = useReleasePledgeContext();
  const { onApplySinglePledge, updateState } = usePledgeDistributeContext();

  const isMobile = useMediaQuery('(max-width: 767px)');

  return (
    <div className="claim-deal-check-modal__table">
      <div className="claim-deal-check-modal__table-head">
        <span>Date</span>
        <span>Wallet</span>
        <span>Pledge Amount</span>
      </div>
      <div className="claim-deal-check-modal__table-body">
        {pledges.map((pledge, index) => (
          <div key={pledge.wallet}>
            <span>{formatDate(pledge.updatedAt)}</span>
            <span>{isMobile ? shortenAddress(pledge.wallet) : pledge.wallet}</span>
            <NumberInput
              value={pledge.acceptedAmount}
              onChange={(e) => onApplySinglePledge(index, e)}
              decimalNumber="2"
              isAllowed={(values) => {
                const { floatValue } = values;

                const isAllowed = !floatValue || floatValue < +pledge.amount;

                if (!isAllowed) {
                  updateState(StateField.Error, 'Cannot be greater than pledged amount');
                }

                return isAllowed;
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default UpdatePledgeTable;
