import React from 'react';
import TransactionListItem from './components/TransactionListItem';
import { useReleasePledgeContext } from '../../context';

function PledgeRelease() {
  const { deal, transactions } = useReleasePledgeContext();

  return (
    <div className="pledge-release-list">
      {transactions.slice(1).map((transaction, index) => (
        <TransactionListItem key={index} deal={deal} transaction={transaction} index={index} />
      ))}
    </div>
  );
}

export default PledgeRelease;
