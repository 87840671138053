import { getLockDetails } from './userModel';

function getUserLevel(lockedBdtBalance, isLockedFor6Months) {
  if (!isLockedFor6Months) return 0;

  lockedBdtBalance = parseFloat(lockedBdtBalance);

  if (lockedBdtBalance >= 0 && lockedBdtBalance < 250) return 0;
  if (lockedBdtBalance >= 250 && lockedBdtBalance < 1000) return 1;
  if (lockedBdtBalance >= 1000 && lockedBdtBalance < 5000) return 2;
  if (lockedBdtBalance >= 5000 && lockedBdtBalance < 10000) return 3;
  if (lockedBdtBalance >= 10000) return 4;

  return -1;
}

export const getUserLevelLabel = (accessLevel) => {
  switch (accessLevel) {
    case 0:
      return 'Level 0';
    case 1:
      return 'Level 1';
    case 2:
      return 'Level 2';
    case 3:
      return 'Level 3';
    case 4:
      return 'Whitelist';
    default:
      throw new Error(`Invalid allocation model ${accessLevel}`);
  }
};

export function getUserLevels(lockInfo, kycStatus) {
  const { isLockedFor6Months, relockMessage } = getLockDetails(lockInfo);

  const isKycVerified = ['verification_approved', 'approved'].includes(kycStatus);
  const hasReleaseTimePassed = lockInfo.releaseTime < Date.now() / 1000;

  return {
    userAccessLevel: isKycVerified ? getUserLevel(lockInfo.amount, isLockedFor6Months) : -1,
    userViewLevel:
      isKycVerified && !hasReleaseTimePassed ? getUserLevel(lockInfo.amount, true) : -1,
    relockMessage,
  };
}
