import useFinalizePledgeAndConfigPool from 'contracts/poolManager/hooks/useFinalizePledge';
import { useNotifications } from 'hooks';
import useWithCorrectNetwork from 'hooks/useWithCorrectNetwork';
import { useQueryClient } from 'react-query';

function useDealLivePromotion(deal) {
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const queryClient = useQueryClient();

  const onError = () => {
    showErrorNotification('Something went wrong while promoting deal');
  };

  const onSuccess = () => {
    queryClient.invalidateQueries(['deal', deal.id]);
    queryClient.invalidateQueries(['admin-deal', deal.id]);

    showSuccessNotification('Deal promoted successfully');
  };

  const { finalizePledgeAndConfigPool, isLoading: isFinalizingPledge } =
    useFinalizePledgeAndConfigPool(deal.address, {
      onSuccess,
      onError,
    });

  const checkedPromoteDeal = useWithCorrectNetwork(finalizePledgeAndConfigPool);

  return {
    promoteDeal: checkedPromoteDeal,
    isLoading: isFinalizingPledge,
  };
}

export default useDealLivePromotion;
