import React from 'react';
import { PledgeStatus } from 'contracts/pledgeVault/constants';
import SvgIcon from 'components/svgIcon';
import { DealStatus } from 'models/constants';

export default function usePledgeLockIcon(deal, pool) {
  let pledgeTypeState = {
    icon: 'iconLockPledge',
    text: 'Locked',
  };

  const isValidOldPledgeStatus = [
    DealStatus.Live,
    DealStatus.PledgeOpened,
    DealStatus.PledgeClosed,
  ].includes(deal.statusId);

  if ((deal.useOldPledge && isValidOldPledgeStatus) || pool?.status === PledgeStatus.OpenUnlocked) {
    pledgeTypeState = {
      icon: 'iconUnlockPledge',
      text: 'Unlocked',
    };
  }

  return (
    <div className="d-flex align-items-center">
      <SvgIcon name={pledgeTypeState.icon} />
      <span>{pledgeTypeState.text}</span>
    </div>
  );
}
