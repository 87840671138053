import React, { useState } from 'react';
import RoundedButton from 'components/button/rounded-button';
import PledgeStatus from 'contracts/pledgeVault/constants';
import useCreatePool from 'contracts/poolManager/hooks/useCreatePool';
import './index.scss';
import { useNotifications } from 'hooks';
import { DealStatus } from 'models/constants';
import { useQueryClient } from 'react-query';
import useOldClosePledge from './hooks/useOldClosePledge';
import useOldOpenPledge from './hooks/useOldOpenPledge';
import { OldPledgeStatusOptions } from '../../constants';
import PledgeStatusSelect from '../../PledgeStatusSelect';

const OldPledgeControl = ({ deal }) => {
  const [showSuccessNotification] = useNotifications();

  const initialStatus =
    deal.statusId === DealStatus.PledgeOpened ? PledgeStatus.Open : PledgeStatus.Closed;

  const [selectedStatus, setSelectedStatus] = useState(initialStatus);

  const queryClient = useQueryClient();

  const { createPool, isLoading: isCreateLoading } = useCreatePool(deal.id, {
    onSuccess: () => {
      showSuccessNotification('Pledge opened');

      queryClient.invalidateQueries(['admin-deal', deal.id]);
      queryClient.invalidateQueries(['deal', deal.id]);
    },
  });

  const { mutate: openPledge, isLoading: isOpenLoading } = useOldOpenPledge(deal.id);
  const { mutate: closePledge, isLoading: isCloseLoading } = useOldClosePledge(deal.id);

  const onChangeStatus = (status) => {
    setSelectedStatus(status);

    switch (status) {
      case PledgeStatus.Closed:
        closePledge();
        break;
      case PledgeStatus.Open:
        openPledge();
        break;
      default:
        setSelectedStatus(deal.statusId);
    }
  };

  const isLoading = isOpenLoading || isCloseLoading || isCreateLoading;

  return (
    <div className="old-pledge-control">
      {!deal.address ? (
        <div className="pledge-control-actions old-pledge-control-actions">
          <RoundedButton
            type="secondary"
            onClick={() => createPool(PledgeStatus.Freezed)}
            disabled={deal.statusId === 7 || isLoading}
          >
            Open
          </RoundedButton>
        </div>
      ) : (
        <div className="pool-actions">
          <PledgeStatusSelect
            value={selectedStatus}
            options={OldPledgeStatusOptions}
            onChange={onChangeStatus}
            disabled={isLoading}
            initialStatus={initialStatus}
          />
        </div>
      )}
    </div>
  );
};

export default OldPledgeControl;
