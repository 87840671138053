import React from 'react';
import RoundedButton from 'components/button/rounded-button';
import { useNotifications } from 'hooks';
import { useQueryClient } from 'react-query';
import useUpdatePoolPhase from 'contracts/poolManager/hooks/useUpdatePoolPhase';
import Tooltip from 'components/tooltip';
import { useDeployContractContext } from '../context';
import usePhaseMerkleRoot from '../../hooks/usePhaseMerkleRoot';
import { buildPhaseConfig } from '../helpers';

function UpdatePhaseAction({ phase }) {
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const { deal, phaseIdsToUpdate, hasErrors, poolConfig, phaseValidState, triggerDirty } =
    useDeployContractContext();

  const queryClient = useQueryClient();

  const { updatePoolPhase, isLoading: isUpdatingPhase } = useUpdatePoolPhase(deal.address, {
    onSuccess: () => {
      queryClient.invalidateQueries(['deal', deal.id]);
      queryClient.invalidateQueries(['admin-deal', deal.id]);

      showSuccessNotification('Phase updated successfully');
    },
  });

  const { insertMerkleRoots } = usePhaseMerkleRoot(deal.id, {
    onSuccess: (result) => {
      result.phases.forEach(async (phase) => {
        const phaseConfig = buildPhaseConfig(phase);

        await updatePoolPhase({ phaseId: phase.index, phaseConfig });
      });
    },
    onError: () => {
      showErrorNotification('Error while creating phase');
    },
  });

  const { isValid, errorMessage } = phaseValidState;

  const isDisabled =
    isUpdatingPhase || !phaseIdsToUpdate.includes(phase.index) || !isValid || hasErrors;

  return (
    <Tooltip title={errorMessage} onMouseEnter={triggerDirty}>
      <div>
        <RoundedButton
          onClick={(e) => {
            e.stopPropagation();
            insertMerkleRoots({ phases: [phase], poolConfig });
          }}
          disabled={isDisabled}
          type="secondary"
        >
          Update Phase
        </RoundedButton>
      </div>
    </Tooltip>
  );
}

export default UpdatePhaseAction;
