import { useQuery } from 'react-query';
import { getAdminDealReleasePledges } from 'services/apiService';

function useAdminReleasePledgesQuery(dealId, options = {}) {
  const { data, isLoading, error } = useQuery(
    ['admin-release-pledges', dealId],
    () => getAdminDealReleasePledges(dealId),
    {
      ...options,
    }
  );

  return { pledges: data, isLoading, error };
}

export default useAdminReleasePledgesQuery;
