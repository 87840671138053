import PoolStatus from 'contracts/poolManager/constants';

const ValidStatusTransitions = {
  [PoolStatus.Active]: [PoolStatus.Paused, PoolStatus.Canceled, PoolStatus.Closed],
  [PoolStatus.Paused]: [PoolStatus.Active, PoolStatus.Canceled, PoolStatus.Closed],
  [PoolStatus.Closed]: [PoolStatus.Canceled],
};

export const isActionEnabled = (poolStatus, status) => {
  const validTransitions = ValidStatusTransitions[poolStatus];

  return validTransitions && validTransitions.includes(status);
};
