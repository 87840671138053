import React, { useCallback, useMemo } from 'react';
import { ethers, utils } from 'ethers';
import SvgIcon from 'components/svgIcon';

const newAddress = {
  address: '',
  personalCap: 0,
  errorMessage: 'Invalid Address',
};

function Whitelist({
  whitelist,
  showAmount,
  isWhitelistInputDisabled,
  onChange,
  whitelistId,
  onlyBasicInfo,
  onValidityChange,
  disabled,
  error,
  invalidAddresses,
}) {
  const getErrorMessage = useCallback(
    ({ address, personalCap }, isDuplicate) => {
      if (!utils.isAddress(address)) return 'Invalid Address';
      if (isDuplicate) return 'Duplicate address';
      if (showAmount && personalCap === 0) return 'Must be greater than 0';

      return null;
    },
    [showAmount]
  );

  const onValidatedAddressChange = (whitelists) => {
    const validatedWhitelists = whitelists.map((whitelist) => {
      if (whitelist.errorMessage) {
        return whitelist;
      }

      return {
        ...whitelist,
        address: ethers.utils.getAddress(whitelist.address),
      };
    });

    onChange(validatedWhitelists);
  };

  const validatedWhitelist = useMemo(() => {
    if (!whitelist?.length) {
      return [{ ...newAddress, personalCap: 0 }];
    }

    const newValue = whitelist?.reduce((accum, { address, personalCap }) => {
      const isDuplicate = accum.some((item) => address === item.address);

      let errorMessage = getErrorMessage({ address, personalCap }, isDuplicate);

      if (showAmount && invalidAddresses?.includes(address) && error) {
        errorMessage = error;
      }

      return [
        ...accum,
        {
          address,
          errorMessage,
          personalCap,
        },
      ];
    }, []);

    onValidityChange(!newValue.some(({ errorMessage }) => !!errorMessage));

    return newValue;
  }, [whitelist, onValidityChange, getErrorMessage, showAmount, invalidAddresses, error]);

  const handleAddressChange = (addressIndex, address) => {
    if (disabled) return;
    let newValue = [];
    const addresses = address.split(' ');
    if (addresses.length === 1 && !whitelist.length) {
      newValue = [
        {
          ...newAddress,
          address,
          errorMessage: getErrorMessage({ address }, false),
        },
      ];
    } else if (addresses.length > 1) {
      newValue = [
        ...whitelist,
        ...addresses.map((addr) => ({
          address: addr,
          personalCap: 0,
          errorMessage: getErrorMessage({ address: addr }, false),
        })),
      ];
    } else {
      newValue = whitelist.map((item, i) => {
        if (i === addressIndex)
          return { ...item, address, errorMessage: getErrorMessage({ address }) };
        return item;
      });
    }
    onValidatedAddressChange(newValue.filter((x) => !!x.address));
  };

  const handlePersonalCapChange = (addressIndex, personalCap) => {
    if (disabled) return;
    const parsedPersonalCap = +personalCap.split('$')[1];
    if (Number.isNaN(parsedPersonalCap)) return;
    if (!whitelist?.length) {
      onValidatedAddressChange([
        { personalCap: parsedPersonalCap, address: '', errorMessage: getErrorMessage({}) },
      ]);
      return;
    }
    const updatedWhitelist = whitelist.map((item, i) => {
      if (i === addressIndex) return { ...item, personalCap: parsedPersonalCap };
      return item;
    });
    onValidatedAddressChange(updatedWhitelist);
  };

  const handleKeyPress = (addressIndex, { key, target }) => {
    if (key === 'Enter') {
      if (target.value === '') return;
      if (addressIndex !== whitelist.length - 1) {
        const nextItemRef = document.getElementsByClassName(`whitelist__address-${whitelistId}`)[
          addressIndex + 1
        ];

        // eslint-disable-next-line no-unused-expressions
        nextItemRef?.focus();
        return;
      }
      onValidatedAddressChange([...whitelist, { ...newAddress }]);
      setTimeout(() => {
        const lastItemRef = document.getElementById(`whitelist__last-address-${whitelistId}`);
        return lastItemRef?.focus();
      }, 0);
      return;
    }
    if (key === 'Backspace' && target.value === '') {
      onValidatedAddressChange(whitelist.filter((_, i) => i !== addressIndex));
      setTimeout(() => {
        const itemToFocusRef = document.getElementsByClassName(`whitelist__address-${whitelistId}`)[
          addressIndex ? addressIndex - 1 : 0
        ];
        return itemToFocusRef?.focus();
      }, 0);
    }
    if (key === 'ArrowUp') {
      const prevItemRef = document.getElementsByClassName(`whitelist__address-${whitelistId}`)[
        addressIndex - 1
      ];
      // eslint-disable-next-line no-unused-expressions
      prevItemRef?.focus();
    }
    if (key === 'ArrowDown') {
      const nextItemRef = document.getElementsByClassName(`whitelist__address-${whitelistId}`)[
        addressIndex + 1
      ];

      // eslint-disable-next-line no-unused-expressions
      nextItemRef?.focus();
    }
  };

  return (
    <div className="deal-field whitelist">
      {validatedWhitelist.map(({ address, personalCap, errorMessage }, i) => (
        <div className="whitelist__row" key={i}>
          <div className="whitelist__flex-container">
            <label className="whitelist__position">{i + 1}.</label>
            <input
              id={
                i + 1 === whitelist?.length ? `whitelist__last-address-${whitelistId}` : undefined
              }
              onChange={(e) => handleAddressChange(i, e.target.value)}
              onKeyDown={(e) => handleKeyPress(i, e)}
              className={`whitelist__address whitelist__address-${whitelistId}`}
              value={address}
              autoComplete="off"
              spellCheck={false}
              disabled={isWhitelistInputDisabled}
            />
          </div>
          {!onlyBasicInfo && showAmount && (
            <input
              onChange={(e) => handlePersonalCapChange(i, e.target.value)}
              className="whitelist__personal-cap"
              value={`$${personalCap}`}
              disabled={isWhitelistInputDisabled}
            />
          )}
          <div className="whitelist__flex-container">
            {!onlyBasicInfo && (
              <span
                className={`whitelist__message ${
                  errorMessage ? 'whitelist_message_error' : 'whitelist_message_success'
                }`}
              >
                {errorMessage || 'Success'}
              </span>
            )}
            <SvgIcon className="item-status-icon" name={errorMessage ? 'error' : 'success'} />
          </div>
        </div>
      ))}
    </div>
  );
}

export default Whitelist;
