import React from 'react';
import usePledgePoolQuery from 'contracts/pledgeVault/hooks/usePledgePoolQuery';
import PledgeStatus from 'contracts/pledgeVault/constants';
import { Divider } from '@material-ui/core';
import PledgeTxList from './PledgeTxList';
import PledgeControl from './PledgeControl';
import PledgeStats from './PledgeStats';
import PledgeAnalyticsModal from './PledgeAnalyticsModal';
import './ReleasePledges/ReleasePledgesPopoup/index.scss';
import UpdatePledgesButton from './ReleasePledges/ReleasePledgesButton';
import './index.scss';

const PledgeTab = ({ deal, pledges, onRefetch }) => {
  const { pool } = usePledgePoolQuery(deal.address);

  return (
    <div className="content pledge-content">
      <div className="content-row pledge-content-row" style={{ height: '100%' }}>
        <div
          style={{ maxWidth: '200px' }}
          className="col col-4 pledge-left transparent p-0 pledge-left-side"
        >
          <PledgeControl deal={deal} />
          <PledgeStats pledges={pledges} deal={deal} />
        </div>

        <div className="col col-8-pledge" style={{ maxWidth: '100%' }}>
          <div className="pledge-transactions-top">
            <div className="pledge-transactions-title-holder">
              <h3>List of pledges</h3>
              <div className="pledge-transactions-top-actions">
                <PledgeAnalyticsModal deal={deal} />
                {pool?.status === PledgeStatus.Freezed && !!pledges.length ? (
                  <UpdatePledgesButton
                    deal={deal}
                    pool={pool}
                    pledges={pledges}
                    onRefetch={onRefetch}
                  />
                ) : null}
              </div>
            </div>
          </div>
          <Divider />
          <PledgeTxList deal={deal} pledges={pledges} />
        </div>
      </div>
    </div>
  );
};
export default PledgeTab;
