import React, { useState, useEffect } from 'react';
import RoundedButton from 'components/button/rounded-button';
import NumberInput from 'components/input/number-input';
import './index.scss';
import { roundNumberToDecimals } from 'utils/helpers';
import { Dialog, Divider } from '@material-ui/core';
import { USDT_ADDRESS } from 'constants/config';
import useERC20Balance from 'contracts/erc20/useERC20Balance';
import RoundedAvatar from 'components/avatar/rounded-avatar';
import CustomSlider from 'components/progress-bar/custom-slider';

const PledgeModal = ({ deal, pledgeMutation, isOpen, onCancel, defaultValue }) => {
  const [data, setData] = useState({
    amount: defaultValue ? roundNumberToDecimals(Number(defaultValue), 0) : '',
  });
  const [errorMessage, setErrorMessage] = useState('');

  const { pledge, isLoading } = pledgeMutation;

  const { balance } = useERC20Balance(USDT_ADDRESS);

  const getErrorMessage = () => {
    if (errorMessage === 'min') return 'Min. = 250';

    if (errorMessage === 'max') return `Max. = ${balance}`;
    return '';
  };

  const onChangeData = (name, value) => {
    setData({
      ...data,
      [name]: +value,
    });
  };

  useEffect(() => {
    if (Number(data.amount) < 1 && Number(data.amount) !== 0) {
      setErrorMessage('min');
    } else if (Number(data.amount) > balance) {
      setErrorMessage('max');
    } else {
      setErrorMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onConfirm = async () => {
    await pledge(data.amount);
  };

  const handleSliderChange = (_, value) => {
    onChangeData('amount', value);
  };

  const handleMaxClick = () => {
    onChangeData('amount', balance);
  };

  return (
    <Dialog
      className="pledge-action-modal-overlay"
      open={isOpen}
      onClose={onCancel}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="modal-container">
        <div className="pledge-modal-header">
          <div>Pledge Amount</div>
        </div>
        <div className="pledge-modal-divider-container">
          <Divider />
        </div>
        <div className="user-info">
          <div className="user-avatar">
            <RoundedAvatar src={deal.imageUrl} />
          </div>
          <div className="user-name">{deal.name}</div>
        </div>
        <div className="pledge-modal-slider-container">
          <CustomSlider
            min={0}
            max={+balance}
            value={data.amount}
            disabled={isLoading}
            onChange={handleSliderChange}
          />
          <div className="pledge-modal-amount-input">
            <NumberInput
              name="amount"
              value={data.amount.toString()}
              onChange={(e) => onChangeData(e.target.name, e.target.value)}
              error={getErrorMessage()}
              decimalNumber="0"
              disabled={isLoading}
            />
            <RoundedButton className="pledge-modal-max-button" onClick={handleMaxClick}>
              MAX
            </RoundedButton>
            <span>USDT</span>
          </div>
        </div>
        <div className="pledge-modal-divider-container">
          <Divider />
        </div>
        <div className="button-container">
          <RoundedButton disabled={isLoading} onClick={onCancel}>
            Cancel
          </RoundedButton>
          <RoundedButton
            type="secondary"
            disabled={isLoading || data.amount <= 0 || !!errorMessage}
            onClick={onConfirm}
          >
            Confirm
          </RoundedButton>
        </div>
      </div>
    </Dialog>
  );
};

export default PledgeModal;
