import PledgeStatus from 'contracts/pledgeVault/constants';

export const PledgeLabel = {
  [PledgeStatus.NotActive]: 'Not Active',
  [PledgeStatus.Open]: 'Open',
  [PledgeStatus.OpenUnlocked]: 'Open',
  [PledgeStatus.OpenLocked]: 'Open',
  [PledgeStatus.Freezed]: 'Freezed',
  [PledgeStatus.Canceled]: 'Canceled',
  [PledgeStatus.Finalized]: 'Finalized',
};

export const PledgeStatusOptions = [
  {
    label: PledgeLabel[PledgeStatus.Open],
    status: PledgeStatus.Open,
    color: 'green',
    value: PledgeStatus.Open,
  },
  {
    label: PledgeLabel[PledgeStatus.Freezed],
    status: PledgeStatus.Freezed,
    color: 'blue',
    value: PledgeStatus.Freezed,
  },
  {
    label: PledgeLabel[PledgeStatus.Canceled],
    status: PledgeStatus.Canceled,
    color: 'red',
    value: PledgeStatus.Canceled,
  },
  {
    label: PledgeLabel[PledgeStatus.Finalized],
    status: PledgeStatus.Finalized,
    color: 'yellow',
    value: PledgeStatus.Finalized,
  },
];

export const OldPledgeStatusOptions = [
  {
    label: 'Open',
    status: PledgeStatus.Open,
    color: 'green',
    value: PledgeStatus.Open,
  },
  {
    label: 'Closed',
    status: PledgeStatus.Closed,
    color: 'red',
    value: PledgeStatus.Closed,
  },
];

export const ValidPledgeActions = {
  [PledgeStatus.OpenUnlocked]: [
    PledgeStatus.OpenLocked,
    PledgeStatus.Freezed,
    PledgeStatus.Canceled,
  ],
  [PledgeStatus.OpenLocked]: [
    PledgeStatus.OpenUnlocked,
    PledgeStatus.Freezed,
    PledgeStatus.Canceled,
  ],
  [PledgeStatus.Freezed]: [PledgeStatus.Open, PledgeStatus.Canceled],
  [PledgeStatus.Finalized]: [],
  // Old pledge statuses
  [PledgeStatus.Open]: [PledgeStatus.Closed],
  [PledgeStatus.Closed]: [PledgeStatus.Open],
};

export const PledgeStatusDescription = [
  'Not Active - Default',
  'Open Unlocked - Pledge allowed, release allowed for users. Transition allowed.',
  'Open Locked - Pledge allowed, release NOT allowed. Transition allowed.',
  'Freezed - Pledge NOT allowed, release NOT allowed. Transition allowed.',
  'Canceled - Pledge NOT allowed, release allowed for admin. Transition NOT allowed.',
  'Finalized - Pledge NOT allowed, release NOT allowed. Transition NOT allowed.',
];
