import React, { useState } from 'react';
import './index.scss';
import { useNotifications } from 'hooks';
import { PoolStatus } from 'contracts/poolManager/constants';
import { useQueryClient } from 'react-query';
import useUpdatePledgeStatus from 'contracts/pledgeVault/hooks/useUpdatePledgeStatus';
import { PledgeStatus } from 'contracts/pledgeVault/constants';
import { PledgeStatusOptions } from '../PledgeControl/constants';
import PledgeStatusSelect from '../PledgeControl/PledgeStatusSelect';
import useAdminReleasePledgesQuery from '../ReleasePledges/ReleasePledgesPopoup/pages/PledgeRelease/hooks/useAdminReleasePledgesQuery';

const PledgeActionsDropdown = ({ deal, pool, isLocked }) => {
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const queryClient = useQueryClient();

  const [selectedStatus, setSelectedStatus] = useState(pool.status);

  const { pledges: pledgesToRelease } = useAdminReleasePledgesQuery(deal.id);

  const { updatePledgeStatus, isLoading: isUpdating } = useUpdatePledgeStatus(pool.address, {
    onSuccess: () => {
      queryClient.invalidateQueries(['admin-deal', deal.id]);
      queryClient.invalidateQueries(['deal', deal.id, 'pledge']);

      showSuccessNotification(`Pool status update successfully`);
    },
    onError: () => {
      showErrorNotification(`Error while updating pool status`);
      setSelectedStatus(pool.status);
    },
  });

  const onChangeStatus = (status) => {
    let statusToUpdate = status;

    // status 100 represents the pool is opened while locked state is determined by switch
    if (status === 100) {
      statusToUpdate = isLocked ? PledgeStatus.OpenLocked : PledgeStatus.OpenUnlocked;
    }

    setSelectedStatus(status);
    updatePledgeStatus(statusToUpdate);
  };

  if (!pool) return null;

  return (
    <div className="pool-actions">
      <PledgeStatusSelect
        value={selectedStatus}
        options={PledgeStatusOptions}
        onChange={onChangeStatus}
        disabled={isUpdating || pool.status === PoolStatus.Canceled}
        initialStatus={pool.status}
        hasAdminReleasedPledges={!!pledgesToRelease?.length}
      />
    </div>
  );
};

export default PledgeActionsDropdown;
