import React, { useState } from 'react';
import { Dialog, DialogContent, Divider } from '@material-ui/core';
import SvgIcon from 'components/svgIcon';
import RoundedButton from 'components/button/rounded-button';
import CustomInput from 'components/input/custom-input';
import Whitelist from 'features/deals/AdminDealsTable/Whitelist';

const WhitelistAddDialog = ({ whitelist, setWhitelist, onSave }) => {
  const [isValid, setIsValid] = useState(false);

  return (
    <Dialog
      open={!!whitelist}
      onClose={() => setWhitelist(null)}
      classes={{ paper: 'whitelists-modal' }}
    >
      <div className="whitelists-modal__header">
        <h2>
          <SvgIcon name="iconDealsModalEdit" />
          <span>{whitelist?.id ? 'Edit' : 'Create'}</span>
        </h2>
        <div>
          <RoundedButton type="transparent" onClick={() => setWhitelist(null)}>
            Cancel
          </RoundedButton>
          <RoundedButton type="primary" onClick={onSave} disabled={!isValid || !whitelist?.name}>
            Save
          </RoundedButton>
        </div>
      </div>
      <DialogContent>
        <Divider />
        <div className="deal-edit-modal__content whitelist-content">
          <CustomInput
            value={whitelist?.name}
            onChange={(e) => setWhitelist((prev) => ({ ...prev, name: e.target.value }))}
            className="whitelists-modal__name-input"
            label="Name"
          />
          <Whitelist
            whitelistId="new"
            whitelist={whitelist?.addresses.map((address) => ({ address, personalCap: 0 }))}
            onlyBasicInfo
            onChange={(updatedWhitelist) => {
              setWhitelist((prev) => ({
                ...prev,
                addresses: updatedWhitelist.map(({ address }) => address),
              }));
            }}
            onValidityChange={setIsValid}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default WhitelistAddDialog;
