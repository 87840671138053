import { checkIsPhaseValid } from '../actions/helpers';

const PoolConfigParams = ['minContribution', 'maxContribution', 'dealSize'];

const PoolConfigValidationFields = {
  minContribution: 'minContribution',
  maxContribution: 'maxContribution',
  dealSize: 'dealSize',
};

const PoolConfigDependencies = {
  minContribution: ['maxContribution', 'dealSize'],
  maxContribution: ['minContribution', 'dealSize'],
  dealSize: ['minContribution', 'maxContribution'],
};

const errorMessages = {
  minContribution: {
    greaterThanMax: 'Min contribution must be less than max contribution',
    greaterThanDealSize: 'Min contribution must be less than deal size',
  },
  maxContribution: {
    lessThanMin: 'Max contribution must be greater than min contribution',
    greaterThanDealSize: 'Max contribution must be less than deal size',
  },
  dealSize: {
    lessThanContribution: 'Deal size must be greater than total contribution',
    lessThanMin: 'Deal size must be greater than min contribution',
    lessThanMax: 'Deal size must be less than max contribution',
    lessThanZero: 'Deal size must be greater than 0',
  },
};

const validatePoolConfigField = (field, value, args, visitedFields = new Set()) => {
  const { poolConfig, setErrors } = args;

  const validationRules = {
    [PoolConfigValidationFields.minContribution]: [
      {
        condition: () => !!poolConfig.maxContribution && +value > +poolConfig.maxContribution,
        message: errorMessages.minContribution.greaterThanMax,
      },
      {
        condition: () => +value > +poolConfig.dealSize,
        message: errorMessages.minContribution.greaterThanDealSize,
      },
    ],
    [PoolConfigValidationFields.maxContribution]: [
      {
        condition: () => !!value && +value < +poolConfig.minContribution,
        message: errorMessages.maxContribution.lessThanMin,
      },
      {
        condition: () => +value > +poolConfig.dealSize,
        message: errorMessages.maxContribution.greaterThanDealSize,
      },
    ],
    [PoolConfigValidationFields.dealSize]: [
      {
        condition: () => +value <= 0,
        message: errorMessages.dealSize.lessThanZero,
      },
      {
        condition: () => +value < +poolConfig.totalContribution,
        message: errorMessages.dealSize.lessThanContribution,
      },
      {
        condition: () => +value < +poolConfig.minContribution,
        message: errorMessages.dealSize.lessThanMin,
      },
      {
        condition: () => +value < +poolConfig.maxContribution,
        message: errorMessages.dealSize.lessThanMax,
      },
    ],
  };

  const rules = validationRules[field] || [];
  const error = rules.find((rule) => rule.condition())?.message || null;

  setErrors((prev) => ({
    ...prev,
    [field]: error,
  }));

  validateDependencies(visitedFields, field, poolConfig, setErrors);

  return error;
};

function validateDependencies(visitedFields, field, poolConfig, setErrors) {
  if (!visitedFields.has(field)) {
    visitedFields.add(field);

    PoolConfigDependencies[field].forEach((dependency) => {
      validatePoolConfigField(
        dependency,
        poolConfig[dependency],
        {
          setErrors,
          poolConfig,
        },
        visitedFields
      );
    });
  }
}

const validatePhasesDates = (phases, args) => {
  const { setErrors } = args;

  phases.forEach((phase, index) => {
    validateDates(phase.startDate, phase.endDate, { index, setErrors });
  });
};

const validateDates = (startDate, endDate, args) => {
  const { setErrors, index } = args;

  if (startDate >= endDate) {
    setErrors((prev) => ({
      ...prev,
      [`${index}-startDate`]: 'Start date must be before end date',
      [`${index}-endDate`]: 'End date must be after start date',
    }));
    return;
  }

  setErrors((prev) => ({
    ...prev,
    [`${index}-startDate`]: '',
    [`${index}-endDate`]: '',
  }));
};

const validatePhasesAccessLevel = (phases, args) => {
  const { setErrors } = args;

  phases.forEach((phase, index) => {
    validateAccessLevels(phase.accessLevels, { index, setErrors });
  });
};

const validatePhasesCaps = (phases, args) => {
  const possibleCapTypes = ['cap', 'levels', 'whitelist', 'nftPrice'];

  phases.forEach((phase, index) => {
    possibleCapTypes.forEach((capType) => {
      validateCaps(capType, phase[capType], { index, ...args });
    });
  });
};

const validateAccessLevels = (accessLevels, args) => {
  const { index, setErrors } = args;

  if (accessLevels.length === 0) {
    setErrors((prev) => ({
      ...prev,
      [`${index}-accessLevels`]: 'Please select at least one access level',
    }));
    return;
  }

  setErrors((prev) => ({
    ...prev,
    [`${index}-accessLevels`]: '',
  }));
};

const validateCaps = (name, value, args) => {
  const { index, poolConfig, setErrors } = args;

  const validateCap = () => {
    if (+value > +poolConfig.dealSize) {
      setErrors((prev) => ({ ...prev, [`${index}-cap`]: 'Cap must be less than deal size' }));
      return;
    }

    setErrors((prev) => ({ ...prev, [`${index}-cap`]: '' }));
  };

  const validateNftPrice = () => {
    if (+value > +poolConfig.dealSize) {
      setErrors((prev) => ({
        ...prev,
        [`${index}-nftPrice`]: 'Nft price must be less than deal size',
      }));
      return;
    }

    setErrors((prev) => ({ ...prev, [`${index}-nftPrice`]: '' }));
  };

  const validateLevels = () => {
    const levels = [];

    value.forEach((level, index) => {
      if (+level > +poolConfig.dealSize) {
        levels.push(index);
      }
    });

    setErrors((prev) => ({ ...prev, [`${index}-levels`]: levels.length > 0 ? levels : '' }));
  };

  const validateWhitelist = () => {
    if (!value) {
      return;
    }

    const whitelist = value.filter((item) => +item.personalCap > +poolConfig.dealSize);

    setErrors((prev) => ({
      ...prev,
      [`${index}-whitelist`]: whitelist.length > 0 ? whitelist : '',
    }));
  };

  switch (name) {
    case 'cap':
      validateCap();
      break;
    case 'levels': {
      validateLevels();
      break;
    }
    case 'whitelist': {
      validateWhitelist();
      break;
    }
    case 'nftPrice': {
      validateNftPrice();
      break;
    }
    default:
      break;
  }
};

const validatePhases = (phases) => {
  const invalidPhase = phases.find((phase) => !checkIsPhaseValid(phase).isValid);

  const arePhasesValid = !invalidPhase;

  return arePhasesValid;
};

export {
  PoolConfigParams,
  validatePoolConfigField,
  validatePhasesCaps,
  validateCaps,
  validatePhases,
  validateAccessLevels,
  validatePhasesAccessLevel,
  validatePhasesDates,
  validateDates,
};
