import React from 'react';
import './index.scss';

import PledgeVersionSwitch from './PledgeVersionSwitch';
import OldPledgeControl from './versions/v1/OldPledgeControl';
import NewPledgeControl from './versions/v2/NewPledgeControl';
import PoolTooltip from './versions/v2/PoolTooltip';
import PledgeLockSwitch from './PledgeLockSwitch';

const PledgeControl = ({ deal }) => {
  return (
    <div className="col col-12 pledge-control pledge-control-wrapper">
      <div className="horizontal-flex horizontal-flex-center">
        <p>Pledge Status</p>
        <PoolTooltip deal={deal} />
      </div>
      <div
        className={`pledge-control-content ${
          deal.useOldPledge ? 'pledge-control-content__old_pledge_control' : ''
        }`}
      >
        {deal.useOldPledge ? <OldPledgeControl deal={deal} /> : <NewPledgeControl deal={deal} />}
      </div>
      <div className="pledge-status-switch-container">
        <PledgeLockSwitch deal={deal} />
        <PledgeVersionSwitch deal={deal} />
      </div>
    </div>
  );
};

export default PledgeControl;
