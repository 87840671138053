import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { updateLoading, addNotification, setActiveHashes } from 'store/actions';
import { DEFAULT_CHAIN_NAME } from 'constants/config';
import { ethers } from 'ethers';
import notificationTypes from 'constants/notificationTypes';
import useWithCorrectNetwork from 'hooks/useWithCorrectNetwork';
import { MutationArgs } from 'utils/types';
import usePledgeVault from './usePledgeVault';

const useBatchReleasePledge = (poolAddress, { onSuccess, onError } = MutationArgs) => {
  const dispatch = useDispatch();

  const pledgeContract = usePledgeVault();

  const queryClient = useQueryClient();

  const {
    mutate: batchReleasePledge,
    isLoading,
    isError,
    error,
  } = useMutation(
    ['batchReleasePledge', poolAddress],
    async (pledges) => {
      if (!pledges.length) {
        throw new Error('No pledges to release');
      }

      const { accounts, amounts } = pledges.reduce(
        ({ accounts, amounts }, pledge) => {
          accounts.push(pledge.user.wallet);

          const releaseAmount = +pledge.amount - +pledge.acceptedAmount;

          const releaseAmountInWei = ethers.utils.parseUnits(releaseAmount.toString(), 'mwei');
          amounts.push(releaseAmountInWei);

          return { accounts, amounts };
        },
        { accounts: [], amounts: [] }
      );

      const tx = await pledgeContract.batchReleasePledge(poolAddress, accounts, amounts);

      return tx.wait();
    },
    {
      onMutate: () => {
        dispatch(updateLoading(true));
      },
      onSuccess: (data) => {
        dispatch(updateLoading(false));
        dispatch(
          setActiveHashes([
            {
              hash: data.transactionHash,
              chain: DEFAULT_CHAIN_NAME,
              pending: false,
              callback: () => {
                queryClient.invalidateQueries(['admin-deal', poolAddress]);
                queryClient.invalidateQueries(['pool-info', poolAddress]);
                queryClient.invalidateQueries(['pool', poolAddress]);

                onSuccess();
              },
            },
          ])
        );
        dispatch(
          addNotification({
            name: data.transactionHash,
            chain: DEFAULT_CHAIN_NAME,
            status: 'pending',
            statusText: 'Pending!',
            time: Date.now(),
            type: notificationTypes.GENERAL,
          })
        );
      },
      onError: () => {
        dispatch(updateLoading(false));
        dispatch(
          addNotification({
            name: 'Pledge Error',
            status: 'error',
            statusText: 'Error!',
            time: Date.now(),
            chain: DEFAULT_CHAIN_NAME,
            type: notificationTypes.GENERAL,
          })
        );
        onError();
      },
    }
  );

  const checkedBatchReleasePledge = useWithCorrectNetwork(batchReleasePledge);

  return {
    batchReleasePledge: checkedBatchReleasePledge,
    isLoading,
    isError,
    error,
  };
};

export default useBatchReleasePledge;
