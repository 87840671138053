import React from 'react';
import UpdatePledgeControls from './components/UpdatePledgeControls';

import '../../index.scss';
import UpdatePledgeTable from './components/UpdatePledgeTable';

const PledgeDistribute = () => {
  return (
    <>
      <UpdatePledgeControls />
      <UpdatePledgeTable />
    </>
  );
};

export default PledgeDistribute;
