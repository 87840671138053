import React from 'react';
import RoundedButton from 'components/button/rounded-button';
import useDealLivePromotion from 'contracts/hooks/useDealLivePromotion';
import Tooltip from 'components/tooltip';
import { useDeployContractContext } from '../context';
import { isValidPoolConfig } from '../helpers';

function DeployContractAction({ disabled, onValidate }) {
  const { deal, poolConfig, isValidPledgeStatus, arePhasesValid, triggerDirty } =
    useDeployContractContext();

  const { promoteDeal, isLoading: isPromotingDeal } = useDealLivePromotion(deal);

  const onPromoteDeal = () => {
    const { isFormValid } = onValidate();

    if (!isFormValid) {
      return;
    }

    promoteDeal();
  };

  return (
    <Tooltip
      title="Please configure deal and all phases correctly before proceeding"
      onMouseEnter={triggerDirty}
      disableHoverListener={arePhasesValid}
    >
      <div>
        <RoundedButton
          onClick={onPromoteDeal}
          disabled={
            isPromotingDeal ||
            !isValidPledgeStatus ||
            !isValidPoolConfig(poolConfig) ||
            !arePhasesValid ||
            disabled
          }
          type="secondary"
        >
          Deploy
        </RoundedButton>
      </div>
    </Tooltip>
  );
}

export default DeployContractAction;
