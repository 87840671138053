/* eslint-disable no-use-before-define */
/* eslint-disable no-restricted-syntax */
import buildCall from 'contracts/calls';
import { CALL_TYPE } from 'contracts/calls/constants';
import { queryCalls } from 'contracts/multicallContract';
import { getProviderByChainId } from 'contracts/providers';
import { ethers } from 'ethers';
import { buildAllowlistData, getAllowedPhases } from 'features/deals/UserDealsTable/helpers';
import { groupByChain } from 'models/common/helpers';

async function enrichWithRemainingAllocation(liveDeals, accountInfo, queryClient) {
  const dealsByChain = groupByChain(liveDeals);

  const results = [];

  for await (const [chainId, deals] of Object.entries(dealsByChain)) {
    const provider = getProviderByChainId(chainId);

    const multicallCalls = prepareMulticallCalls(deals, accountInfo);

    const multicallResults = await queryCalls(provider, multicallCalls);

    const enrichedDeals = deals.map((deal, index) => {
      const { personalCap, remainingAllocations } = parseRemainingAllocation(
        multicallResults,
        index
      );

      queryClient.setQueryData(
        ['remaining-allocations', deal.address, accountInfo.address],
        remainingAllocations
      );

      return {
        ...deal,
        personalCap,
      };
    });

    results.push(...enrichedDeals);
  }

  return results;
}

function prepareMulticallCalls(deals, accountInfo) {
  const callParams = deals.map((deal) => {
    const eligiblePhases = getAllowedPhases(deal.activePhases, accountInfo);

    return {
      address: deal.address,
      params: [
        accountInfo.address,
        eligiblePhases.map((phase) => phase.index),
        eligiblePhases.map((phase) => buildAllowlistData(phase)),
      ],
    };
  });

  return callParams.map(({ address, params }) =>
    buildCall(CALL_TYPE.REMAINING_ALLOCATION)(address, params)
  );
}

function parseRemainingAllocation(multicallResults, callIndex) {
  const result = multicallResults[callIndex];

  const rawRemainingAllocations = result.returnData[0];

  const remainingAllocations = rawRemainingAllocations.map((allocation) =>
    ethers.utils.formatUnits(allocation, 'mwei')
  );

  const personalCap = remainingAllocations.reduce((acc, allocation) => {
    return acc + Number(allocation);
  }, 0);

  return { personalCap, remainingAllocations };
}

export { enrichWithRemainingAllocation };
