import React from 'react';
import RoundedAvatar from 'components/avatar/rounded-avatar';
import CustomProgressBar from 'components/progress-bar/custom-progress-bar';
import { useReleasePledgeContext } from '../../context';
import { usePledgeDistributeContext } from '../../pages/PledgeDistribute/context';
import '../../index.scss';

const ReleasePledgesHeader = () => {
  const { deal } = useReleasePledgeContext();

  const { state, originalPledgedAmount } = usePledgeDistributeContext();

  return (
    <div className="update-pledges-header">
      <div className="update-pledges-top-header">
        <div className="update-pledges-header-title">
          <h3>Update Pledges</h3>
          <span className="update-pledges-header-description">
            Adjust pledge amounts for each wallet
          </span>
          <div className="update-pledges-header-title-holder">
            <RoundedAvatar src={deal.imageUrl} />
            <div>
              <span>{deal.name}</span>
              {deal.dealSize && deal.raisedAmount && (
                <CustomProgressBar
                  total={Number(deal.dealSize)}
                  value={Number(deal.raisedAmount)}
                />
              )}
            </div>
          </div>
        </div>
        <div className="update-pledges-header-total update-pledges-header-amounts">
          <div>
            Original Pledge Amount:{' '}
            <span className="update-pledges-header-amount">{originalPledgedAmount} USDT</span>
          </div>
          <div>
            Total Pledge Release:{' '}
            <span className="update-pledges-header-amount">
              {state.totalRelease.amountFormatted} USDT
            </span>
          </div>
          <div>
            Refund Amount:{' '}
            <span className="update-pledges-header-amount">{state.totalRelease.refund} USDT</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReleasePledgesHeader;
