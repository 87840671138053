import Icon from 'components/svgIcon';
import React, { useState, useRef, useEffect } from 'react';
import './index.scss';
import { ValidPledgeActions } from '../constants';
import PledgeStatus from 'contracts/pledgeVault/constants';

export default function PledgeStatusSelect({
  options,
  value,
  onChange,
  disabled,
  initialStatus,
  hasAdminReleasedPledges,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const selectedOption = options.find((option) => option.value === value) || options[0];

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const toggleDropdown = () => {
    if (disabled) return;

    setIsOpen(!isOpen);
  };

  const handleSelect = (option) => {
    const isValidAction = ValidPledgeActions[initialStatus].includes(option.value);

    const isUnlockingPledge = option.value === PledgeStatus.Open;
    if (isUnlockingPledge && hasAdminReleasedPledges) {
      return;
    }

    if (isValidAction) {
      onChange(option.value);
    }

    setIsOpen(false);
  };

  return (
    <div ref={dropdownRef} className="pledge-status-select-container">
      <div className="pledge-status-select-inner-container">
        <div>
          <div
            onClick={toggleDropdown}
            className={`pledge-status-select-dropdown-container pledge-status-select-dropdown-container__${
              selectedOption.color
            } ${disabled ? 'pledge-status-select-dropdown-container--disabled' : ''}`}
          >
            {selectedOption.label}
            <Icon name="arrowDown" />
          </div>

          {isOpen && (
            <div className="pledge-status-select-dropdown-menu">
              {options.map((option) => {
                return (
                  <div
                    key={option.value}
                    onClick={() => handleSelect(option)}
                    className={`pledge-status-select-dropdown-menu-item pledge-status-select-dropdown-menu-item__${option.color}`}
                  >
                    {option.label}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
